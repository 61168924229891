import { Address } from './address';

export class Register {
    public businessName = '';
    public merchantNumber: string = "";
    public firstname = '';
    public lastname = '';
    public groups: string[] = [];
    public access_token = '';
    public application = '';
    public server = '';
    public addresses: Address[] = new Array<Address>();
    public email = '';
    public phone = '';
    public isAutoNotification = true;
    public isAutoSubcribe = true;
    public fax = '';
    public password = '';
    public passwordconfirm = '';
    public deliveryInstructions = '';
    public profileid = 0;
    public createddate = new Date();
    public lastlogin = new Date();
    public passwordAttempts = 0;
    public locked = false;
    public isGuestLogin = false;
    public paymentTokens: string[] = [];
    public verified = false;
    public key: number = 1;
    public modifieddate = new Date();
    public modifiedby = '';


    constructor() {
        this.businessName = '';
        this.merchantNumber = "";
        this.firstname = '';
        this.lastname = '';
        this.groups = [];
        this.access_token = '';
        this.application = 'deliverme';
        this.server = '';
        this.email = '';
        this.phone = '';
        this.isAutoNotification = true;
        this.isAutoSubcribe = true;
        this.fax = '';
        this.password = '';
        this.passwordconfirm = '';
        this.modifieddate = new Date();
        this.modifiedby = '';
        this.key = 1;
        const address: Address = new Address();
        address.street1 = '';
        address.street2 = '';
        address.city = '';
        address.state = '';
        address.postalCode = '';
        address.country = 'USA';
        address.isDefault = true;

        this.addresses.push(address);
    }
}
