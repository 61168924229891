import { ReceiptItem } from  "./receipt-item";
export class Transaction {
    public dateTime: Date;
    public universalTimeStamp: number;
    public applicationKey: string;
    public isProcharge: boolean;
    public protocolType: string;
    public PubStrMasterApplicationKey: string; // procharge mobile use only
    public name: string;
    /**
     * @deprecated firstname is deprecated. Use firstName instead.
     */
    public firstname: string;
    public firstName: string;
    /**
     * @deprecated lastname is deprecated. Use lastName instead.
     */
    public lastname: string;
    public lastName: string;
    public street1: string;
    public street2: string;
    public city: string;
    public state: string;
    /**
     * @deprecated postalcode is deprecated. Use postalCode instead.
     */
    public postalcode: string;
    public postalCode: string;
    public country: string;
    /**
     * @deprecated emv_data is deprecated. Use emv instead.
     */
    public emv_data: string;
    public emv: string;
    /**
     * @deprecated trackData is deprecated. Use trackData instead.
     */
    public track_data: string;
    public trackData: string;
    /**
     * @deprecated cardnumber is deprecated. Use cardNumber instead.
     */
    public cardnumber: string;
    public cardNumber: string;
    /**
     * @deprecated cc_last_four is deprecated. Use ccLastFour instead.
     */
    public cc_last_four: string;
    public ccLastFour: string;
    public token: string;
    /**
     * @deprecated cc_type is deprecated. Use ccType instead.
     */
    public cc_type: string;
    public ccType: string;
    /**
     * @deprecated cc_exp_month is deprecated. Use ccExpMonth instead.
     */
    public cc_exp_month: string;
    public ccExpMonth: string;
    /**
     * @deprecated cc_exp_year is deprecated. Use ccExpYear instead.
     */
    public cc_exp_year: string;
    public ccExpYear: string;
    public cvv: string;
    public amount: string;
    /**
     * @deprecated tip_amount is deprecated. Use tipAmount instead.
     */
    public tip_amount: string;
    public tipAmount: string;
    /**
     * @deprecated taxrate is deprecated. Use taxRate instead.
     */
    public taxrate: string;
    public taxRate: string;
    /**
     * @deprecated tax_amount is deprecated. Use taxAmount instead.
     */
    public tax_amount: string;
    public taxAmount: string;
    public transactionFee: string;
    public reverseCashDiscountPercentage: string;
    public reverseCashDiscountAmount: string;
    public reverseCashDiscountFixAmount: string;
    public customerServicefee: string;
    public customerServiceFeeAmount: string;
    public customerServiceFeeFixAmount: string;
    public customerServiceFeePercentage: string;
    public cashDiscountFixAmount: string;
    public email: string;
    public phone: string;
    public cell: string;
    /**
     * @deprecated companyname is deprecated. Use companyName instead.
     */
    public companyname: string;
    public companyName: string;
    /**
     * @deprecated merchantnumber is deprecated. Use merchantNumber instead.
     */
    public merchantnumber: string;
    public merchantNumber: string;
    /**
     * @deprecated transaction_code is deprecated. Use transactionCode instead.
     */
    public transaction_code: string;
    public transactionCode: string;
    public merchantid: number;
    public profileid: number;
    /**
     * @deprecated ordernumber is deprecated. Use orderNumber instead.
     */
    public ordernumber: string;
    public orderNumber: string;
    public x_tran_key: string;
    public source: string;
    /**
     * @deprecated approval_code is deprecated. Use approvalCode instead.
     */
    public approval_code: string;
    public approvalCode: string;
    public sandbox: string;
    public sandbox_did: string;
    public target: string;
    /**
     * @deprecated transactionID is deprecated. Use transactionID instead.
     */
    public transactionid: string;
    public transactionID: string;
    /**
     * @deprecated deviceid is deprecated. Use deviceID instead.
     */
    public deviceid: string;
    public deviceID: string;
    /**
     * @deprecated batchnumber is deprecated. Use batchNumber instead.
     */
    public batchnumber: string;
    public batchNumber: string;
    /**
     * @deprecated itemnumber is deprecated. Use itemNumber instead.
     */
    public itemnumber: string;
    public itemNumber: string;
    /**
     * @deprecated revisionnumber is deprecated. Use revisionNumber instead.
     */
    public revisionnumber: string;
    public revisionNumber: string;
    public debitItemNumber: string;
    /**
     * @deprecated InvoiceId is deprecated. Use invoiceID instead.
     */
    public InvoiceId: number;
    public invoiceID: number;
    /**
     * @deprecated InvoiceNum is deprecated. Use invoiceNum instead.
     */
    public InvoiceNum: string;
    public invoiceNum: string;
    /**
     * @deprecated PaymentId is deprecated. Use paymentID instead.
     */
    public PaymentId: number;
    public paymentID: number;
    public eci: string;
    public aci: string;
    /**
     * @deprecated AuthCharIndicator is deprecated. Use authCharIndicator instead.
     */
    public AuthCharIndicator: string;
    public authCharIndicator: string;
    public industryType: string;
    public sandbox_batchnum: string;
    public sandbox_itemnum: string;
    public deviceModel: string;

    public isOffline: boolean;
    public byPassBatchCheck: boolean;

    public paymentType: string;
    public paymentToken: any;     // apple pay token, google pay token.

    /**
     * @deprecated terminalId is deprecated. Use terminalID instead.
     */
    public terminalId: string;
    public terminalID: string;
    public writeControlCharacter: string;
    public transactionType: string;
    public terminalCapability: string;
    public terminalCardCaptureCapability: string;
    public terminalPinCapability: string;
    public terminalCategoryCode: string;
    public posConditionCode: string;
    public cardVerificationPresenceIndicator: string;
    public partialAuthIndicator: string;
    public isPurchaseCard = false;
    public giftCardIndicator: string;
    public customerReceipt: string;
    public merchantReceipt: string;
    public cardNotPresent = true;
    public receipts = false;
    public items: ReceiptItem[] = [];
    public raw: string[];

    constructor() {
        this.dateTime = new Date();
        this.universalTimeStamp = new Date().getTime();
        this.applicationKey = "";
        this.isProcharge = true;
        this.protocolType = "1"
        this.cardnumber = "";
        this.cardNumber = "";
        this.name = "";
        this.firstname = "";
        this.firstName = "";
        this.lastname = "";
        this.lastName = "";
        this.street1 = "";
        this.street2 = "";
        this.city = "";
        this.state = "";
        this.postalcode = "";
        this.postalCode = "";
        this.country = "";
        this.emv = "";
        this.track_data = "";
        this.trackData = "";
        this.cc_last_four = "";
        this.ccLastFour = "";
        this.token = "";
        this.cc_type = "";
        this.ccType = "";
        this.cc_exp_month = "";
        this.ccExpMonth = "";
        this.cc_exp_year = "";
        this.ccExpYear = "";
        this.cvv = "";
        this.amount = "";
        this.tip_amount = "";
        this.tipAmount = "";
        this.tax_amount = "";
        this.taxAmount = "";
        this.reverseCashDiscountAmount = "";
        this.reverseCashDiscountFixAmount = "";
        this.customerServicefee = "";
        this.customerServiceFeeAmount = "";
        this.customerServiceFeeFixAmount = "";
        this.cashDiscountFixAmount = "";
        this.email = "";
        this.phone = "";
        this.cell = "";
        this.companyname = "";
        this.companyName = "";
        this.merchantnumber = "";
        this.merchantNumber = "";
        this.transactionCode = "4";
        this.merchantid = 0;
        this.profileid = 0;
        this.ordernumber = "";
        this.orderNumber = "";
        this.x_tran_key = "";
        this.source = "i2";
        this.approval_code = "";
        this.approvalCode = "";
        this.sandbox = "n";
        this.sandbox_did = "";
        this.target = "";
        this.transactionID = "";
        this.deviceid = "";
        this.batchnumber = "0";
        this.batchNumber = "0";
        this.itemnumber = "001";
        this.itemNumber = "001";
        this.revisionnumber = "0";
        this.revisionNumber = "0";
        this.debitItemNumber = "999";
        this.InvoiceId = 0;
        this.invoiceID = 0;
        this.InvoiceNum = "";
        this.invoiceNum = "";
        this.PaymentId = 0;
        this.paymentID = 0;
        this.eci = "";
        this.aci = "";
        this.AuthCharIndicator = "";
        this.authCharIndicator = "";
        this.industryType = "6";
        this.paymentType = "";    // apple pay, google pay.
        this.paymentToken = {};     // apple pay token, google pay token.
        this.sandbox_batchnum = "";
        this.sandbox_itemnum = "";
        this.deviceModel = "";
        this.isOffline = false;
        this.terminalId = "PP001.";
        this.terminalID = "PP001.";
        this.writeControlCharacter = "";
        this.transactionType = "";
        this.terminalCapability = "";
        this.terminalCardCaptureCapability = "";
        this.terminalPinCapability = "";
        this.terminalCategoryCode = "";
        this.posConditionCode = "";
        this.cardVerificationPresenceIndicator = "1";
        this.partialAuthIndicator = "";
        this.giftCardIndicator = "";
        this.isPurchaseCard = false;
        this.raw = [];
        this.customerReceipt = "";
        this.merchantReceipt = "";
        this.cardNotPresent = true;
        this.receipts = false;
        this.items = [];
    }
}
