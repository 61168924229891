import { Injectable, EventEmitter } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';

const KEY = 'REGISTRATION';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  public FORMKEYS = {
    INFO: `${KEY}.INFO`,
    TYPE: `${KEY}.TYPE`,
    LOC: `${KEY}.LOC`,
    SVC: `${KEY}.SVC`,
    DCA: `${KEY}.DCA`, //delivery coverage area
    HOURS: `${KEY}.HOURS`,
    PAYOPTS: `${KEY}.PAYOPTS`,
    TAX: `${KEY}.TAXES`
  };

  constructor(
    private _localStorage: StorageMap
  ) { }

  public RouteChange: EventEmitter<string> = new EventEmitter();

  public ChangeRoute(route) {
    this.RouteChange.emit(`/registration/${route}`);
  }

  public SetData(key: string, data: any) {
    data = JSON.stringify(data);
    this._localStorage.set(key, data).subscribe();
  }

  public GetData(key: string) {
    return this._localStorage.get(key);
  }

}
