import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { GoogleLoginProvider, FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { loginStep } from 'projects/common/src/lib/login';
import { Observable, Subscription } from 'rxjs';
import { SideNavService } from 'projects/services/src/lib/side-nav.service';
const pkg = require('../../../../package.json');

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  public loggingIn = false;
  public loginError: string;
  public form: UntypedFormGroup;
  public show = false;
  public state: loginStep = loginStep.login;
  public version = pkg.version;

  sidenavObservable: Observable<boolean>;
  sidenavSubscription: Subscription;
  sidenavState = false;

  constructor(
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authSvc: AuthenticationService,
    private sideNavService: SideNavService,
    private socialAuthSvc: SocialAuthService
  ) {
    iconRegistry.addSvgIcon('facebook', sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/facebook.svg'));
    iconRegistry.addSvgIcon('google', sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/google.svg'));
  }

  ngOnInit() {
    this._ResetForm();
  }

  private _ResetForm() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)])
    });
  }

  get email() { return this.form.get('email') as UntypedFormControl; }
  get password() { return this.form.get('password') as UntypedFormControl; }

  public GoToRegistration() {
    this.dialogRef.close('register');
  }

  public passwordResetRequest() {
    this.dialogRef.close('password-reset-request');
  }

  public Cancel(): void {
    this.dialogRef.close();
    this.sideNavService.close();
  }

  public login(): void {
    this.loginError = null;
    this.email.disable();
    this.password.disable();
    this.loggingIn = true;

    setTimeout(async () => {
/*       let cognitoUser: any = await this.authSvc.cognitoLogIn({
        application: 'deliverme',
        email: this.email.value,
        password: this.password.value,
        username: ''
      }).catch((error: any) => {
        console.log('cognito login failed for user', error);
      }); */

      /* if (!cognitoUser || !cognitoUser.tokens) { */
        this.authSvc.login({
          application: 'deliverme',
          email: this.email.value,
          password: this.password.value,
          username: ''
        }).subscribe(data => {
          if (data && data.access_token) {
            this.dialogRef.close();
            this.sideNavService.close();
          } else {
            // disable inputs so they can't do anything except close the dialog and try again:
            this.email.enable();
            this.password.enable();
            this.loginError = 'An error has occurred, please try again later';
          }
        }, async (err: any) => {
          let cognitoUser: any = await this.authSvc.cognitoLogIn({
            application: 'deliverme',
            email: this.email.value,
            password: this.password.value,
            username: ''
          }).catch((error: any) => {
            console.log('cognito login failed for user', error);
            this.email.enable();
            this.password.enable();
            this.loginError = 'An error has occurred, please try again later';
            this.handleError(err);
          });
        }, () => {
          this.email.enable();
          this.password.enable();
          this.loggingIn = false;
        });
/*       } else {
        this.dialogRef.close();
        this.sideNavService.close();
      } */
    }, 0);
  }

  public signInWithGoogle(): void {
    // console.log('Login With Google');
    this.socialAuthSvc.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      // console.log(x);
      this.authSvc.socialLogin(x).subscribe(data => {
        if (data && data.access_token) {
          this.dialogRef.close();
          this.sideNavService.close();
          // this.continueShopping();
        } else {
          // disable inputs so they can't do anything except close the dialog and try again:
          this.email.enable();
          this.password.enable();
          this.loginError = 'An error has occurred, please try again later';
        }
      }, err => {
        this.handleError(err);
      }, () => {
        this.email.enable();
        this.password.enable();
        this.loggingIn = false;
      });
    }).catch(err => {
      console.log(err);
    });
  }

  public signInWithFB(): void {
    // console.log('Login With Facebook');
    this.socialAuthSvc.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {
      // console.log(x);
      this.authSvc.socialLogin(x).subscribe(data => {
        if (data && data.access_token) {
          this.dialogRef.close();
          this.sideNavService.close();
          // this.continueShopping();
        } else {
          // disable inputs so they can't do anything except close the dialog and try again:
          this.email.enable();
          this.password.enable();
          this.loginError = 'An error has occurred, please try again later';
        }
      }, err => {
        this.handleError(err);
      }, () => {
        this.email.enable();
        this.password.enable();
        this.loggingIn = false;
      });
    }).catch(err => {
      console.log(err);
    });
  }

  private handleError(err: HttpErrorResponse) {
    if (err.status) {
      if (err.error) {
        if (err.status === 500 || err.status === 401) {
          this.loginError = 'Invalid Credentials';
        } else {
          this.loginError = err.status + ': ' + err.error.message;
        }
      } else {
        this.loginError = err.status + ': ' + err.message;
      }
    } else {
      this.loginError = err.message;
    }
    console.log('An error has occurred:', this.loginError);
    this.loggingIn = false;
  }

}
