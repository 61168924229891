<div id="registration-dialog" class="body" style="padding: 0 !important;" fxLayoutAlign="center">
    <div id="divSignUp">
        <div class="row">
            <div class="col-2">
                <a class="nav-link float-left" style="color: black;" data-dismiss="modal" [routerLink]="[]"
                    (click)="dialogRef.close();" data-test="Registration-DialogNavLinkA">
                    <i class="fas fa-times fa-lg"></i>
                </a>
            </div>
            <h3 class="col-10"><b>DeliverMe Sign Up</b></h3>
        </div>
        <!--         <h3 mat-dialog-title fxLayoutAlign="center center">
            <span>DeliverMe SignUp</span>
        </h3> -->
        <div mat-dialog-content class="container-fluid">
            <form [formGroup]="formRegistration" (ngSubmit)="register(formRegistration)" class="registration-form"
                novalidate>

                <div class="row" *ngIf="formRegistration" style="align-items: flex-start;margin-bottom: 4px">
                    <div class="col-12">
                        <div class="row">
                            <div class="col form-group">
                                <input type="text" class="form-control form-control-sm bold-input"
                                    formControlName="firstname" placeholder="First Name"
                                    (change)="formChange(formRegistration)" required
                                    data-test="Registration-DialogFirstNameInput" />
                                <mat-error *ngIf="firstname.invalid && submitted" class="help-block">
                                    First Name Required
                                </mat-error>
                            </div>
                            <div class="col form-group">
                                <input type="text" class="form-control form-control-sm bold-input"
                                    formControlName="lastname" placeholder="Last Name"
                                    (change)="formChange(formRegistration)" required
                                    data-test="Registration-DialogLastNameInput" />
                                <mat-error *ngIf="lastname.invalid && submitted" class="help-block">
                                    Last Name Required
                                </mat-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <input type="text" class="form-control form-control-sm bold-input" maxlength="65"
                                    formControlName="street1" placeholder="Address" (keyup)="addressLookUp()"
                                    [matAutocomplete]="autocomplete" (change)="formChange(formRegistration)" required
                                    data-test="Registration-DialogAddressInput">
                                <mat-error *ngIf="street1.invalid && submitted" class="help-block">
                                    Address Required
                                </mat-error>
                                <mat-autocomplete #autocomplete="matAutocomplete">
                                    <mat-option *ngFor="let addr of streetMatches" [value]="addr.formatted_address"
                                        (click)="selectedAddress(addr)">
                                        <small>{{addr.formatted_address}}</small>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 form-group">
                                <input type="text" class="form-control form-control-sm bold-input"
                                    formControlName="street2" placeholder="Apt #, Suite, etc."
                                    (change)="formChange(formRegistration)" data-test="Registration-DialogAptInput" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 form-group">
                                <input type="text" class="form-control form-control-sm bold-input"
                                    formControlName="city" placeholder="City" (change)="formChange(formRegistration)"
                                    required data-test="Registration-DialogCityInput" />
                                <mat-error *ngIf="city.invalid && submitted" class="help-block">
                                    City Required
                                </mat-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <mat-form-field appearance="none" class="my-form-field"
                                    style="margin-top: 14px;margin-bottom: 10px;max-height: 30px">
                                    <select matNativeControl id="registration-state"
                                        class="form-control form-control-sm bold-input" placeholder="State"
                                        formControlName="state" (change)="formChange(formRegistration)" required
                                        data-test="Registration-DialogStateSelect">
                                        <option value="" disabled selected>State</option>
                                        <option *ngFor="let s of states" [value]="s">
                                            {{s}}
                                        </option>
                                    </select>
                                    <mat-error *ngIf="state.invalid && submitted" class="help-block"
                                        style="font-size: 16px">
                                        State Required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <input type="tel" class="form-control form-control-sm bold-input"
                                    formControlName="postalcode" placeholder="Postal Code"
                                    (change)="formChange(formRegistration)" required
                                    data-test="Registration-DialogPostalCodeInput" />
                                <mat-error *ngIf="postalcode.invalid && submitted" class="help-block">
                                    Zip Code Required
                                </mat-error>
                                <mat-error *ngIf="postalcode.hasError('pattern') && submitted">
                                    Valid Zip Code Required
                                </mat-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <input type="tel" class="form-control form-control-sm bold-input"
                                    formControlName="phone"
                                    placeholder="Mobile Phone Number (Required for SMS Notifications)*" maxlength="15"
                                    ng-maxlength="15" [textMask]="{mask: regexList.us_phone_masked}"
                                    (change)="formChange(formRegistration)" required
                                    data-test="Registration-DialogPhoneInput" />
                                <mat-error *ngIf="phone.hasError('pattern') || phone.invalid && submitted">
                                    Valid Phone Number Required (Tolls Not Allowed)
                                </mat-error>
                            </div>
                            <div class="col form-group">
                                <input type="tel" class="form-control form-control-sm bold-input" formControlName="fax"
                                    placeholder="Fax: (555) 555-5555" maxlength="15" ng-maxlength="15"
                                    [textMask]="{mask: regexList.us_phone_masked}"
                                    (change)="formChange(formRegistration)" data-test="Registration-DialogFaxInput" />
                                <mat-error *ngIf="fax.hasError('pattern') && (fax.dirty || fax.touched)">
                                    Please enter a valid fax number
                                </mat-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <input #registrationEmail type="email" class="form-control form-control-sm bold-input"
                                    formControlName="email" placeholder="Email" (change)="formChange(formRegistration)"
                                    required data-test="Registration-DialogEmailInput" />
                                <mat-error *ngIf="email.hasError('pattern') || email.invalid && submitted">
                                    Valid Email Required
                                </mat-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col form-group">
                                <input type="password" class="form-control form-control-sm bold-input"
                                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}" formControlName="password"
                                    placeholder="Password" required (change)="formChange(formRegistration)"
                                    data-test="Registration-DialogPasswordInput" />
                                <mat-error *ngIf="password.invalid && submitted">
                                    Password Required
                                </mat-error>
                                <mat-error *ngIf="password.hasError('pattern') && submitted">
                                    Password must contain 8 to 15 characters and at least one number, one uppercase
                                    letter and one special character (@$!%*?&)
                                    <!-- Password must contain 8 or more characters and at least one number and one uppercase letter -->
                                </mat-error>
                            </div>
                            <div class="col form-group">
                                <input type="password" class="form-control form-control-sm bold-input"
                                    formControlName="passwordconfirm" placeholder="Confirm Password" required
                                    (change)="formChange(formRegistration)"
                                    data-test="Registration-DialogConfirmPasswordInput" />
                                <mat-error *ngIf="passwordconfirm.invalid && submitted">
                                    Confirm Password Required
                                </mat-error>
                                <!-- <mat-error *ngIf="passwordconfirm.hasError('pattern') && submitted"> -->
                                <mat-error *ngIf="password.value !== passwordconfirm.value && submitted">
                                    Password and confirmation password do not match
                                </mat-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col form-group">
                                <mat-checkbox formControlName="isAutoSubcribe"
                                    data-test="Registration-DialogAutoSubCheckbox">Auto Subscribe</mat-checkbox>
                            </div>
                            <div class="col form-group">
                                <mat-checkbox formControlName="isAutoNotification"
                                    data-test="Registration-DialogAutoNotifCheckbox">Auto Notification</mat-checkbox>
                            </div>
                        </div>

                        <div class="row">
                            <div *ngIf="enableRecaptcha" class="col-md-12 form-group">
                                <re-captcha formControlName="recaptcha" (resolved)="resolved($event)"
                                    style="width: 600px;"
                                    siteKey="6Leh57YUAAAAACZu8IUm8T14tYbsJdDRORidAky3"></re-captcha>
                                <mat-error *ngIf="recaptcha.hasError('required')">
                                    Recaptcha required
                                </mat-error>
                            </div>
                        </div>
                        <div class="row" id="divText" mat-dialog-content>
                            A verification link will be sent to your email and
                            you may begin ordering once verified.
                            Be sure to check your spam folder if you do not see it in your inbox.
                        </div>
                        <div class="row">
                            <div class="col form-group">
                                <button type="button" name="cancel" class="btn btn-primary" (click)="Cancel()"
                                    data-test="Registration-DialogCancelButton">Cancel</button>
                            </div>
                            <div class="col form-group">
                                <!--  <button class="btn btn-success" [disabled]="formRegistration.invalid || disableSubmit === true" (click)="register(formRegistration)">Sign Up!</button> -->
                                <button type="submit" class="btn btn-success"
                                    data-test="Registration-DialogSubmitButton">Sign Up!</button>
                            </div>
                        </div>
                        <div id="register_error" fxFlex="100%">
                            <h5 *ngIf="loginError">
                                <span style="color: red">{{loginError}}</span>
                            </h5>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
