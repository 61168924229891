import * as moment from 'moment';
import 'moment-timezone';

export class Common {

  deviceInfo = null;

  constructor() {

  }

  public compareValues(key: string, order: string = 'asc'): any {
    return function innerSort(a: any, b: any) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  public convertToLocalTimeZone(date: string): any {
    try {

      const timezone = moment.tz(moment.tz.guess()).zoneAbbr();

      const offset = moment().utcOffset();
      const hrs = offset / 60;

      // const tzDate = new Date(this.order.createtime).toString();
      const tzDate = date;
      let newDate = moment(tzDate).format('MMMM Do YYYY, h:mm:ss a') + ' (' + timezone + ')';

      return newDate;

    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}
