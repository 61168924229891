<!-- <div mat-dialog-title>Confirm</div> -->
<div style="padding: 24px;" [ngStyle]="{'text-align': validate ? 'center' : 'left'}">
    <div id="divText" mat-dialog-content *ngIf="!validate">
        {{data.message}}
    </div>
    <div id="divText" mat-dialog-content *ngIf="validate">
        <h2>{{data.message}}</h2>
    </div>
    <div *ngIf="data.text">
        <mat-expansion-panel expanded [hideToggle]="validate" (opened)="panelOpenState = true" (closed)="panelOpenState = false" [ngStyle]="{'background': validate ? 'white' : 'none'}">
            <mat-expansion-panel-header>
                <mat-panel-description *ngIf="!validate">
                    Details
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div id="divDetails2">
                {{''}}
            </div>
            <form *ngIf="validate" [formGroup]="validationForm">
                <mat-form-field style="width: 100%;">
                    <mat-label>Confirmation</mat-label>
                    <input matInput type="text" formControlName="validation" data-test="Confirm-DialogConfirmationInput" required />
                    <mat-error *ngIf="validation.invalid" class="help-block">
                        Incorrect confirmation
                    </mat-error>
                </mat-form-field>
            </form>
        </mat-expansion-panel>
    </div>
    <div *ngIf="data" mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
        <button *ngIf="!data.okonly && !data.yesno && !validate" mat-button (click)="Cancel()" data-test="Confirm-DialogCancelOrderButton">Cancel Order</button>
        <button *ngIf="!data.okonly && !data.yesno && !validate" mat-button (click)="Confirm()" data-test="Confirm-DialogCorrectOrderButton">Correct Order</button>
        <button *ngIf="data.yesno && data.yesno === true && !validate" mat-button (click)="Confirm()" data-test="Confirm-DialogYesButton">Yes</button>
        <button *ngIf="data.yesno && data.yesno === true && !validate" mat-button (click)="Cancel()" data-test="Confirm-DialogNoButton">No</button>
        <button *ngIf="validate && validate === true" mat-button (click)="Cancel()" data-test="Confirm-DialogCancelButton">Cancel</button>
        <button *ngIf="validate && validate === true" mat-button [disabled]="validation.invalid" (click)="Confirm()" data-test="Confirm-DialogOkButton1">Ok</button>
        <button style="margin: 0 auto;" *ngIf="(data.okonly && data.okonly === true) && !data.yesno" mat-button (click)="Confirm()" data-test="Confirm-DialogOkButton2">Ok</button>
    </div>
</div>