<div class="container center">
    <div class="row">
        <div class="col-12">
            <a class="nav-link float-right" data-dismiss="modal" [routerLink]="[]" (click)="close()">
                <i class="fas fa-times fa-lg"></i>
            </a>
        </div>
    </div>
    <form [formGroup]="formPassword" class="shadow p-3 bg-white rounded" (ngSubmit)="formPassword.valid && resetPassword(formPassword)">
        <input type="email" formControlName="email" placeholder="Login Email" required (ngModelChange)="formChange(formPassword)" class="form-control form-control-sm" data-test="Password-ChangeLoginEmailInput"/>
        <mat-error *ngIf="currentPassword.hasError('required') && (currentPassword.dirty || currentPassword.touched)">
            Valid Login Email Required
        </mat-error>
        <input type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" formControlName="currentPassword" placeholder="Current Password" required (ngModelChange)="formChange(formPassword)" class="form-control form-control-sm" data-test="Password-ChangeCurrentPasswordInput"/>
        <mat-error *ngIf="currentPassword.hasError('required') && (currentPassword.dirty || currentPassword.touched)">
            Old Password Required
        </mat-error>
        <input type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" formControlName="newPassword" placeholder="New Password" required (ngModelChange)="formChange(formPassword)" class="form-control form-control-sm" data-test="Password-ChangeNewPasswordInput"/>
        <mat-error *ngIf="newPassword.hasError('required') && (newPassword.dirty || newPassword.touched)">
            New Password Required
        </mat-error>
        <mat-error *ngIf="newPassword.hasError('pattern') && (newPassword.dirty || newPassword.touched)">
            <h6>Password must contain the following:</h6>
            Minimum of 8 characters<br> Maximum of 15 characters<br> One number<br> One uppercase letter
        </mat-error>
        <input type="password" pattern="{{ newPassword.value }}" formControlName="passwordconfirm" placeholder="Confirm Password" required (ngModelChange)="formChange(formPassword)" class="form-control form-control-sm" data-test="Password-ChangeConfirmPasswordInput"/>
        <mat-error *ngIf="passwordconfirm.hasError('required') && (passwordconfirm.dirty || passwordconfirm.touched)">
            Confirm Password Required
        </mat-error>
        <mat-error *ngIf="passwordconfirm.hasError('pattern') && (passwordconfirm.dirty || passwordconfirm.touched)">
            Password and confirmation password do not match
        </mat-error>

        <button name="submit" type="submit" [disabled]="formPassword.invalid || disableSubmit === true" class="btn btn-success changebtn" data-test="Password-ChangeSubmitButton">Change
            Password</button>
    </form>
</div>