import { Modifier } from './modifier';
import { OptionalGroup } from './optional-group';
import { OptionalItem } from 'projects/common/src/lib/optional-item';
import { AvailableTime } from './menu-category';

export enum BeverageTypeEnum {
    None = 0,
    Beverage = 1,
    CockTail = 2,
    Beer = 4,
    Wine = 5,
}

export enum BeverageSizePriceEnum {
    Small = 0,
    Medium = 0.50,
    Large = 1.00
}

export class Item {
    public _id: any;
    public itemNum: string;
    public merchantid: string;
    public itemid: number;
    public itemtype: string;
    public categoryid: number;
    public categoryName: string;        /// for reporting
    public subcategoryid: number;
    public seqnum: number;
    public price: number;
    public cost: number;
    public orig_price?: number;
    public qty: number;
    public qtyOnHand: number;
    public inventoryTracked: boolean;
    public lowLevelQty: number;
    public minqty: number;
    public maxqty: number;
    public thumb: string;
    public title: string;
    public desc: string;
    public url: string;
    public featured: boolean;
    public icon: string;
    public image: string;
    public isCrossSellSub: boolean;
    public images: ItemImage[];
    public cross_sell: string;
    public cross_sell_label: string;
    public temperature?: string;
    public beverage?: boolean;
    public beverage_type?: BeverageTypeEnum;
    public imported?: boolean;
    public size?: string;
    public sides_group: number;
    public maxsides: number;
    public sides?: OptionalItem[] = [];
    public side_groups: OptionalGroup[] = [];
    public option_group: number;
    public option_groups: OptionalGroup[] = [];
    public options?: OptionalItem[] = [];
    public modifier_groups: OptionalGroup[] = [];
    public modifierGroupsNumber: number[] = [];
    public modifiers?: OptionalItem[] = [];
    public cross_sell_group: string;
    public cross_sell_items: number[] = [];
    public comments?: string;
    public seat_number?: number;
    public show_details?: boolean;
    public for: string;
    public allowSpecialInstructions: boolean;
    public specialInstruction: string;
    public labeledPrice: string;
    // Tax for XT items
    public taxAmount: number;
    public taxPercent: number;
    public active: boolean;
    public ageVerificationRequired: boolean;
    public minimumAgeRequired: number;
    public ageVerificationText: string;
    public item86: boolean;
    public Item86: boolean;
    public onlyFor86: boolean;

    public isHidden: boolean;
    public available: AvailableTime;
    public disableModifiers: boolean;
    public ratioGraphic: string;

    public allowRatio: boolean = false;
    public wholeModAvailable: boolean = false;
    public leftModAvailable: boolean = false;
    public rightModAvailable: boolean = false;

    public wholeMods: OptionalItem[] = [];
    public leftMods: OptionalItem[] = [];
    public rightMods: OptionalItem[] = [];
    public printer: number;

    constructor() {
        // this._id = '';
        this.itemNum = '';
        this.merchantid = '';
        this.itemid = 0;
        this.itemtype = '';
        this.categoryid = 0;
        this.categoryName = '';
        this.subcategoryid = 0;
        this.seqnum = 0;
        this.price = 0;
        this.cost = 0;
        this.orig_price = 0;
        this.qtyOnHand = 99;
        this.inventoryTracked = false;
        this.lowLevelQty = 0;
        this.minqty = 1;
        this.maxqty = 99;
        this.thumb = '';
        this.title = '';
        this.desc = '';
        this.url = '';
        this.featured = true;
        this.icon = '';
        this.image = '';
        this.isCrossSellSub = false;
        this.cross_sell = '';
        this.cross_sell_label = '';

        this.images = [];
        for (let index = 0; index < 5; index++) {
            this.images.push(new ItemImage());
        }

        this.temperature = '';
        this.beverage = false;
        this.beverage_type = BeverageTypeEnum.None;
        this.imported = false;
        this.size = 'SM';
        this.option_group = 0;
        this.option_groups = [];
        this.maxsides = 1;
        this.sides = [];
        this.sides_group = 0;
        this.side_groups = [];
        this.options = [];
        this.modifiers = [];
        this.modifier_groups = [];
        this.modifierGroupsNumber = [];
        this.comments = '';
        this.seat_number = 1;
        this.show_details = false;
        this.for = '';
        this.allowSpecialInstructions = false
        this.specialInstruction = '';
        this.taxAmount = 0;
        this.taxPercent = 0;
        this.labeledPrice = '';
        this.active = true;
        this.ageVerificationRequired = false;
        this.minimumAgeRequired = 21;
        this.ageVerificationText = '';
        this.item86 = false;
        this.Item86 = false;
        this.onlyFor86 = false;

        this.isHidden = false;
        this.available = new AvailableTime();
        this.disableModifiers = false;
        this.ratioGraphic = 'None';
        this.printer = 0;
    }
}

export class ItemImage {
    public url: string;
    public enabled: boolean;

    constructor() {
        this.enabled = false;
        this.url = '';
    }
}
