import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxMaskModule } from 'ngx-mask';

import { AgmCoreModule } from '@agm/core';
import { BREAKPOINT, FlexLayoutModule } from '@angular/flex-layout';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthIntercept } from 'projects/common/src/lib/auth-intercept';
import { HttpGlobalInterceptor } from 'projects/common/src/lib/http-interceptor';
import { LogIn } from 'projects/common/src/lib/login';
import { MaterialDesignModule } from 'projects/common/src/lib/material-design/material-design.module';
import { User } from 'projects/common/src/lib/user';
import { SideNavService } from 'projects/services/src/lib/side-nav.service';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
// import { ButtonsModule } from "@progress/kendo-angular-buttons";

import { TextMaskModule } from 'angular2-text-mask';

import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { NgRatingBarModule } from 'ng-rating-bar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReceiptsModule } from 'projects/receipts/src/app/app.module';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { AddressDialogComponent } from './components/address-dialog/address-dialog.component';
import { AddressSelectionComponent } from './components/address-dialog/address-selection/address-selection.component';
import { ApplePayVerificationComponent } from './components/apple-pay-verification/apple-pay-verification.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmedComponent } from './components/confirmed/confirmed.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { EgiftComponent } from './components/egift/egift.component';
import { HealthcheckComponent } from './components/healthcheck/healthcheck.component';
import { Http404Component } from './components/http404/http404.component';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { ItemDetailsModalComponent } from './components/item-details-modal/item-details-modal.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { MapModule } from './components/map/map.module';
import { MerchantPromptDialogComponent } from './components/merchant-prompt-dialog/merchant-prompt-dialog.component';
import { ModifierGroupsModalComponent } from './components/modifier-groups-modal/modifier-groups-modal.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { PaymentDialogComponent } from './components/payment-dialog/payment-dialog.component';
import { ReceiptDialogComponent } from './components/receipt-dialog/receipt-dialog.component';
import { RegistrationDialogComponent } from './components/registration-dialog/registration-dialog.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ScanToPayComponent } from './components/scan-to-pay/scan-to-pay.component';
import { ScheduleDialogComponent } from './components/schedule-dialog/schedule-dialog.component';
import { StoreCartComponent } from './components/store-cart/store-cart.component';
import { StoreCheckoutComponent } from './components/store-checkout/store-checkout.component';
import { StoreInfoDialogComponent } from './components/store-info-dialog/store-info-dialog.component';
import { StoreComponent } from './components/store/store.component';

const EXTRA_BREAKPOINTS = [{
  alias: 'xs.landscape',
  suffix: 'XsLandscape',
  mediaQuery: 'screen and (orientation: landscape) and (max-width: 559px)',
  priority: 1000,
  overlapping: false
}];

const MY_CUSTOM_FORMATS = {
  parseInput: 'LLL',
  fullPickerInput: 'LLL',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LLL',
  monthYearA11yLabel: 'MMMM YYYY',
};


export function getBaseHref() {
  return document.getElementsByTagName('base')[0].href;
}

@NgModule({
  declarations: [
    AppComponent,
    AddressDialogComponent,
    ConfirmDialogComponent,
    AddressSelectionComponent,
    DialogComponent,
    Http404Component,
    PasswordResetComponent,
    ItemDetailsModalComponent,
    LoginDialogComponent,
    PaymentDialogComponent,
    ReceiptDialogComponent,
    RegistrationDialogComponent,
    ScheduleDialogComponent,
    StoreComponent,
    StoreCartComponent,
    StoreCheckoutComponent,
    StoreInfoDialogComponent,
    ApplePayVerificationComponent,
    ScanToPayComponent,
    PasswordChangeComponent,
    ImageDialogComponent,
    ModifierGroupsModalComponent,
    MerchantPromptDialogComponent,
    RegistrationComponent,
    EgiftComponent,
    HealthcheckComponent,
    ConfirmedComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'deliverme' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RecaptchaModule,
    MapModule,
    RecaptchaFormsModule,
    MaterialDesignModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatRadioModule,
    TextMaskModule,
    NgxSpinnerModule,
    AppRoutingModule,
    ReceiptsModule,
    SocialLoginModule,
    NgRatingBarModule,
    MatSidenavModule,
    TelerikReportingModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBqTbkSC9_LkMyM_JrDkPYp9AX-ejoAWmo', libraries: ['geometry', 'places'] }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialDesignModule,
    AppRoutingModule,
    RecaptchaModule,
    MapModule
  ],
  providers: [
    HttpClientModule,
    AuthenticationService,
    AuthIntercept,
    LogIn,
    User,
    SideNavService,
    DeviceDetectorService,
    MatIconRegistry,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpGlobalInterceptor,
      multi: true
    },
    { provide: 'BASE_URL', useFactory: getBaseHref },
    {
      provide: BREAKPOINT,
      useValue: EXTRA_BREAKPOINTS,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1012903490271-kct1279j8bvl9f4ai13d4p9dfuk32hcd.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2515961195309358'),
          },
        ],
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
  ]
})
export class AppModule { }
