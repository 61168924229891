<div id="divAddressDialog">
  <div class="row">
    <h3 class="col-10"><b style="padding-left: 25px;">Customer Information</b></h3>
    <div class="col-2">
      <a class="nav-link float-right" style="color: black;" data-dismiss="modal" [routerLink]="[]"
        (click)="dialogRef.close();">
        <i class="fas fa-times fa-lg"></i>
      </a>
    </div>
  </div>
  <mat-dialog-content class="container-fluid">
    <form [formGroup]="infoForm" style="width: 97%;">
      <div class="row" *ngIf="infoForm" style="align-items: flex-start; padding: 0 24px;">
        <div class="col-md-12 form-group">
          <label>Name*</label>
          <input type="text" name="name" formControlName="name" (change)="formChange(infoForm)" 
            class="form-control form-control-sm bold-input" data-test="Address-DialogName*Input" required />
          <!--                     <mat-error *ngIf="name.invalid && (name.dirty || name.touched)" class="help-block">Name Required
                    </mat-error> -->
          <mat-error *ngIf="name.invalid && name.errors.invalidName && (name.dirty || name.touched)" class="help-block">
            First and Last Name Required
          </mat-error>
        </div>
        <div class="col-md-12 form-group">
          <label>Email Address*</label>
          <input type="email" id="adEmail" name="email" formControlName="email" (change)="formChange(infoForm)"
            class="form-control form-control-sm bold-input" data-test="Address-DialogEmailAddress*Input" required />
          <mat-error *ngIf="email.hasError('pattern') || email.hasError('required') && (email.dirty || email.touched)">
            Valid Email Required
          </mat-error>
        </div>
        <div class="col-md-12 form-group">
          <label>Mobile Phone Number (Required for SMS Notifications)*</label>
          <input type="tel" id="adPhone" [textMask]="{mask: regexList.us_phone_masked}" name="phone"
            formControlName="phone" (change)="formChange(infoForm)" class="form-control form-control-sm bold-input" data-test="Address-DialogMobilePhoneNumber*Input"
            required />
          <mat-error *ngIf="phone.hasError('pattern') || phone.hasError('required') && (phone.dirty || phone.touched)">
            Valid Phone Number Required (Tolls Not Allowed)
          </mat-error>
        </div>

        <div class="col-md-12" *ngIf="info.orderType === 3">
          <mat-form-field *ngIf="infoForm" appearance="none" class="my-form-field">
            <div class="row">
              <div class="col-5">
                <label>Address*</label>
              </div>
              <div class="col-7" *ngIf="user">
                <button class="btn btn-link" style="float: right; padding: 0px;" (click)="openSelection()"
                  [disabled]="infoForm.invalid" data-test="Address-DialogSelectAnotherAddressButton">Select Another
                  Address</button>
              </div>
            </div>
            <div class="row">
              <div class="input-group">
                <input id="adStreet1" matInput type="text" required="required" name="street1" formControlName="street1"
                  (keyup)="addressLookUp()" [matAutocomplete]="autocomplete"
                  class="form-control form-control-sm address-autocomplete bold-input" (change)="formChange(infoForm)" data-test="Address-DialogAddress*Input"
                  required />
              </div>
              <div class="input-group">
                <mat-error
                  *ngIf="street1.hasError('pattern') || street1.hasError('required') && (street1.dirty || street1.touched)">
                  Address Required
                </mat-error>
              </div>
            </div>
            <mat-autocomplete #autocomplete="matAutocomplete">
              <mat-option *ngFor="let addr of matches" [value]="addr.formatted_address" (click)="selectedAddress(addr)">
                <small>{{addr.formatted_address}}</small>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-md-6 form-group" *ngIf="info.orderType === 3">
          <label>City</label>
          <input type="text" id="adCity" name="city" formControlName="city" (change)="formChange(infoForm)"
            class="form-control form-control-sm bold-input" data-test="Address-DialogCityInput" required />
          <mat-error *ngIf="city.invalid && (city.dirty || city.touched)" class="help-block">City Required
          </mat-error>
        </div>
        <div class="col-md-3 form-group" *ngIf="info.orderType === 3">
          <label>State</label>
          <mat-form-field appearance="none" style="margin-top: 14px;">
            <select matNativeControl id="state" formControlName="state" class="form-control form-control-sm bold-input"
              (change)="formChange(infoForm)" data-test="Address-DialogStateInput" required>
              <option *ngFor="let s of states" [value]="s">
                {{s}}
              </option>
            </select>
            <mat-error *ngIf="state.invalid && (state.dirty || state.touched)" class="help-block">
              State Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3 form-group" *ngIf="info.orderType === 3">
          <label>Zip Code</label>
          <input type="tel" id="adPostalCode" formControlName="postalCode" placeholder="Zip Code"
            (change)="formChange(infoForm)" class="form-control form-control-sm bold-input" data-test="Address-DialogZipCodeInput" required />
          <mat-error *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)" class="help-block">Zip Code
            Required
          </mat-error>
          <mat-error
            *ngIf="postalCode.hasError('pattern') || postalCode.hasError('required') && (postalCode.dirty || postalCode.touched)">
            Please enter a valid zip code
          </mat-error>
        </div>
        <div class="col-md-12 form-group" *ngIf="user && info.orderType === 3">
          <input type="checkbox" id="newaddress" name="newaddress" style="padding-right: 1rem;margin-right: .5rem;"
            (click)="saveAddress($event)" data-test="SaveNewAddressCheckbox">
          <label for="newaddress" style="font-weight:700;font-size: 12px">Save New Address</label>
        </div>
        <div class="col-md-12 form-group sticky-item">
          <button class="btn btn-success btn-update-delivery" (click)="Update()" [disabled]="infoForm.invalid" data-test="Address-DialogUpdateCustomerInformationButton">
            Update Customer Information
          </button>
        </div>
      </div>
      <!--                 <div class="row"> -->

      <!-- </div>     -->
      <!--            <div class="row">
                <div class="col-md-12 form-group">
                    <button class="btn btn-success btn-update-delivery" (click)="Update()" [disabled]="infoForm.invalid">Update Delivery
                        Information</button>
                </div>
            </div> -->
    </form>
  </mat-dialog-content>
</div>
