export class MenuCategory {
    public _id: any;
    public applicationKey: string;
    public merchantid: string;
    public id: number;
    public categoryid: number;
    public instructionEnabled: boolean;
    public seqnum: number;
    // public subcategoryid: number;
    public thumb: string;
    public title: string;
    public displayname: string;
    public desc: string;
    public url: string;
    public featured: boolean;
    public icon: string;
    public image: string;
    public images: string[];
    public subcategories: MenuCategory[];
    public parentid: any;
    public visible: boolean;
    public Index: number;
    public isHidden: boolean;
    public sortAlphabetically: boolean;
    public available: AvailableTime;
    public isDuplicate = false;
    public printer: number;

    constructor() {
        this.id = 0;
        this.merchantid = '';
        this.categoryid = 0;
        this.instructionEnabled = true;
        this.seqnum = 0;
        // this.subcategoryid = 0;
        this.thumb = '';
        this.title = '';
        this.desc = '';
        this.url = '';
        this.featured = true;
        this.icon = '';
        this.image = '';
        this.images = [];
        this.subcategories = [];
        this.parentid = 0;
        this.visible = true;
        this.Index = 0;
        this.isHidden = false;
        this.sortAlphabetically = false;
        this.available = new AvailableTime();
        this.printer = 0;
    }
}

export class AvailableTime {
    public limitedTime: boolean;
    public timeRange = {
        startTime: "",
        endTime: ""
    };
    public dateOfWeek: boolean[];

    constructor() {
        this.limitedTime = false;
        this.timeRange = {
            startTime: "",
            endTime: ""
        };
        this.dateOfWeek = [true, true, true, true, true, true, true];
    }
}
