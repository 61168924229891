import { Address } from './address';

export class User {
  // public _id = '';
  public _id: string;
  public iso360ID = '';
  public crmid = '';
  public access_token = '';
  public businessName = '';
  public merchantNumber: string = "";
  public firstname = '';
  public lastname = '';
  public groups: string[] = [];
  public server = '';
  public street1 = '';
  public street2 = '';
  public city = '';
  public state = '';
  public postalCode = '';
  public country = '';
  public addresses: Address[] = [];
  public email = '';
  public phone = '';
  public cellphone = '';
  public workphone = '';
  public fax = '';
  public deliveryInstructions = '';
  public isAdmin = false;
  public isMerchant = false;
  public isAutoNotification = true;
  public isAutoSubcribe = true;
  public merchantid = '';
  public profileid = 0;
  public createddate = new Date();
  public lastlogin = new Date();
  public modifieddate = new Date();
  public modifiedby = '';
  public passwordAttempts = 0;
  public locked = false;
  public isGuestLogin = false;
  public paymentTokens: string[] = [];
  public verified = false;
  public key: number = 1;
  public cognitoTokens: any;
  public userSub: string = "";    //aws cognito username

  constructor() {
    this.merchantNumber = "";
    this.addresses = [];
  }
}

export interface LoginResponse {
  username: string;
  firstName: string;
  lastName: string;
  groups: string[];
  authenticationType: string;
  accessToken: string;
  application: string;
}
