<mat-sidenav-container class="sidenav-container" hasBackdrop="false">

  <mat-sidenav-content id="MainContainer" class="sidenav-content" fxLayout="row" fxLayoutAlign="start stretch">

    <div class="menu-container">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="menu-header"><h1>DeliverMe</h1></div>

        <mat-accordion class="menu">

          <mat-expansion-panel *ngFor="let group of linkGroups" hideToggle="true" #MenuGroupPanel>
            <mat-expansion-panel-header>
              <mat-panel-title>{{group.name}}</mat-panel-title>
            </mat-expansion-panel-header>

            <mat-list>
              <mat-list-item *ngFor="let link of group.links" [class.active]="link.active" [class.nav]="link.nav" (click)="link.nav ? Nav(link.route) : Void()">
                {{link.name}}
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>

        </mat-accordion>
      </div>
    </div>

    <div fxFlex="100%" class="content">
      <div class="content-header" fxLayout="row" fxLayoutAlign="end center">
        <a routerLink="/"><img class="deliverme-logo" src="https://epionlineorders.s3.us-east-2.amazonaws.com/assets/images/temp_logo.jpg" data-test="RegistrationDMLogoA"/></a>
      </div>
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>

  </mat-sidenav-content>

</mat-sidenav-container>
