<div class="sticky-cart" *ngIf="merchantInfo">
    <button type="button" id="btnCart" class="btn btn-primary d-md-none cart sidebarCollapse noprint" [hidden]="hideCart" (click)="shoppingCartClicked()" data-test="StoreStickyCartButton">
    <i class="fas fa-shopping-cart"></i>
    <span *ngIf="cartModel && cartModel.itemList" class="cart-basket d-flex align-items-center justify-content-center">
      {{ cartModel.itemList.length }}
    </span>
    <span *ngIf="!cartModel" class="cart-basket d-flex align-items-center justify-content-center">
      {{ 0 }}
    </span>
  </button>
</div>
<div class="wrapper noprint" id="main">
    <!-- Sidebar [ngClass]="{'active': !authCheck}" -->
    <nav id="sidebar" [ngClass]="{'sidebar': true, 'active': setActive()}" *ngIf="merchantInfo">
        <app-store-cart (hideCart)="onHideCart($event)"></app-store-cart>
    </nav>
    <!-- Main Page Content -->
    <div id="content" [ngClass]="{'content': !merchantInfo }">
        <div class="banner-notification" *ngIf="banner" [innerHtml]="banner.html | secureHtml" [ngStyle]="{ 'background-color': banner.backGroundColor }">
        </div>
        <ng-container *ngIf="locations.length > 1 && !merchantInfo">
            <div class="row container merchant-info" [ngClass]="{'container-1450': !merchantInfo }">
                <div class="col-lg-3 col-sm-1 col-xs-1 d-none d-xl-block">
                    <img class="merchant-logo" [src]="locations[0].logo" alt="">
                </div>

                <div class="col-lg-auto col-lg-9 col-sm-11 col-xs-11">
                    <div class="row">
                        <h1>{{locations[0].businessName}}</h1>
                    </div>

                    <div class="row" class="box">
                        <div class="row">
                            <span class="select-location-banner">Select Your Location To Begin Online Order</span>
                        </div>
                        <div class="row" style="margin-right: 0px !important">
                            <div class="col-sm-5" style="margin: 4px" *ngFor="let loc of locations">
                                <div>
                                    <ul>
                                        <li>
                                            <div class="row">
                                                <div class="col-12">
                                                    <button class="btn btn-link button-location" (click)="selectedMerchant(loc)" data-test="StoreLocationButton"><span
                              style="font-weight:700;">{{loc.linkText}}</span></button>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="row">
                                                <div class="col-1 col-sm-3 col-xs-3">
                                                    <i class="fas fa-clock"></i>
                                                </div>
                                                <div class="col-10 col-sm-9 col-xs-9" style="padding: 0px !important;">
                                                    <span *ngIf="!isClosed" style="font-weight: 600;">
                            {{openTime}} &#8729;
                            {{waittime}} Minute Order Wait Time</span>
                                                    <span *ngIf="isClosed" class="text-danger" [class.paddin-left-none]="!openTime" style="font-weight: 600;">Online
                            ordering is currently not
                            available.</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="row">
                                                <div class="col-1 col-sm-3 col-xs-3">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                </div>
                                                <div class="col-10 col-sm-9 col-xs-9" style="padding: 0px !important;">
                                                    ({{loc.distance}} mi) &#8729;
                                                    <span style="font-weight: 500;">{{loc.merchantAddress}}</span>
                                                    <button class="btn btn-link btn-more-info" (click)="openStoreInfoDialog()" data-test="StoreMoreInfoButton1">More Info</button>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="row">
                                                <div class="col-1 col-sm-3 col-xs-3">
                                                    <i class="fas fa-mobile"></i>
                                                </div>
                                                <div class="col-10 col-sm-9 col-xs-9" style="padding: 0px !important;">
                                                    {{loc.phone}}
                                                </div>
                                            </div>
                                        </li>
                                        <li *ngIf="deliveryEnabled">
                                            <div class="row">
                                                <div class="col-1 col-sm-3 col-xs-3">
                                                    <i class="fas fa-dollar-sign"></i>
                                                </div>
                                                <div class="col-10 col-sm-9 col-xs-9" style="padding: 0px !important;">
                                                    ${{loc.settings.minorderamt||'0'}} Minimum &#8729; {{deliveryfee}}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid" *ngIf="locations[0].backgroundImage || !merchantInfo">
                <div class="row">
                    <div class="col-12 banner text-right" [style.backgroundImage]="backgroundImage()">
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="locations.length > 0 && merchantInfo">

            <!--       <nav *ngIf="merchantInfo"
        class="deliverme-nav sticky-top navbar navbar-light bg-light justify-content-between sidebarCollapse">
        <a class="merchant-brand navbar-brand" href="#">
          <img *ngIf="merchantInfo" [src]="merchantInfo.logo" width="50" height="50" alt="">
          {{merchantInfo.businessName}}
        </a>
      </nav> -->

            <nav *ngIf="merchantInfo" class="deliverme-nav sticky-top navbar navbar-light bg-light justify-content-between sidebarCollapse">
                <a class="merchant-brand navbar-brand" href="#">
                    <img *ngIf="merchantInfo" [src]="merchantInfo.logo" width="50" height="50" alt=""> {{merchantInfo.businessName}}
                </a>
            </nav>

            <!-- <div class="container-fluid" *ngIf="merchantInfo && merchantInfo.backgroundImage"> -->
            <div class="container" *ngIf="merchantInfo && merchantInfo.backgroundImage">
                <div class="row">
                    <div class="col-12 banner text-right" [style.backgroundImage]="backgroundImage()">
                    </div>
                </div>
            </div>
            <div class="container static-merchant-info" *ngIf="merchantInfo">
                <div class="row">
                    <div class="info-block col-xl-7 col-lg-12 mt-5 mt-md-0">
                        <div class="row">
                            <div class="col-2 d-none d-xl-block" style="padding: 0;" [ngClass]="{'static-merchant-info img.merchant-logo':locations.length === 1}" *ngIf="merchantInfo && merchantInfo.logo">
                                <img class="merchant-logo" [src]="merchantInfo.logo" alt="">
                            </div>
                            <div class="col-xl-10 col-lg-12">
                                <img class="d-xl-none d-lg-block merchant-logo mt-n5 mb-3" [src]="merchantInfo.logo" alt="">
                                <h1 class="static-merchant-info h1">{{merchantInfo.businessName}}</h1>
                                <ul>
                                    <li *ngIf="dOrderStatus.trim() !== ''">
                                        <div class="row">
                                            <div class="col-1">
                                                <!-- <i class="fas fa-calendar-alt"></i> -->
                                                <i class="fas fa-clock"></i>
                                            </div>
                                            <div class="col-10" style="padding: 0px !important;">
                                                <span style="font-weight: 600;">{{dOrderStatus}}</span>
                                                <!-- <span *ngIf="!isClosed" style="font-weight: 600;">
                          {{openTime}} &#8729;
                          {{waittime}} Minute Order Wait Time</span>
                                                <span *ngIf="isClosed" class="text-danger" [class.paddin-left-none]="!openTime" style="font-weight: 600;">{{orderStatus}}</span> -->
                                            </div>
                                        </div>
                                    </li>
                                    <li *ngIf="dOrderStatus.trim() !== ''">
                                        <div class="row">
                                            <div class="col-1">
                                                <!-- <i class="fas fa-calendar-alt"></i> -->
                                                <i class="fas fa-clock"></i>
                                            </div>
                                            <div class="col-10" style="padding: 0px !important;">
                                                <span style="font-weight: 600;">{{pOrderStatus}}</span>
                                                <!-- <span *ngIf="!isClosed" style="font-weight: 600;">
                          {{openTime}} &#8729;
                          {{waittime}} Minute Order Wait Time</span>
                                                <span *ngIf="isClosed" class="text-danger" [class.paddin-left-none]="!openTime" style="font-weight: 600;">{{orderStatus}}</span> -->
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="row">
                                            <div class="col-1">
                                                <i class="fas fa-map-marker-alt"></i>
                                            </div>
                                            <div class="col-10" style="padding: 0px !important;">
                                                <!-- ({{locations[0].distance}} mi) &#8729; -->
                                                ({{merchantInfo.distance}} mi) &#8729;
                                                <span style="font-weight: 500;">{{merchantAddress}}</span>
                                                <button class="btn btn-link btn-more-info" (click)="openStoreInfoDialog()" data-test="StoreMoreInfoButton2">More Info</button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="row">
                                            <div class="col-1">
                                                <i class="fas fa-mobile"></i>
                                            </div>
                                            <div class="col-10" style="padding: 0px !important;">
                                                {{merchantInfo.phone}}
                                            </div>
                                        </div>
                                    </li>
                                    <li *ngIf="deliveryEnabled">
                                        <div class="row">
                                            <div class="col-1">
                                                <i class="fas fa-dollar-sign"></i>
                                            </div>
                                            <div class="col-10" style="padding: 0px !important;">
                                                ${{merchantInfo.settings.minorderamt||'0'}} Minimum &#8729; {{deliveryfee}}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <nav class="searchbar sticky-top" *ngIf="merchantInfo">
            <div class="container-fluid searchbar">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-md-6">
                            <form class="form-inline">
                                <div class="input-group my-2 w-100">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text bg-transparent border-0"><i class="fas fa-search"></i></span>
                                    </div>
                                    <input type="text" class="form-control border-0 bg-transparent" placeholder="Search All Items" name="searchText" [(ngModel)]="searchText" aria-label="form" aria-describedby="form" (input)="SearchAllItems()" [matAutocomplete]="autocomplete" data-test="StoreSearchAllItemsInput"
                                    />

                                    <mat-autocomplete #autocomplete="matAutocomplete">
                                        <mat-option *ngFor="let i of searchItems" [value]="i.title" (click)="SearchAllItems()">
                                            <small>{{i.title}}</small>
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </form>
                        </div>
                        <!-- <div class="col-xl-4 col-md-6 text-lg-right">
              <div class="order-type btn-group w-100 my-2" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-primary">Individual Order</button>
                <button type="button" class="btn btn-outline-primary" disabled="disabled"
                  title="This feature is not yet available">Group Order</button>
              </div>
            </div> -->
                    </div>
                </div>
            </div>
        </nav>

        <nav class="menu-categories sticky-top" *ngIf="merchantInfo">
            <div class="container-fluid">
                <div class="container">
                    <div class="row">
                        <div class="category-btn-group btn-group w-100" role="group" aria-label="Basic example">

                            <button type="button" class="col-xl-2 col-md-4 btn btn-primary-bb d-none d-lg-inline d-md-inline d-sm-inline" [class.active]="selectedCategory===''" (click)="CategoryClicked('')" data-test="StoreFullMenu">
                Full Menu
              </button>

                            <button *ngFor="let c of categories;let i = index" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" type="button" class="col-xl-2 col-md-4 btn btn-primary-bb d-none" [class.d-lg-inline]="i<5" [class.d-md-inline]="i<3" [class.d-sm-inline]="i<1"
                                [class.active]="selectedCategory==c._id" (click)="CategoryClicked(c._id); c.subcategories && c.subcategories.length > 0 ? menuTrigger.openMenu() : menuTrigger.closeMenu()" data-test="StoreCategoriesButton">
                {{c.title}}
                <mat-menu #menu="matMenu">
                  <button *ngFor="let sc of c.subcategories" mat-menu-item (click)="SubCategoryClicked(c._id, sc._id)" data-test="StoreSubcategoriesButton">{{sc.title}}</button>
                            </mat-menu>
                            </button>
                            <button id="btnGroupDrop1" type="button" *ngIf="categories" class="col-xl-2 col-md-4 btn btn-primary-bb dropdown-toggle" [class.d-lg-none]="categories.length<=5" [class.d-md-none]="categories.length<=3" [class.d-sm-none]="categories.length<=1" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" data-test="StoreCatDropdownButton">
                More
              </button>
                            <div class="dropdown-menu">
                                <a *ngFor="let c of categories;let i = index" class="dropdown-item d-block" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" [class.d-lg-none]="i<5" [class.d-md-none]="i<3" [class.d-sm-none]="i<1" [class.active]="selectedCategory==c._id" href="javascript:void(0)"
                                    (click)="CategoryClicked(c._id); c.subcategories && c.subcategories.length > 0 ? menuTrigger.openMenu() : menuTrigger.closeMenu()" data-test="StoreCatDropdownA">
                  {{c.title}}
                  <mat-menu #menu="matMenu">
                    <button *ngFor="let sc of c.subcategories" mat-menu-item (click)="SubCategoryClicked(c._id, sc._id)" data-test="StoreSCButton">{{sc.title}}</button>
                              </mat-menu>
                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

        <div class="container" *ngIf="categoryItems">
            <div class="row" *ngFor="let ci of categoryItems">

                <div class="col-12 category-title" id="popular" *ngIf="ci.items.length > 0">
                    <h2>{{ci.category}}</h2>
                </div>
                <div class="col-xl-6 col-lg-12 pl-xl-0 mb-3" id="item" *ngFor="let item of ci.items">
                    <a [routerLink]="[]" (click)="GetItemDetails(item)" [ngClass]="{'progress-cursor': loadingItem === true}" data-test="StoreGetItemDetailsA">
                        <div class="menu-item">
                            <div class="row mx-0">
                                <div class="col-9 menu-item-details">
                                    <h3 *ngIf="!item.item86">{{item.title}}</h3>
                                    <h3 *ngIf="item.item86 === true">{{item.title}} - <a style="color: red;">Not Available</a></h3>
                                    <div class="description">
                                        <p>{{item.desc}}</p>
                                    </div>
                                    <h2 *ngIf="!item.labeledPrice.startsWith('0.00')">
                                        {{(item.inventoryTracked && item.qtyOnHand
                                        <=0 ) ? 'Out Of Stock' : '$' + item.labeledPrice || item.price.toFixed(2)}} </h2>
                                </div>
                                <div class="col-3 menu-item-image">
                                    <div [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="row" *ngFor="let sci of ci.subCategories" style="width: 100%; margin-left: 0.5px;">

                    <div class="col-12 category-title" id="popular">
                        <h2>{{sci.category}}</h2>
                    </div>
                    <div class="col-xl-6 col-lg-12 pl-xl-0 mb-3" id="item" *ngFor="let sItem of sci.items">
                        <a [routerLink]="[]" (click)="GetItemDetails(sItem)" [ngClass]="{'progress-cursor': loadingItem === true}">
                            <div class="menu-item">
                                <div class="row mx-0">
                                    <div class="col-9 menu-item-details">
                                        <h3 *ngIf="!sItem.item86">{{sItem.title}}</h3>
                                        <h3 *ngIf="sItem.item86 === true">{{sItem.title}} - <a style="color: red;">Not Available</a></h3>
                                        <div class="description">
                                            <p>{{sItem.desc}}</p>
                                        </div>
                                        <h2 *ngIf="!sItem.labeledPrice.startsWith('0.00')">
                                            {{(sItem.inventoryTracked && sItem.qtyOnHand
                                            <=0 ) ? 'Out Of Stock' : '$' + sItem.labeledPrice || sItem.price.toFixed(2)}} </h2>
                                    </div>
                                    <div class="col-3 menu-item-image" style="height: 151px;">
                                        <div [ngStyle]="{ 'background-image': 'url(' + sItem.image + ')' }">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <!--                <div class="row" style="width: 100%; margin-left: 0.5px;" fxLayoutAlign="end center">
                  <mat-paginator #paginator [pageIndex]="0" [pageSize]="100" [length]="10" showFirstLastButtons>
                  </mat-paginator>
                </div> -->
            </div>
        </div>
    </div>
</div>