import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Merchant } from 'projects/common/src/lib/merchant';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { MerchantService } from 'projects/services/src/lib/merchant.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-merchant-prompt-dialog',
  templateUrl: './merchant-prompt-dialog.component.html',
  styleUrls: ['./merchant-prompt-dialog.component.scss']
})
export class MerchantPromptDialogComponent implements OnInit {
  @ViewChild('search') searchElement: ElementRef;

  public merchantList: Merchant[] = [];
  public searchMerchants: Merchant[] = [];
  public searchText: string;
  public merchant: Merchant;

  constructor(
    public dialogRef: MatDialogRef<MerchantPromptDialogComponent>,
    private merchantService: MerchantService,
    public authSvc: AuthenticationService,
    ) { }

  ngOnInit(): void {
    this.merchantService.getAllMerchants().pipe(first()).subscribe((m: Merchant[]) => {
      if (m) {
        this.merchantList = m;

        this.authSvc.updatedProgressBarStatus(false);
        // this.loadMerchant();
      }
    });
  }

  public SearchLocations() {
    const stlower = this.searchText.toLowerCase();
    this.searchMerchants = this.merchantList.filter(m => {
      return (m.businessName && m.businessName.toLowerCase().indexOf(stlower) !== -1) ||
        (m.routeName && m.routeName.toLowerCase().indexOf(stlower) !== -1) ||
        (m.merchantNumber1 && m.merchantNumber1.toLowerCase().indexOf(stlower) !== -1);
    });

    if (this.searchMerchants.length === 1 && this.merchant.businessName !== this.searchMerchants[0].businessName) {
      this.SelectLocation(this.searchMerchants[0]);
    }
  }

  public SelectLocation(m: Merchant) {
    this.searchMerchants = [];
    this.searchText = m.businessName;
    this.merchant = m;
    // this.loadMerchant();
    this.searchElement.nativeElement.blur();
  }

  public Confirm() {
    this.dialogRef.close(this.merchant);
  }

  public Cancel() {
    this.dialogRef.close();
  }
}
