// import { ApplePaymentRequest } from './apple-payment-request';
import { Order } from 'projects/common/src/lib/order';
import { OrderTypeEnum } from 'projects/common/src/lib/ordertype';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, from, Subject, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Transaction } from 'projects/common/src/lib/transaction';
import { EtcPaymentResponse } from 'projects/common/src/lib/etc-transaction-response';
import { OrderService } from 'projects/services/src/lib/order.service';

type ApplePayShippingType =
  'shipping' |
  'delivery' |
  'storePickup' |
  'servicePickup';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json;charset=utf-8',
  })
};

interface Window {
  innerWidth: number;
  location: any;
  ApplePaySession: any;
  session: any;
  PaymentRequest: any;    // samsung
}

declare var window: Window;

export class ApplePay {

  public isSupported: boolean;
  /* public paymentRequest: ApplePayJS.ApplePayPaymentRequest; */
  public paymentRequest: any = {};
  public subscription: Subscription;
  public applepayOrder: Order;
  public methodData: any = {};
  public orderDetails: any = {};
  /*   public paymentOptions: any = {
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      requestShipping: false,
      shippingType: '',
    }; */

  currentPaymentToken: any;
  onpaymentauthorizedCount = 0;

  constructor(private order: Order, private http: HttpClient, private orderService: OrderService, private applePayConfig?: any) {
    // constructor(storename: string, cart: StoreCartModel, http: HttpClient) {
    try {
      // this.http = http;

      /*      if (this.order.ordertype === OrderTypeEnum.Delivery) {
             this.paymentOptions.shippingType = 'delivery';
           } else {
             this.paymentOptions.shippingType = 'storePickup';
           } */

    } catch (error) {
      console.log(error.message);
    }
  }

  public onApplePayLoaded(): any {
    try {
      console.log('onApplePayLoaded');

      const merchantIdentifier = 'com.electronicpayments.www';
      const merchantIdentifier2 = 'api.deliverme.com';
      const merchantIdentifier3 = 'com.deliverme.api';

      if (ApplePaySession.canMakePayments()) {
        if (this.applePayConfig && this.applePayConfig.enabled === true) {
          this.isSupported = true;
          // alert('make payments is supported');
        } else {
          // alert('apple payments disabled');
        }
        // alert('make payments is supported');
      } else {
        alert('make payments not supported');
      }

    } catch (error) {
      console.log('onApplePayLoaded error: ' + error.message);
    }
  }

  public async validateMerchant(url: string): Promise<EtcPaymentResponse> {
    const body = {
      displayName: this.order.businessName,
      validationUrl: url
    };

    return this.http.post<EtcPaymentResponse>('/api/applepay/validatemerchant', body)
      .pipe(
        catchError(this.handleError)
      ).toPromise();
  }

  public getShipMethods(region: string = 'USA'): ApplePayJS.ApplePayShippingMethod[] {
    try {
      return [
        {
          label: 'Free Shipping',
          detail: 'Arrives in 5 to 7 days',
          amount: '0.00',
          identifier: 'FreeShip'
        },
        {
          label: 'USPS Ground',
          detail: 'Arrives in 3 to 5 days',
          amount: '7.00',
          identifier: 'ground'
        },
        {
          label: 'UPS Ground',
          detail: 'Arrives in 3 to 5 days',
          amount: '7.00',
          identifier: 'ground'
        }
      ];
    } catch (error) {
      alert(error.message);
    }
  }

  public calculateShippingCost(sm: string, itemPrice: number): string {
    try {
      switch (sm) {
        case 'USPS':
          return '5.00';
        case 'UPS':
          return '6.00';
        case 'FEDEX':
          return '7.00';
        default:
          return '0.00';
      }
    } catch (error) {
      alert(error.message);
    }
  }

  public async processPayment(payment: Transaction): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.orderService.submitTransaction(payment).subscribe((response: any) => {
        if (response) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, (error: any) => {
        // alert(error.message);
        resolve(false);
      });
    });
  }

  public async processApplePayment(token: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.orderService.processApplePayment(token).subscribe((response: any) => {
        if (response) {
          resolve(response);
        } else {
          resolve(undefined);
        }
      }, (error: any) => {
        // alert(error.message);
        reject(error);
      });
    });
  }

  public async displayPaymentSheet(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {

        let apCountryCode = '';
        let apCurrencyCode = '';
        let apCountry = 'United States';

        if (this.order.customerInformation.country) {
          switch (this.order.customerInformation.country) {
            case 'US':
            case 'USA':
              apCountryCode = 'US';
              apCurrencyCode = 'USD';
              break;
            case 'CA':
            case 'CAN':
              apCountryCode = 'CA';
              apCurrencyCode = 'CAD';
              apCountry = 'Canada';
              break;
            case 'MX':
            case 'MEX':
            case 'MXN':
              apCountryCode = 'MX';
              apCurrencyCode = 'MXN';
              apCountry = 'Mexico';
              break;
          }
        } else {
          apCountryCode = 'US';
          apCurrencyCode = 'USD';
        }

        let supportedVersion = 6;

        if (ApplePaySession.supportsVersion(4)) {
          supportedVersion = 4;
        }

        if (ApplePaySession.supportsVersion(6)) {
          supportedVersion = 6;
        }

        if (ApplePaySession.supportsVersion(7)) {
          supportedVersion = 7;
        }

        if (ApplePaySession.supportsVersion(8)) {
          supportedVersion = 8;
        }

        if (ApplePaySession.supportsVersion(9)) {
          supportedVersion = 9;
        }

        if (ApplePaySession.supportsVersion(10)) {
          supportedVersion = 10;
        }

        if (ApplePaySession.supportsVersion(11)) {
          supportedVersion = 11;
        }

        if (ApplePaySession.supportsVersion(12)) {
          supportedVersion = 12;
        }

        const orderShippingType: ApplePayJS.ApplePayShippingType = (this.order.ordertype === OrderTypeEnum.Delivery) ? 'delivery' : 'storePickup';

        let applePayJSMethod: ApplePayJS.ApplePayPaymentRequest;

        if (this.applePayConfig && this.applePayConfig.applePayJS) {

          applePayJSMethod = this.applePayConfig.applePayJS;

          applePayJSMethod.countryCode = apCountryCode;
          applePayJSMethod.currencyCode = apCurrencyCode;
          applePayJSMethod.shippingType = orderShippingType;
          applePayJSMethod.requiredBillingContactFields = this.applePayConfig.requiredBillingContactFields ? this.applePayConfig.requiredBillingContactFields : ['postalAddress', 'name', 'phone', 'email'];
          applePayJSMethod.requiredShippingContactFields = this.applePayConfig.requiredShippingContactFields ? this.applePayConfig.requiredShippingContactFields : ['postalAddress', 'name', 'phone', 'email'];

          if (!applePayJSMethod.merchantCapabilities) {
            applePayJSMethod.merchantCapabilities = ['supports3DS', 'supportsCredit', 'supportsDebit'];
          }

          if (!applePayJSMethod.supportedNetworks) {
            applePayJSMethod.supportedNetworks = ['visa', 'masterCard', 'amex', 'discover'];
          }

          applePayJSMethod.lineItems = [];

          applePayJSMethod.lineItems.push({
            label: 'Gratuity',
            amount: this.order.tip.toFixed(2)
          });

          if (this.order.serviceFee > 0) {
            applePayJSMethod.lineItems.push({
              label: 'Checkout Fee',
              amount: this.order.serviceFee.toFixed(2)
            });
          }

          if (this.order.convenienceFee > 0) {
            applePayJSMethod.lineItems.push({
              label: 'Convenience Fee',
              amount: this.order.convenienceFee.toFixed(2)
            });
          }

          if (this.order.deliveryFee > 0) {
            applePayJSMethod.lineItems.push({
              label: 'Delivery Fee',
              amount: this.order.deliveryFee.toFixed(2)
            });
          }

          applePayJSMethod.lineItems.push({
            label: 'Tax @' + this.order.taxrate.toFixed(2),
            amount: this.order.tax.toFixed(2)
          });

          applePayJSMethod.lineItems.push({
            label: 'Subtotal',
            amount: this.order.subtotal.toFixed(2)
          });

          applePayJSMethod.total = {
            label: this.order.businessName,
            amount: this.order.totalAmount.toFixed(2),
            type: 'final'
          };

        } else {

          applePayJSMethod = {
            countryCode: apCountryCode,
            currencyCode: apCurrencyCode,
            merchantCapabilities: [
              'supports3DS',
              'supportsDebit',
              'supportsCredit'
            ],
            supportedNetworks: [
              'visa',
              'masterCard',
              'amex',
              'discover'
            ],
            shippingType: orderShippingType,
            requiredBillingContactFields: [
              'postalAddress',
              'name',
              'phone',
              'email'
            ],
            requiredShippingContactFields: [
              'postalAddress',
              'name',
              'phone',
              'email'
            ],
            lineItems: [],
            total: {
              label: this.order.businessName,
              amount: this.order.totalAmount.toFixed(2),
              type: 'final'
            }
          };

          applePayJSMethod.lineItems.push(
            {
              label: 'Gratuity',
              amount: this.order.tip.toFixed(2)
            });

          applePayJSMethod.lineItems.push(
            {
              label: 'Checkout Fee',
              amount: this.order.serviceFee.toFixed(2)
            });

          if (this.order.convenienceFee > 0) {
            applePayJSMethod.lineItems.push({
              label: 'Convenience Fee',
              amount: this.order.convenienceFee.toFixed(2)
            });
          }

          if (this.order.deliveryFee > 0) {
            applePayJSMethod.lineItems.push({
              label: 'Delivery Fee',
              amount: this.order.deliveryFee.toFixed(2)
            });
          }

          applePayJSMethod.lineItems.push(
            {
              label: 'Tax @' + this.order.taxrate.toFixed(2),
              amount: this.order.tax.toFixed(2)
            });

          applePayJSMethod.lineItems.push(
            {
              label: 'Subtotal',
              amount: this.order.subtotal.toFixed(2)
            });
        }

        if (this.order.ordertype === OrderTypeEnum.Delivery) {
          if (this.order.customerInformation &&
            this.order.customerInformation.firstName &&
            this.order.customerInformation.lastName &&
            this.order.customerInformation.street1 &&
            this.order.customerInformation.city &&
            this.order.customerInformation.state &&
            this.order.customerInformation.postalcode &&
            this.order.customerInformation.email
          ) {
            applePayJSMethod.shippingContact = {
              addressLines: [this.order.customerInformation.street1],
              administrativeArea: this.order.customerInformation.state,
              country: apCountry,
              countryCode: apCountryCode,
              emailAddress: this.order.customerInformation.email,
              familyName: this.order.customerInformation.lastName,
              givenName: this.order.customerInformation.firstName,
              locality: this.order.customerInformation.city,
              phoneNumber: this.order.customerInformation.phone,
              phoneticFamilyName: '',
              phoneticGivenName: '',
              postalCode: this.order.customerInformation.postalcode,
              subAdministrativeArea: '',
              subLocality: ''
            };
          }
        }

        if (this.applePayConfig && this.applePayConfig.debug) {
          alert(JSON.stringify(applePayJSMethod));
        }

        this.paymentRequest = new ApplePaySession(supportedVersion, applePayJSMethod);  // <<-- Apple Pay JS API

        /*      if (this.applePayConfig && this.applePayConfig.debug) {
               alert('this.paymentRequest is instantiated');
             } */

        window.ApplePaySession = this.paymentRequest; // ApplePayJS
        // window.session = this.paymentRequest;

        // Apple Pay JS API
        this.paymentRequest.onvalidatemerchant = async (event: any) => {
          const text = '\nstartSession Results' + '\n';
          /*         if (this.applePayConfig && this.applePayConfig.debug) {
                    alert('onvalidatemerchant');
                  } */

          const merchantSession = await this.validateMerchant(event.validationURL);

          /*         if (this.applePayConfig && this.applePayConfig.debug) {
                    alert(JSON.stringify(merchantSession));
                  } */

          if (typeof merchantSession === 'string' || 'statusCode' in merchantSession) {

            if (this.applePayConfig && this.applePayConfig.debug) {
              alert('merchantSession error. aborting payment request');
            }

            this.paymentRequest.abort();
            return resolve(undefined);
          }

          if (!('merchantIdentifier' in merchantSession && 'merchantSessionIdentifier' in merchantSession && ('nOnce' in merchantSession || 'nonce' in merchantSession))) {
            let errorDescription = 'merchantSession is invalid. Payment Session cancelled by Apple Pay Demo Site.\n';
            if (!('merchantIdentifier' in merchantSession)) {
              errorDescription += 'merchantIdentifier is not found in merchantSession.\n';
            }
            if (!('merchantSessionIdentifier' in merchantSession)) {
              errorDescription += 'merchantSessionIdentifier is not found in merchantSession.\n';
            }
            if (!('nOnce' in merchantSession)) {
              errorDescription += 'nonce is not found in merchantSession\n';
            }
            errorDescription += text;
            alert(errorDescription);
            this.paymentRequest.abort();
            return resolve(undefined);
          }

          if (this.paymentRequest !== null) {
            this.paymentRequest.completeMerchantValidation(merchantSession);
          }
        };

        // Apple Pay JS API
        this.paymentRequest.onshippingcontactselected = (event: any) => {
          const shippingContact = event.shippingContact;
          const shipMethods: ApplePayJS.ApplePayShippingMethod[] = this.getShipMethods(apCountryCode);

          const update = {
            newTotal: {
              type: 'final',
              label: 'Total',
              amount: this.order.totalAmount.toFixed(2)
            },
            newLineItems: applePayJSMethod.lineItems
          };

          this.paymentRequest.completeShippingContactSelection(update);
        };

        // Apple Pay JS API
        this.paymentRequest.onshippingmethodselected = (event: any) => {
          const shippingMethod = event.shippingMethod;
          const update = {
            newTotal: {
              type: 'final',
              label: 'Total',
              amount: this.order.totalAmount.toFixed(2)
            },
            newLineItems: applePayJSMethod.lineItems
          };

          this.paymentRequest.completeShippingMethodSelection(update);
        };

        // Apple Pay JS API
        this.paymentRequest.onpaymentmethodselected = (event) => {
          const paymentMethod = event.paymentMethod;
          const update = {
            newTotal: {
              type: 'final',
              label: 'Total',
              amount: this.order.totalAmount.toFixed(2)
            },
            newLineItems: applePayJSMethod.lineItems
          };

          this.paymentRequest.completePaymentMethodSelection(update);
        };

        // Apple Pay JS API
        this.paymentRequest.onpaymentauthorized = async (event: any) => {
          this.onpaymentauthorizedCount += 1;
          const payment = event.payment;
          this.currentPaymentToken = payment.token; // payment.token.paymentData;

          event.payment.order = this.order;

          this.processApplePayment(event.payment).catch((error: any) => {
            if (error) {
              this.paymentRequest.completePayment({ status: ApplePaySession.STATUS_FAILURE });
              reject(error);
            }
          }).then((result) => {
            if (result) {
              this.paymentRequest.completePayment({ status: ApplePaySession.STATUS_SUCCESS });
              resolve(result);
            }
          });
        };

        // Apple Pay JS API
        this.paymentRequest.oncancel = (event) => {
          if (this.applePayConfig && this.applePayConfig.debug) {
            // alert('payment cancel error ' + JSON.stringify(event));
          }
          reject({ message: 'Payment Request cancelled' });
        };

        if (this.paymentRequest) {
          this.paymentRequest.begin(); // <<-- Apple Pay JS API
        } else {
          reject({ message: 'Apple Pay request not defined' });
        }

      } catch (error) {
        // alert('onButtonClick: trycatch error: ' + error.message);
        reject(error);
      }
    });
  }

  private async validateResponse(response: any) {
    try {
      alert((response) ? response : 'response is undefined');
      // const errors = await checkAllValuesAreGood(response);
      await response.complete('success');
    } catch (err) {
      // Something went wrong...
      await response.complete('fail');
    }
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      alert('An error occurred: ' + error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      alert(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError((error.error) ? error.error : error);
  }

}
