import { Injectable, Output, EventEmitter } from '@angular/core';
import { LogIn } from 'projects/common/src/lib/login';
import { User } from 'projects/common/src/lib/user';
import { Observable, throwError, Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public Token: string;
  public LoginData: LogIn;
  public UserData: User;
  public authenticated: boolean;
  public title: string;
  public isDelivery: boolean;
  public isDeliveryOrder: boolean;
  public isPayNow: boolean;
  public isMobile: boolean;
  public routename = '';
  public authenticatedDelegate = new Subject<boolean>();
  public authenticatedEvent$ = this.authenticatedDelegate.asObservable();
  public add_beverage: boolean;

  public routenameDelegate = new Subject<string>();
  public routenameEvent$ = this.routenameDelegate.asObservable();

  constructor(public loginData: LogIn,
    public deviceService: DeviceDetectorService,
     public userData: User) {
    this.LoginData = new LogIn();
    this.UserData = new User();
    this.isMobile = this.deviceService.isMobile();
    this.routename = '';
    this.LoginData.username = '';
    this.LoginData.password = '';
    this.LoginData.application = 'deliverme';

    this.UserData.access_token = '';
    this.UserData.firstname = '';
    this.UserData.lastname = null;
    this.UserData.groups = [];
    this.UserData.email = '';
    this.add_beverage = true;
  }

  public setAuthenticated(isvalid: boolean) {
    try {
      this.authenticated = isvalid;
      this.authenticatedDelegate.next(isvalid);
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public isAuthenticated(): any {
    try {
      this.authenticatedDelegate.next(this.authenticated);
      return this.authenticatedDelegate;
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public setBrandName(routename: string) {
    // let crosssells: Array<CrossSell>;

    try {
      this.routename = routename;
      this.routenameDelegate.next(routename);
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getBrandName(): any {
    try {
      this.routenameDelegate.next(this.routename);
      return this.routenameDelegate;
    } catch (error) {
      return throwError(new Error(error));
    }
  }

}
