import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Merchant } from 'projects/common/src/lib/merchant';
import { Item } from 'projects/common/src/lib/item';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, from, Subject, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MenuCategory } from 'projects/common/src/lib/menu-category';

const API_STORAGE_KEY = 'categories';
const API_URL = 'https://localhost/api';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private menuCategories: MenuCategory;
  private items: Item[] = [];
  private categoryList: MenuCategory[];
  public categorySettingsSaved = true;
  public _categorySettingsSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.categorySettingsSaved);
  public categorySettingsSavedStatus = this._categorySettingsSaved.asObservable();

  constructor(
    public http: HttpClient,
    private storage: StorageMap
  ) {
  }

  public updatedcategorySettingsSaved(status: boolean) {
    this._categorySettingsSaved.next(status);
  }

  public get CategorySettingsSaved() {
    return this._categorySettingsSaved;
  }

  public getCategories(merchantid: string): Observable<MenuCategory[]> {
    try {
      return this.http.get<MenuCategory[]>('/api/category/a/' + merchantid)
      .pipe(
        catchError(this.handleError)
      );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getDMCategories(merchantid: string): Observable<MenuCategory[]> {
    try {
      return this.http.get<MenuCategory[]>('/api/category/m/' + merchantid)
      .pipe(
        catchError(this.handleError)
      );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public updateCategories(data: MenuCategory[]): Observable<any> {
    return this.http.put<any>('/api/manage/category', data)
      .pipe(
        catchError(this.handleError)
      );
  }

  public syncCategories(merchantid: string): Observable<MenuCategory[]> {
    try {
      return this.http.get<MenuCategory[]>('/api/category/s/' + merchantid)
      .pipe(
        catchError(this.handleError)
      );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getCategory(merchantid: string, categoryid: number, refresh: boolean = false): Observable<MenuCategory> {
    try {
      return this.http.get<MenuCategory>('/api/category/i/' + merchantid + '/' + categoryid)
      .pipe(
        catchError(this.handleError)
      );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getCategoryItems(id: string, merchantid: string): Observable<Item[]> {
    try {
      return this.http.get<Item[]>('/api/item/list/' + id + '/' + merchantid)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public deleteCategory(category: MenuCategory, merchant: Merchant): Observable<any> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          applicationKey: merchant.applicationKey,
          merchantid: merchant.merchantid.toString()
        })
      };

      return this.http.delete<any>('/api/manage/category/' + merchant._id + '/' + category.id, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError((error.error) ? error.error : error);
  }

  private setLocalStorage(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data).subscribe(() => { });
  }

  // Get cached API result
  private getLocalStorage(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`);
  }

  public setCategoryList(categories: MenuCategory[]) {
    // this.storage.set('current_item', item).subscribe(() => { });
    this.categoryList = categories;
  }

  public get CategoryList() {
    return this.categoryList;
  }
}
