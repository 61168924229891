import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export class Regex {
  public us_phone_masked = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public decimal_masked = /^\(\d{*}\).\d{3}/; //[/\d/, '.', /\d/, /\d/];
  // public test = ['*', '*', '*', '*', ' ', '*', '*', '*', '*', ' ', '*', '*', '*', '*', ' ', /\d/, /\d/, /\d/, /\d/];

  public us_business_phone_regex = /^\(\d{3}\)\s\d{3}-\d{4}/;
  public us_phone_regex = /^\((?!800|888|877|866|855|844|833)\d{3}\)\s([0-9]{3})?[\\s.-]*\)?[\\s.-]*[0-9]{3}[\\s.-]*[0-9]{4}/;
  public ip_address = /^\((?!800|888|877|866|855|844|833)\d{3}\)\s([0-9]{3})?[\\s.-]*\)?[\\s.-]*[0-9]{3}[\\s.-]*[0-9]{4}/;

  public decimal = /^\\d{*}\.\d{2}/;
  public postal_regex = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public states: string[] = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY'
  ];

  public email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public ccRegexList = {
    common: [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    amex: [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
    maestro13: [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
    maestro15: [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
    maestro19: [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
    diners_inter: [/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    unionpay19: [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    unknown17: [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    unknown18: [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],

    masked_common: ['*', '*', '*', '*', ' ', '*', '*', '*', '*', ' ', '*', '*', '*', '*', ' ', /\d/, /\d/, /\d/, /\d/],
    masked_amex: ['*', '*', '*', '*', ' ', '*', '*', '*', '*', '*', '*', ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
    masked_maestro13: ['*', '*', '*', '*', ' ', '*', '*', '*', '*', ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
    masked_maestro15: ['*', '*', '*', '*', ' ', '*', '*', '*', '*', '*', '*', ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
    masked_maestro19: ['*', '*', '*', '*', ' ', '*', '*', '*', '*', ' ', '*', '*', '*', '*', ' ', '*', '*', '*', '*', ' ', /\d/, /\d/, /\d/],
    masked_diners_inter: ['*', '*', '*', '*', ' ', '*', '*', '*', '*', '*', '*', ' ', /\d/, /\d/, /\d/, /\d/],
    masked_unionpay19: ['*', '*', '*', ' ', '*', '*', '*', '*', '*', '*', '*', '*', '*', /\d/, /\d/, /\d/, /\d/],
    masked_unknown17: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', /\d/, /\d/, /\d/, /\d/],
    masked_unknown18: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', /\d/, /\d/, /\d/, /\d/],
  };

  public ccMonthOptions: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  public quantity_mask = createNumberMask({
    maskChar: '0',
    prefix: '',
    allowDecimal: false,
    requireDecimal: false
  });

  public item_number_mask = createNumberMask({
    maskChar: '0',
    prefix: '',
    allowDecimal: false,
    requireDecimal: false,
    includeThousandsSeparator: false,
    integerLimit: 26,
    allowLeadingZeroes: true
  });

  public currency_mask = createNumberMask({
    maskChar: '0',
    prefix: '',
    allowDecimal: true,
    requireDecimal: false
  });

  public currency_mask_with_dollar_sign = createNumberMask({
    maskChar: '0.00',
    prefix: '$ ',
    allowDecimal: true,
    requireDecimal: true
  });

  public currency_mask_with_dollar_sign_negative = createNumberMask({
    maskChar: '0.00',
    prefix: '$ ',
    allowNegative: true,
    allowDecimal: true,
    requireDecimal: true
  });

  public ccexpdate = [/\d/, /\d/, '\/', /\d/, /\d/];

  public tax_mask = createNumberMask({
    allowDecimal: true,
    requireDecimal: false,
    // decimalLimit: 4,
    maskChar: '0',
    prefix: '',
    suffix: ' %'
  });

  public fixCCstring = /[^0-9]/gi;
}
