import { OnInit, Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subscription } from 'rxjs';
import { RegistrationService } from 'projects/services/src/lib/registration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, AfterViewInit, OnDestroy {

  // --- ViewChild properties:
  @ViewChildren('MenuGroupPanel') MenuGroupPanels: QueryList<MatExpansionPanel>;

  // --- private properties
  private activeGroupIdx = 0;
  private regSvcSub: Subscription;
  private routerSub: Subscription;

  // --- public properties
  public IsRoot: boolean = true;
  public linkGroups = [
    {
      name: 'Account Setup',
      active: false,
      nav: false,
      links: [
        //{ route: 'registration', name: 'Registration Information', active: false },
        { route: 'information', name: 'Merchant Information', active: false, nav: false },
        { route: 'type', name: 'Restaurant Type', active: false, nav: false },
        { route: 'location', name: 'Restaurant Location', active: false, nav: false }
      ],
    },
    {
      name: 'Services and Hours',
      active: false,
      nav: false,
      links: [
        { route: 'servicetypes', name: 'Service Type', active: false, nav: false },
        { route: 'deliveryarea', name: 'Delivery Coverage Area', active: false, nav: false },
        { route: 'schedule', name: 'Hours of Operation', active: false, nav: false },
      ],
    },
    {
      name: 'Payments and Taxes',
      active: false,
      nav: false,
      links: [
        { route: 'paymentoptions', name: 'Payment Options', active: false, nav: false },
        { route: 'taxation', name: 'Taxation', active: false, nav: false },
      ],
    },
    {
      name: 'Menu Builder',
      active: false,
      nav: false,
      links: [
        { route: 'menubuilder', name: 'Menu Builder', active: false, nav: false },
        { route: 'link2', name: 'Future Link 2', active: false, nav: false },
        { route: 'link3', name: 'Future Link 3', active: false, nav: false },
      ],
    },
  ];


  constructor(
    private _router: Router,
    private _registrationSvc: RegistrationService
  ) {
    let url = this._router.url;
    let activeLink = url.split('/').pop();
    this.IsRoot = activeLink == 'registration';
    this.SwitchActiveLink(activeLink);

    this.regSvcSub = this._registrationSvc.RouteChange.subscribe(r => {
      this._router.navigate([r]);
    });

    this.routerSub = _router.events.subscribe((r) => {
      if (r instanceof NavigationEnd && this.MenuGroupPanels) {
        this.Next(r.url);
      }
    });
  }

  ngOnInit() {
    if (this.IsRoot) {
      this._registrationSvc.ChangeRoute('information');
    }
  }

  ngAfterViewInit() {
    this._OpenActiveGroup();
  }

  ngOnDestroy() {
    this.regSvcSub.unsubscribe();
    this.routerSub.unsubscribe();
  }

  //--- private methods
  private _OpenActiveGroup() {
    //open the active group:
    this.MenuGroupPanels.toArray()[this.activeGroupIdx].open();
    //disable usage of accordiions:
    this.MenuGroupPanels.forEach((p, i) => { if (i > this.activeGroupIdx) p.disabled = true; });
    //only allow navigation through the use of the form's next and previous buttons.
  }

  //--- public methods
  public SwitchActiveLink(linkRoute) {
    this.linkGroups.forEach(lg => {
      lg.nav = false;
      lg.active = false;
      lg.links.forEach(l => {
        l.active = false;
        l.nav = false;
      });
    });

    let linkFound = false;
    this.linkGroups.forEach((lg,i) => {
      if (!linkFound) lg.nav = true;
      lg.links.forEach((l) => {
        if (l.route == linkRoute) {
          this.activeGroupIdx = i;
          l.active = true;
          l.nav = true;
          linkFound = true;
        }
        if (!linkFound) l.nav = true;
      });
    });
  }

  public Next(route: string) {
    let activeLink = route.split('/').pop();
    this.IsRoot = activeLink == 'registration';
    if (this.IsRoot) {
      this._registrationSvc.ChangeRoute('information');
    }
    if (this.MenuGroupPanels) this.MenuGroupPanels.forEach((p) => { p.disabled = false; });
    this.SwitchActiveLink(activeLink);
    this._OpenActiveGroup();
  }

  public Nav(route) { this._router.navigate([`/registration/${route}`]); }
  public Void() { }

}


//import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
//import { Router } from '@angular/router';
//import { GlobalService } from 'projects/services/src/lib/global.service';
//import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
//import { User } from 'projects/common/src/lib/user';
//import { Address } from 'projects/common/src/lib/address';
//import { Observable, Subscription } from 'rxjs';
//import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
//import { StorageMap } from '@ngx-pwa/local-storage';
//import { Register } from 'projects/common/src/lib/register';
//import { GoogleApiService } from 'projects/services/src/lib/google-api.service';
//import { SmsService } from 'projects/services/src/lib/sms.service';
//import { async } from '@angular/core/testing';

//const postal_regex = /^[0-9]{5}$/;
//const phone_regex = /^\d{10}$/;
//const phone10_regex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

//const API_STORAGE_KEY = 'deliverme';
//const API_URL = 'https://www.deliverme.com/api';

//@Component({
//  selector: 'registration',
//  templateUrl: './registration.component.html',
//  styleUrls: ['./registration.component.scss']
//})
//export class RegistrationComponent implements OnInit, OnDestroy {
//  timeoutId: any;
//  search_timeout: any;
//  street_matches: any[];
//  ins_street_matches: any[];
//  public registration: Register = new Register();
//  user: User;

//  disableSubmit = true;

//  public us_phone_regex = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
//  public recentToken = '';

//  public recaptchSubscription: Subscription;
//  public captchListenerSubscription: Subscription;

//  states: string[] = [
//    'AK',
//    'AL',
//    'AR',
//    'AZ',
//    'CA',
//    'CO',
//    'CT',
//    'DE',
//    'FL',
//    'GA',
//    'HI',
//    'IA',
//    'ID',
//    'IL',
//    'IN',
//    'KS',
//    'KY',
//    'LA',
//    'MA',
//    'MD',
//    'ME',
//    'MI',
//    'MN',
//    'MO',
//    'MS',
//    'MT',
//    'NC',
//    'ND',
//    'NE',
//    'NH',
//    'NJ',
//    'NM',
//    'NV',
//    'NY',
//    'OH',
//    'OK',
//    'OR',
//    'PA',
//    'RI',
//    'SC',
//    'SD',
//    'TN',
//    'TX',
//    'UT',
//    'VA',
//    'VT',
//    'WA',
//    'WI',
//    'WV',
//    'WY'
//  ];

//  formRegistration = new FormGroup({
//    lastname: new FormControl(this.registration.lastname, [Validators.required]),
//    firstname: new FormControl(this.registration.firstname, [Validators.required]),
//    street1: new FormControl(this.registration.addresses[0].street1, [Validators.required]),
//    street2: new FormControl(this.registration.addresses[0].street2, []),
//    city: new FormControl(this.registration.addresses[0].city, [Validators.required]),
//    state: new FormControl(this.registration.addresses[0].state, [Validators.required]),
//    postalcode: new FormControl(this.registration.addresses[0].postalCode, [
//      Validators.required,
//      Validators.minLength(5),
//      Validators.maxLength(10)
//    ]),
//    email: new FormControl(this.registration.email, [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
//    phone: new FormControl(this.registration.phone, []),
//    fax: new FormControl(this.registration.fax, []),
//    password: new FormControl(this.registration.password, [
//      Validators.required,
//      Validators.minLength(8),
//      Validators.maxLength(15)
//    ]),
//    isAutoNotification: new FormControl(this.registration.isAutoNotification, []),
//    isAutoSubcribe: new FormControl(this.registration.isAutoSubcribe, []),
//    passwordconfirm: new FormControl(this.registration.passwordconfirm, [
//      Validators.required,
//      Validators.minLength(8),
//      Validators.maxLength(15)
//    ]),
//    recaptcha: new FormControl(null, Validators.required)
//  });

//  // get patient(): any { return this.formRegistration.get('patient'); }
//  // get businessName(): any { return this.formRegistration.get('businessName'); }
//  get firstname(): any { return this.formRegistration.get('firstname'); }
//  get lastname(): any { return this.formRegistration.get('lastname'); }
//  get street1(): any { return this.formRegistration.get('street1'); }
//  get street2(): any { return this.formRegistration.get('street2'); }
//  get city(): any { return this.formRegistration.get('city'); }
//  get state(): any { return this.formRegistration.get('state'); }
//  get postalcode(): any { return this.formRegistration.get('postalcode'); }
//  get email(): any { return this.formRegistration.get('email'); }
//  get phone(): any { return this.formRegistration.get('phone'); }
//  get fax(): any { return this.formRegistration.get('fax'); }
//  get password(): any { return this.formRegistration.get('password'); }
//  get passwordconfirm(): any { return this.formRegistration.get('passwordconfirm'); }
//  get recaptcha(): any { return this.formRegistration.get('recaptcha'); }

//  constructor(public router: Router,
//    public google: GoogleApiService,
//    public localStorage: StorageMap,
//    public globalservice: GlobalService,
//    public smsService: SmsService,
//    public authenticationService: AuthenticationService) {
//    (async () => {
//      const registration: any = await this.getLocalStorage('register');
//      console.log(registration);
//    })();
//  }

//  ngOnInit() {
//    console.log('ngOnInit');
//    /*     this.captchListenerSubscription = this.recaptchaV3Service.onExecute
//         .subscribe((data) => {
//           this.executionLog.push(data);
//         }); */
//  }

//  ngOnDestroy() {
//    if (this.recaptchSubscription) {
//      this.recaptchSubscription.unsubscribe();
//    }
//  }

//  public addressLookUp(): void {
//    clearTimeout(this.search_timeout);

//    this.search_timeout = setTimeout(() => {
//      this.google.search(this.formRegistration.controls.street1.value).subscribe(d => {
//        // console.log('d', d);
//        this.street_matches = d;
//      }, error => {
//        console.log('error:', error);
//        // this.snackBar.open(error.error, null, { duration: 2000 });
//      });
//    }, 1000);
//  }

//  googlePlaces(): void {
//    clearTimeout(this.search_timeout);

//    this.search_timeout = setTimeout(() => {
//      this.google.search(this.formRegistration.get('street').value).subscribe(d => {
//        // console.log('d', d);
//        this.street_matches = d;
//      }, error => {
//        console.log('error:', error);
//        // this.snackBar.open(error.error, null, { duration: 2000 });
//      });
//    }, 2000);
//  }

//  selectedAddress(addr: any): void {

//    if (!this.registration) {
//      this.registration = new Register();
//    }

//    this.registration.addresses[0].street2 = '';

//    for (let i = 0; i < addr.address_components.length; i++) {
//      switch (addr.address_components[i].types[0]) {
//        case 'street_number':
//          this.registration.addresses[0].street1 = addr.address_components[i].short_name;
//          break;
//        case 'route':

//          this.registration.addresses[0].street1 += ' ' + addr.address_components[i].short_name;

//          this.formRegistration.patchValue({
//            street1: this.registration.addresses[0].street1.trim()
//          });
//          /*           (this.formRegistration.controls.street).setValue(addr.address_components[i].short_name); */

//          break;
//        case 'locality':
//          (this.formRegistration.controls.city).setValue(addr.address_components[i].short_name);
//          this.registration.addresses[0].city = addr.address_components[i].short_name;
//          break;
//        case 'administrative_area_level_1':
//          (this.formRegistration.controls.state).setValue(addr.address_components[i].short_name);
//          this.registration.addresses[0].state = addr.address_components[i].short_name;
//          break;
//        case 'country':

//          break;
//        case 'postal_code':
//          (this.formRegistration.controls.postalcode).setValue(addr.address_components[i].short_name);
//          this.registration.addresses[0].postalCode = addr.address_components[i].short_name;
//          break;
//      }
//    }
//  }

//  public handleToken(token: string): void {
//    console.log('recaptcha token: ' + token);
//  }

//  /*   public executeAction(action: string): void {
//      if (this.recaptchSubscription) {
//        this.recaptchSubscription.unsubscribe();
//      }
//      this.recaptchSubscription = this.recaptchaV3Service.execute('importantAction')
//        .subscribe((token) => this.handleToken(token));
//    } */

//  public formatToken(token: string): string {
//    if (!token) {
//      return '(empty)';
//    }

//    return `${token.substr(0, 7)}...${token.substr(-7)}`;
//  }

//  public resolved(event): void {
//    this.disableSubmit = false;
//    console.log(event);
//  }

//  public confirmPassword(): void {
//    try {

//    } catch (error) {

//    }
//  }

//  public formChange(form: any): void {
//    try {
//      // this.registration.businessName = form.controls.businessName.value;
//      this.registration.firstname = form.controls.firstname.value;
//      this.registration.lastname = form.controls.lastname.value;
//      this.registration.addresses[0].street1 = form.controls.street1.value;
//      this.registration.addresses[0].street2 = form.controls.street2.value;
//      this.registration.addresses[0].city = form.controls.city.value;
//      this.registration.addresses[0].state = form.controls.state.value;
//      this.registration.addresses[0].postalCode = form.controls.postalcode.value;
//      this.registration.email = form.controls.email.value;
//      this.registration.phone = form.controls.phone.value;
//      this.registration.isAutoNotification = form.controls.isAutoNotification.value;
//      this.registration.isAutoSubcribe = form.controls.isAutoSubcribe.value;
//      this.registration.fax = form.controls.fax.value;
//    } catch (error) {

//    }
//  }

//  public register(form: any): void {
//    try {

//      this.registration.firstname = form.controls.firstname.value;
//      this.registration.lastname = form.controls.lastname.value;
//      this.registration.email = form.controls.email.value;
//      this.registration.phone = form.controls.phone.value;
//      this.registration.isAutoNotification = form.controls.isAutoNotification.value;
//      this.registration.isAutoSubcribe = form.controls.isAutoSubcribe.value;
//      this.registration.fax = form.controls.fax.value;
//      this.registration.password = form.controls.password.value;
//      this.registration.passwordconfirm = form.controls.passwordconfirm.value;

//      this.setLocalStorage('register', this.registration);

//      const result: any = this.authenticationService.register(this.registration).subscribe(data => {
//        console.log(data);

//        this.user = data;

//        const message = {
//          to_numbers: [this.user.phone],
//          storename: 'deliverme.com',
//          type: 1,
//          userid: this.user._id
//        };

//        if (this.user.phone && this.user.isAutoSubcribe) {
//          this.smsService.sendMessage(message).subscribe();
//        }

//        if (this.user.isAutoNotification) {
//          message.type = 2;
//          this.smsService.sendMessage(message).subscribe();
//        }

//        this.localStorage.set('user', this.user).subscribe(() => { });
//        this.globalservice.UserData = data;
//        this.localStorage.set('token', data.access_token).subscribe(() => { });
//        this.globalservice.setAuthenticated(true);
//        this.globalservice.Token = data.access_token;
//        //this.authenticationService.authenticationChanged(data);

//        this.localStorage.get('routename').subscribe(async (value: any) => {
//          if (value) {
//            this.globalservice.setBrandName(value);
//            // this.router.navigateByUrl('/home/' + data._id);
//            this.router.navigate(['/' + value + '/categories']);
//          } else {
//            this.router.navigate(['/locations']);
//          }
//        });
//      }, (error) => {
//        if (error.error) {
//          console.log(error.error.message);
//        } else {
//          console.log(error.message);
//        }
//      });
//    } catch (error) {
//      console.log(error.message);
//    }
//  }

//  public setLocalStorage(key, data) {
//    this.localStorage.set(`${API_STORAGE_KEY}-${key}`, data).subscribe((value) => {

//    }, (error) => {
//      console.log(error);
//    });
//  }

//  // Get cached API result
//  public async getLocalStorage(key): Promise<any> {
//    this.localStorage.get(`${API_STORAGE_KEY}-${key}`).subscribe((value: Register) => {
//      this.registration = value;

//      if (!this.registration) {
//        this.registration = new Register();
//      }

//      this.formRegistration.patchValue({
//        firstname: this.registration.firstname,
//        lastname: this.registration.lastname,
//        street1: this.registration.addresses[0].street1,
//        street2: this.registration.addresses[0].street2,
//        city: this.registration.addresses[0].city,
//        state: this.registration.addresses[0].state,
//        postalcode: this.registration.addresses[0].postalCode,
//        email: this.registration.email,
//        phone: this.registration.phone,
//        isAutoNotification: this.registration.isAutoNotification,
//        isAutoSubcribe: this.registration.isAutoSubcribe,
//        fax: this.registration.fax
//      });
//    });
//  }

//}
