<div id="payment-dialog" class="body" style="padding: 0 !important; overflow-x: hidden;">
    <div id="divBillingAddress">
        <div class="row">
            <h3 class="col-10" style="padding-left: 25px;">Payment Method</h3>
            <div class="col-2">
                <a class="nav-link float-right" style="color: black;" data-dismiss="modal" [routerLink]="[]" (click)="dialogRef.close();">
                    <i class="fas fa-times fa-lg"></i>
                </a>
            </div>
        </div>
        <div mat-dialog-content class="container-fluid" *ngIf="ccVal==='new'">
            <form (ngSubmit)="Submit()" [formGroup]="newCardForm">
                <div id="divCardForm" class="row" *ngIf="newCardForm" style="align-items: flex-start;">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label>Cardholder Name*</label>
                                <input class="form-control form-control-sm bold-input" name="name" formControlName="name" (change)="formChange(newCardForm)" required data-test="Payment-DialogCardholderName*Input"/>
                                <mat-error *ngIf="name.invalid && name.errors.invalidName && (name.dirty || name.touched)" class="help-block">First and Last Name Required
                                </mat-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label>Card Number</label>
                                <div style="position:relative;">
                                    <!-- <input type="tel" class="form-control form-control-sm bold-input" name="cardnumber" formControlName="cardnumber" [textMask]="{mask: ccRegex.ccmask}" (input)="ccnumChange()" required /> -->
                                    <input type="tel" class="form-control form-control-sm bold-input" name="cardnumber" formControlName="cardnumber" [hiddenInput]="true" mask="{{ccMask}}" (input)="ccnumChange()" required data-test="Payment-DialogCardNumberInput"/>
                                    <!-- <img *ngIf="cardbrandlogo && cardbrandlogo.value" src="https://www.merchantequip.com/image/?logos={{cardbrandlogo.value}}&height=10" alt="Merchant Equipment Store Credit Card Logos"
                                    style="position:absolute;right:5px;top:50%;transform:translate(0,-50%);font-size:1em !important;" /> -->
                                    <img *ngIf="cardbrandlogo && cardbrandlogo.value" height="32px" src="../../../assets/cc_logos/{{cardbrandlogo.value}}.png" alt="" style="position:absolute;right:5px;top:50%;transform:translate(0,-50%);font-size:1em !important;" />
                                    <!--  <span *ngIf="ccRegex.brand" style="position:absolute;right:5px;top:50%;transform:translate(0,-50%);font-size:1em !important;" class="pw pw-{{ccRegex.brand}}"></span> -->
                                </div>
                                <mat-error *ngIf="cardnumber.invalid && (!cardnumber.errors || !cardnumber.errors.invalidCardNumber) && (cardnumber.dirty || cardnumber.touched)" class="help-block">
                                    Card Number Required.
                                </mat-error>
                                <mat-error *ngIf="cardnumber.errors && cardnumber.errors.invalidCardNumber && (cardnumber.dirty || cardnumber.touched)" class="help-block">
                                    Card Number Invalid
                                </mat-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col form-group">
                                <label>Security Code</label>
                                <input type="tel" class="form-control form-control-sm bold-input" [hiddenInput]="true" mask="XXXX" [maxlength]="ccRegex.mincvv" name="cvv" formControlName="cvv" (change)="formChange(newCardForm)" required data-test="Payment-DialogSecurityCode"/>

                                <mat-error *ngIf="cvv.invalid && (!cvv.errors || !cvv.errors.minlength || !cvv.errors.maxlength || !cvv.errors.invalidCVV) && (cvv.dirty || cvv.touched)" class="help-block">
                                    CVV Required.
                                </mat-error>
                                <mat-error *ngIf="cvv.errors && cvv.errors.minlength && (cvv.dirty || cvv.touched)" class="help-block">
                                    CVV length is {{ccRegex.mincvv}}.
                                </mat-error>
                                <mat-error *ngIf="cvv.errors && cvv.errors.maxlength && (cvv.dirty || cvv.touched)" class="help-block">
                                    CVV length is {{ccRegex.mincvv}}.
                                </mat-error>
                                <mat-error *ngIf="cvv.errors && cvv.errors.invalidCVV && (cvv.dirty || cvv.touched)" class="help-block">
                                    CVV Invalid
                                </mat-error>
                            </div>
                            <div class="col form-group">
                                <label>Exp Date</label>

                                <input type="tel" class="form-control form-control-sm bold-input" name="expdate" placeholder="MM/YY" [textMask]="{mask: regexList.ccexpdate, guide: true, keepCharPositions: true}" formControlName="expdate" required (change)="formChange(newCardForm)" data-test="Payment-DialogExpDateInput"/>

                                <mat-error *ngIf="expdate.invalid && (!expdate.errors || !expdate.errors.invalidDate || !expdate.errors.expiredDate) && (expdate.dirty || expdate.touched)" class="help-block">
                                    Expiration Date Required.
                                </mat-error>
                                <mat-error *ngIf="expdate.errors && expdate.errors.invalidDate && (expdate.dirty || expdate.touched)" class="help-block">
                                    Expiration Date Invalid. Please input 2 digit Month and 2 digit Year!
                                </mat-error>
                                <mat-error *ngIf="expdate.errors && expdate.errors.expiredDate && (expdate.dirty || expdate.touched)" class="help-block">
                                    Credit Card Expired!
                                </mat-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 form-group" *ngIf="data.order.orderType === 3">
                                <input type="checkbox" id="billingaddress" name="billingaddress" style="padding-right: 1rem;margin-right: .5rem;" (change)="formChange(newCardForm)" (click)="importDeliveryAddress()" data-test="Payment-DialogBillingsameasdeliveryCheckbox">
                                <label for="billingaddress" style="font-weight:700;font-size: 12px">Billing address is
                                    the
                                    same
                                    as
                                    delivery
                                    address</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <label>Billing Address</label>
                                <input class="form-control form-control-sm bold-input" name="street1" formControlName="street1" (change)="formChange(newCardForm)" (keyup)="addressLookUp()" [matAutocomplete]="autocomplete" required data-test="Payment-DialogBillingAddressInput"/>
                                <mat-error *ngIf="street1.invalid && (street1.dirty || street1.touched)" class="help-block">
                                    Address Required
                                </mat-error>
                                <mat-autocomplete #autocomplete="matAutocomplete">
                                    <mat-option *ngFor="let addr of matches" [value]="addr.formatted_address" (click)="selectedAddress(addr)">
                                        <small>{{addr.formatted_address}}</small>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label>City</label>
                                <input type="text" required="required" name="city" formControlName="city" (change)="formChange(newCardForm)" class="form-control form-control-sm bold-input" required data-test="Payment-DialogCityInput"/>
                                <mat-error *ngIf="city.invalid && (city.dirty || city.touched)" class="help-block">City Required
                                </mat-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <label>State</label>
                                <mat-form-field appearance="none" style="margin-top: 14px;">
                                    <select matNativeControl id="state" formControlName="state" class="form-control form-control-sm bold-input" (change)="formChange(newCardForm)" required data-test="Payment-DialogStateSelect">
                                        <option *ngFor="let s of states" [value]="s" data-test="Payment-DialogStateOption">
                                            {{s}}
                                        </option>
                                    </select>
                                    <mat-error *ngIf="state.invalid && (state.dirty || state.touched)" class="help-block">
                                        State Required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <label>Zip Code</label>
                                <input type="tel" formControlName="zip" (change)="formChange(newCardForm)" class="form-control form-control-sm bold-input" required data-test="Payment-DialogZipCodeInput"/>
                                <mat-error *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="help-block">Zipcode Required
                                </mat-error>
                                <mat-error *ngIf="zip.hasError('pattern') || zip.hasError('required') && (zip.dirty || zip.touched)">
                                    Valid Zip Code Required
                                </mat-error>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 form-group">
                                <button class="btn btn-success btn-update-payment" [disabled]="newCardForm.invalid" (click)="Submit()" data-test="Payment-DialogUpdateButton">Update
                                    Payment
                                    Information</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>