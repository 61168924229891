import { Item } from './item';
import { OrderTypeEnum } from './ordertype';

class NumVal {
  Value: number;
  Text: string;
}

export class Tip extends NumVal {
  Percent: number;
  Flat: number;
  Type: TipType;
}

export enum TipType {
  Flat = 0,
  Percent = 1
}

export enum ScheduleType {
  ASAP = '0',
  SCHEDULED = '1'
}

export interface ISchedule {
  text: ScheduleType;
  value: number;
  wait: number;
  close: number;
  asap: boolean;
}

export class StoreCartModel {
  itemList: Item[] = [];
  subtotal: NumVal;
  tax: NumVal;
  total: NumVal;
  deliveryfee: NumVal;
  servicefee: NumVal;
  conveniencefee: NumVal;
  // conveniencefee: NumVal;
  tip: Tip;
  schedule: ISchedule;
  itemCount: number;
  deliverytype: OrderTypeEnum;
  deliverytypetext: string;
  deliveryInstructions: string;

  constructor() {
    const d = new Date();
    // var nd = d.getTime() + (20 * 60 * 1000);
    this.schedule = {
      text: ScheduleType.ASAP,
      value: 0,
      wait: 20,
      close: 0,
      asap: false,
    };
    this.itemList = [];
    this.subtotal = { Value: 0, Text: '0' };
    this.tax = { Value: 0, Text: '0' };
    this.total = { Value: 0, Text: '0' };
    this.deliveryfee = { Value: 0, Text: '0' };
    this.servicefee = { Value: 0, Text: '0' };
    this.tip = { Flat: 0, Percent: 0, Type: TipType.Percent, Value: 0, Text: '' };
    this.itemCount = 0;
    this.deliverytype = -1;
    this.deliverytypetext = '';
    this.deliveryInstructions = '';
  }
}
