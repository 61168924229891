import { Injectable } from '@angular/core';
import { Observable, from, Subject, throwError, Subscriber, BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  isOpen = false;

  public sidenavDelegate = new Subject<boolean>();
  public sidenavEvent = this.sidenavDelegate.asObservable();

  public stateSubject = new Subject<boolean>();
  public state = this.stateSubject.asObservable();
  public sidenavBehavior: BehaviorSubject<boolean> = new BehaviorSubject(this.isOpen);
  public sidenavObservable: Observable<boolean> = this.sidenavBehavior.asObservable();

  constructor() { }

/*   public setSideNav(Nav: MatSidenav){
    this.sideNav = Nav;
  } */

  public open(){
    this.isOpen = true;
    this.sidenavBehavior.next(this.isOpen);
    this.stateSubject.next(this.isOpen);
  }

  public toggle(){
    this.isOpen = !this.isOpen;
    this.sidenavBehavior.next(this.isOpen);
    this.stateSubject.next(this.isOpen);
  }

  public close(){
    this.isOpen = false;
    this.sidenavBehavior.next(this.isOpen);
    this.stateSubject.next(this.isOpen);
  }
}
