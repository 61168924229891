import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Info } from 'projects/common/src/lib/info';
import { Address } from 'projects/common/src/lib/address';

@Component({
  selector: 'app-address-selection',
  templateUrl: './address-selection.component.html',
  styleUrls: ['./address-selection.component.scss']
})
export class AddressSelectionComponent implements OnInit {
  public info: Info;
  public selectedAddress: Address;
  public selectedIndex: number;

  constructor(
    public dialogRef: MatDialogRef<AddressSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Info,
  ) { }

  ngOnInit() {
    this.info = this.data;
    this.clearSelectedAddress();
    // this.selectedAddress = this.info.addresses.find(a => { if (a.street1 === this.info.address.street1) { a.isSelected = true; return a; } });
    this.selectedIndex = this.info.addresses.findIndex(a => a.street1 === this.info.address.street1);
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.info.addresses.findIndex(a => a.isDefault);
    }
    this.info.addresses[this.selectedIndex].isSelected = true;
    this.selectedAddress = this.info.addresses[this.selectedIndex];
  }

  public selectAddress(i: number) {
    if (this.selectedIndex !== i) {
      this.clearSelectedAddress();

      this.info.addresses[i].isSelected = true;

      this.selectedAddress = this.info.addresses[i];
      this.selectedIndex = i;
    } else {
      this.info.addresses[i].isSelected = false;

      this.selectedAddress = new Address();
      this.selectedIndex = -1;
    }
  }

  public updateAddress() {
    this.dialogRef.close(this.selectedIndex);
  }

  public close() {
    this.clearSelectedAddress();
    this.dialogRef.close(-1);
  }

  public clearSelectedAddress() {
    this.info.addresses.find(a => {
      if (a.isSelected) {
        a.isSelected = false;
      }
    });
  }

}
