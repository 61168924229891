import { MerchantSettings } from './merchant-settings';
import { OrderType } from './ordertype';
import { CustomTheme } from './customtheme';
// import { Address } from './address';

export enum RmsType {
  ExaTouch = 0,
  Aloha = 1,
  Micros = 2,
  DeliverMe = 3
}

export class PrinterInfo {
    public name = "";
    public nickname = "";

    constructor() {
        this.name = "";
        this.nickname = "";
    }
}

export class ContactInfo {
  public name = '';
  public fname = '';
  public lname = '';
  public email = '';
  public phone = '';
  public contactMethod = 3;

    constructor() {
        this.name = '';
        this.fname = '';
        this.lname = '';
        this.email = '';
        this.phone = '';
        this.contactMethod = 0;
    }
}

export class Merchant {
    public _id = '';
    public isoID = '';
    public applicationKey = '';
    public companyID = 0;       // this will group multiple locations to the same company
    public fname = '';
    public lname = '';
    public businessName = '';
    public businessDesc = "";
    public baseRoute = '';
    public routeName = '';
    /* public title = ''; */
    public linkText = '';
    public email = '';
    public merchantNumber1 = '';
    public merchantNumber2 = '';
    public merchantNumber3 = '';
    public merchantNumber4 = '';
    public phone = '';
    public logo = '';
    public backgroundImage = '';
    public bannerOpen = '';
    public bannerClose = '';
    public address = '';
    public address2 = '';
    public city = '';
    public state = '';
    public zip = '';
    public country = '';
    public giftCardMerchantID = '';
    public giftCardLocationID = '';
    public giftCardTerminalID = '';
    public industryType = '';
    public paymentGatewayID: string = "4";
    public moto = '';
    public deviceID: '';
    public merchantid = 0;
    public profileid = 0;
    public apiUrl = '';
    public categoryEndpoint = '';
    public itemEndpoint = '';
    public orderEndpoint = '';
    public merchantSettingEndpoint = "";
    public latitude = 0.00;
    public longitude = 0.00;
    public theme: CustomTheme;
    public settings: MerchantSettings;
    public orderOptions: OrderType[];
    public contactInfo: ContactInfo;
    public orderType: number;
    public printers: PrinterInfo[] = [];
    public rmsType: number;
    public distance: number;
    public merchantAddress: string;
    public modifiedby = '';
    public modifieddate = new Date();
    public createdate = new Date();

    constructor() {
        this.country = 'USA';
        this.companyID = 0;
        this.contactInfo = new ContactInfo();
        this.settings = new MerchantSettings();
        this.orderOptions = [];
        this.distance = 0;
        this.createdate = new Date();
        this.paymentGatewayID = "4";
        this.printers = [];
    }
}
