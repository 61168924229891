import {Component, Pipe, PipeTransform} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'secureHtml' })
export class SecureHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value) {
        console.log(this.sanitizer.bypassSecurityTrustHtml(value))

    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
