import { Item } from './item';
import { Payment } from './payment';
import moment from 'moment';
import { Customer } from './customer';
import { OrderTypeEnum } from './ordertype';
import { EtcPaymentResponse } from 'projects/common/src/lib/etc-transaction-response';

/* XT Delivery Statuses */
export enum DeliveryStatus {
  New = 0,
  Ready = 1,
  Out = 2,
  Delivered = 3,
  Undeliverable = 4
}

export enum OrderStatus {
  None = -1,
  Open = 0,
  Completed = 1,
  JoinIn = 2,
  Deleted = 3,
  Refunded = 4,
  Voided = 5,
  Pending = 6,
  RefPending = 7,
  VoidPending = 8,
  RefProcessing = 9,
  OpenRefund = 10,
  Undeliverable = 11,
  Declined = 12,
  TicketPending = 13
}

export class DeviceDetails {
  isMobile: boolean;
  isTablet: boolean;
  isDeskTopService: boolean;
  deviceInfo: any;

  constructor() {
    this.isMobile = false;
    this.isTablet = false;
    this.isDeskTopService = false;
  }
}

export class Order {
  public _id: string;
  public applicationKey: string;
  public requestID: string;
  public orderno: number;
  public orderxref: string;
  public userid: string;
  public merchantNumber: string;
  public businessName: string;
  public storeLocation: string;
  public datetime: string;
  public ordertype: any | OrderTypeEnum | string;
  public orderstatus: OrderStatus;
  public deliveryStatus: DeliveryStatus;
  public receiptNumber: string;
  public subtotal: number;
  public taxrate: number;
  public tax: number;
  public serviceFee: number;
  public convenienceFee: number;
  public tip: number;
  public totalAmount: number;
  public deliveryFee: number;
  // public payment_type: string;
  public paymentType: string;
  public useWalletShippingAddress: boolean;
  //  public table_number: string;
  public tableNumber: string;
  public server: string;
  public customerInformation: Customer;
  public items: Item[] = [];
  public paymethods: Payment[] = [];
  // public pay_option: string;
  public payOption: string;
  public status = '';
  public InvoiceId: number;
  public InvoiceNum: string;
  public deliveryInstructions: string;
  public asap: boolean;
  public ScanToPay: boolean;
  public deviceInfo: DeviceDetails;
  public orderSource = "DeliverMe";
  public dateTimeZone = 'America/New_York';
  public createtime = new Date();
  public modifieddate = new Date();
  public modifiedby = '';

  constructor() {
    this._id = '';
    this.applicationKey = '';
    this.requestID = '';
    this.orderno = 0;
    this.orderxref = '';
    this.merchantNumber = '';
    this.userid = '';
    this.businessName = '';
    this.storeLocation = '';
    this.datetime = moment(new Date()).format('LLL');
    this.orderstatus = OrderStatus.Pending;
    this.ordertype = OrderTypeEnum.Delivery;
    this.receiptNumber = '';
    this.subtotal = 0.00;
    this.taxrate = 0.0000;
    this.tax = 0.0000;
    this.serviceFee = 0.00;
    this.tip = 0.00;
    this.totalAmount = 0.00;
    this.deliveryFee = 0.00;
    this.convenienceFee = 0.00;
    this.paymentType = '';
    this.useWalletShippingAddress = true;
    this.tableNumber = '';
    this.server = '';
    this.customerInformation = new Customer();
    this.items = [];
    this.paymethods = [];
    this.payOption = '1';
    this.status = '';
    this.InvoiceId = 0;
    this.InvoiceNum = '';
    this.deliveryInstructions = '';
    this.asap = false;
    this.orderSource = "DeliverMe";
    this.deviceInfo = new DeviceDetails();
    this.createtime = new Date();
    this.modifieddate = new Date();
    this.modifiedby = '';
  }
}

export class OrderConfirmation {
  orderno: string;
  confirmation: number;
  status: boolean;
  paymentResponse: EtcPaymentResponse;
  message: string;
  order: Order;
}
