import { Injectable } from '@angular/core';
import { Rating } from 'projects/common/src/lib/rating';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, from, Subject, throwError, Subscriber, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RatingsService {
  public rating: Rating;
  public ratings: Rating[] = [];
  public ratingObservable: BehaviorSubject<Rating[]> = new BehaviorSubject(this.ratings);
  public merchantsObservable: Observable<Rating[]> = this.ratingObservable.asObservable();

  constructor(public http: HttpClient, public localStorage: StorageMap) {
    this.merchantsObservable = new BehaviorSubject(this.ratings);
  }

  public addRating(rating: Rating): Observable<Rating> {
    return this.http.post<Rating>('/api/ratings', rating)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getAverageRating(merchantid: string): Observable<any> {
    return this.http.get<any>('/api/ratings/average/' + merchantid)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getRatings(merchantid: string, lastID: string): Observable<Rating[]> {
    return this.http.get<Rating[]>('/api/ratings/' + merchantid + '/' + lastID)
      .pipe(
        catchError(this.handleError)
      );
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

}
