import { Address } from './address';

export class Info {
    name: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    addressF: string;
    address: Address;
    addresses: Address[];
    orderType: number;

    constructor() {
        this.name = '';
        this.address = new Address();
        this.addresses = [];
        this.firstname = '';
        this.lastname = '';
        this.email = '';
        this.addressF = '';
        this.orderType = 3;
    }
}