export enum OrderTypeEnum {
    Unknown = -1,
    DineIn = 0,
    ToGo = 1,
    PickUp = 2,
    Delivery = 3
}

export class OrderType {
    public name = '';
    public desc = '';
    public value: OrderTypeEnum;
    public icon = '';
    public enabled = false;
    public suspended = false;
    public selected = false;
    public waitTimes: number;
    public operationHours: any[] = [];

    constructor() {
        this.name = '';
        this.desc = '';
        this.value = OrderTypeEnum.PickUp;
        this.icon = 'local_mall';
        this.enabled = false;
        this.suspended = false;
        this.selected = false;
        this.waitTimes = 0;
        this.operationHours = [];
    }
}
