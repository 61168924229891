import { Component, OnInit, Input } from '@angular/core';
import { AgmMarker } from 'projects/common/src/lib/marker';

@Component({
  selector: 'google-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @Input() lat = 51.678418;
  @Input() lng = 7.809007;

  public latitude = 51.678418;
  public longitude = 7.809007;
  public zoom = 15;
  public markers: AgmMarker[] = [];
  public selectedMarker: AgmMarker;

  constructor() { }

  ngOnInit() {
    this.setCurrentPosition();
  }

  public setCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  public addLocationMarker(lat: number, long: number): void {
    const marker = new AgmMarker(lat, long, 0.4 );
    this.markers.push(marker);
  }

  selectMarker(event: any) {
    this.selectedMarker = {
      latitude: event.latitude,
      longitude: event.longitude,
      alpha: 0.4
    };
  }

}
