import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient, HttpEvent, HttpEventType, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {

  public google_api_key = 'AIzaSyALVbipewPTNQW3xExNdYV7nXAlOsxuJfE';

  constructor(public http: HttpClient, public globalservice: GlobalService) { }

  public search(place: string): Observable<any[]> {
    try {

      httpOptions.headers.append('token', this.globalservice.Token);

      // console.log('place = ' + place);

      return this.http.get<any>('/api/google/search/' + place, httpOptions)
        .pipe(
          catchError(this.handleError)
        );

    } catch (error) {
      console.log('error:' + error.message);
    }
  }

  public handleError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${err.status}, ` +
        `body was: ${err.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(err);
  }
}
