import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpGlobalInterceptor } from 'projects/common/src/lib/http-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogIn } from 'projects/common/src/lib/login';
import { User } from 'projects/common/src/lib/user';
import { MaterialDesignModule } from 'projects/common/src/lib/material-design/material-design.module';
/* import { DeviceDetectorModule } from 'ngx-device-detector'; */
import { AgmCoreModule } from '@agm/core';
import { AuthIntercept } from 'projects/common/src/lib/auth-intercept';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgRatingBarModule } from 'ng-rating-bar';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { Receipt2Component } from './components/receipt2/receipt2.component';
import { SmsReceiptComponent } from './components/sms-receipt/sms-receipt.component';
import { MatInputModule } from '@angular/material/input';

export function getBaseHref() {
  return document.getElementsByTagName('base')[0].href;
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        FlexLayoutModule,
        MaterialDesignModule,
        MatInputModule,
        NgRatingBarModule,
        BrowserAnimationsModule,
        AgmCoreModule.forRoot({ apiKey: 'AIzaSyBqTbkSC9_LkMyM_JrDkPYp9AX-ejoAWmo', libraries: ['places'] })
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialDesignModule,
        MatInputModule,
        NgRatingBarModule,
        AppRoutingModule
    ],
    providers: [
        AuthIntercept,
        LogIn,
        User,
        MatIconRegistry,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpGlobalInterceptor,
            multi: true
        },
    ],
    bootstrap: [SmsReceiptComponent]
})
export class AppModule { }


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialDesignModule,
    MatInputModule,
    AppRoutingModule,
    MaterialDesignModule,
    FlexLayoutModule,
    NgRatingBarModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBqTbkSC9_LkMyM_JrDkPYp9AX-ejoAWmo', libraries: ['places'] })
  ],
  declarations: [ReceiptComponent, Receipt2Component, SmsReceiptComponent],
  exports: [
    MaterialDesignModule,
    FlexLayoutModule,
    ReceiptComponent,
    Receipt2Component,
    SmsReceiptComponent,
    CommonModule,
    FormsModule,
    NgRatingBarModule,
    AppRoutingModule
  ],
  providers: [
    AuthIntercept,
    LogIn,
    User,
    MaterialDesignModule,
    MatIconRegistry,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpGlobalInterceptor,
      multi: true
    },
  ]
})
export class ReceiptsModule {

}
