import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit, AfterViewInit {
  panelOpenState = false;
  okonly = false;
  yesno = false;
  validate = false;
  validateData = '';

  validationForm: UntypedFormGroup;

  get validation() {return this.validationForm.get('validation')}

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    // this.okonly = (this.data.okonly && this.data.okonly === true) ? true : false;
    // this.yesno = (this.data.yesno && this.data.yesno === true) ? true : false;
    // this.validate = (this.data.validateData && this.data.validateData.trim() !== '') ? true : false;
    // this.validateData = (this.data.validateData && this.data.validateData.trim() !== '') ? this.data.validateData.trim() : '';

    // this.validationForm = new FormGroup({
    //   validation: new FormControl({ value: '', disabled: !this.validate }, [Validators.pattern(this.validateData)])
    //   // validation: new FormControl({ value: '', disabled: !this.validate }, [this.invalidConfirmation()])
    // });
  }

  ngAfterViewInit() {
    this.okonly = (this.data.okonly && this.data.okonly === true) ? true : false;
    this.yesno = (this.data.yesno && this.data.yesno === true) ? true : false;
    this.validate = (this.data.validateData && this.data.validateData.trim() !== '') ? true : false;
    this.validateData = (this.data.validateData && this.data.validateData.trim() !== '') ? this.data.validateData.trim() : '';

    this.validationForm = new UntypedFormGroup({
      validation: new UntypedFormControl({ value: '', disabled: !this.validate }, [Validators.pattern(this.validateData)])
      // validation: new FormControl({ value: '', disabled: !this.validate }, [this.invalidConfirmation()])
    });

    if (this.validate) {
      this.validation.markAsTouched();
    }

    document.getElementById('divText').innerHTML = this.data.message;
    if (this.data.text) {
      document.getElementById('divDetails2').innerHTML = '<p>' + this.data.text + '</p>';
    }
  }

  private confirmationObserve() {
    this.validation.valueChanges.subscribe()
  }

  private invalidConfirmation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        // console.log(idx);
        if (!this.validationForm) { return null; }
        if (this.validationForm.pristine && !this.validationForm.dirty && !this.validationForm.touched) { return null; }

        if (control.value.toString().trim() === '') {
          return { invalidConfirmation: { value: true } };
        } else {
          if (this.validateData.trim() !== control.value.trim()) {
            return { invalidPostalCode: { value: true } };
          }

          return null;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  public Confirm() {
    this.dialogRef.close(true);
  }

  public Cancel() {
    this.dialogRef.close(false);
  }
}
