export class OperationHours {
    public openHour: string;
    public closeHour: string;
    public available: boolean;

    constructor() {
        this.openHour = '';
        this.closeHour = '';
        this.available = true;
    }
}
