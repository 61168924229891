import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Order } from 'projects/common/src/lib/order';
import { Item } from 'projects/common/src/lib/item';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, from, Subject, throwError, Subscriber, BehaviorSubject, Subscription } from 'rxjs';
import { GlobalService } from './global.service';
import { Option } from 'projects/common/src/lib/option';
import { Modifier } from 'projects/common/src/lib/modifier';
import { SideItem } from 'projects/common/src/lib/side-item';
import { OptionalItem } from 'projects/common/src/lib/optional-item';
import { async } from '@angular/core/testing';

const API_STORAGE_KEY = 'cart';
const API_URL = 'https://localhost/api';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  public cart: Order = new Order();
  public orderDelegate = new Subject<Order>();
  public orderEvent$ = this.orderDelegate.asObservable();
  public orderSummaryDelegate = new Subject<Order>();
  public orderSummaryEvent$ = this.orderSummaryDelegate.asObservable();
  public cart_cross_sell: number;
  public add_cross_sell = false;
  public _add_cart_cross_sell: BehaviorSubject<boolean> = new BehaviorSubject(this.add_cross_sell);
  public add_cart_cross_sell: Observable<boolean> = this._add_cart_cross_sell.asObservable();

  public min_amount: number;
  public min_amount_reached = false;
  public _minimum_amount_reached: BehaviorSubject<boolean> = new BehaviorSubject(this.min_amount_reached);
  public minimum_amount_reached: Observable<boolean> = this._minimum_amount_reached.asObservable();

  public itemsDelegate = new Subject<Order>();
  public itemsEvent$ = this.itemsDelegate.asObservable();
  public _itemsObservable: BehaviorSubject<Order> = new BehaviorSubject(this.cart);
  public itemsObservable: Observable<Order> = this._itemsObservable.asObservable();

  public _orderSummary: BehaviorSubject<Order> = new BehaviorSubject(this.cart);
  public orderSummary: Observable<Order> = this._orderSummary.asObservable();
  public taxrate = 0.00;
  public servicefeerate = 0.00;
  public taxvalue = '0.00';
  public tippercentage = 0.00;
  public tipmanualstr = '0.00';

  constructor(public http: HttpClient,
    public storage: StorageMap,
    public globalservice: GlobalService) {

    this.storage.get('cart').subscribe(async (order: Order) => {
      if (order) {
        this.cart = order;
      } else {
        this.cart = new Order();
      }

      this.orderSummary = new BehaviorSubject(this.cart);
      this.itemsObservable = new BehaviorSubject(this.cart);

      await this.calculateOrderSummary();
    });

    this.storage.get('cart_cross_sell').subscribe(async (id: number) => {
      if (id) {
        this.cart_cross_sell = id;
      }

      this.storage.get('add_cart_cross_sell').subscribe(async (data: boolean) => {
        if (data) {
          this.add_cross_sell = data;
        } else {
          this.add_cross_sell = false;
        }

        this._add_cart_cross_sell.next(this.add_cross_sell);
      });

      this.checkMinOrderReached();
    });
  }

  public checkMinOrderReached() {
    this.storage.get('minimumOrder').subscribe(async (minimumOrder: number) => {
      if (minimumOrder !== null && minimumOrder !== undefined) {
        this.min_amount = minimumOrder;
      } else {
        this.min_amount = 0;
      }

      if (this.cart.totalAmount >= this.min_amount) {
        this.min_amount_reached = true;
      } else {
        this.min_amount_reached = false;
      }

      this._minimum_amount_reached.next(this.min_amount_reached);
    });
  }

  public getShoppingCart(refresh: boolean = false): Observable<Order> {
    try {
      const cartObservable = new Observable<Order>(o => {
        this.storage.get('cart').subscribe((value: Order) => {
          if (value) {
            this.cart = value;
            this.calculateOrderSummary();
          } else {
            this.cart = new Order();
          }
          o.next(this.cart);
          this._itemsObservable.next(this.cart);
        });
      });

      return cartObservable;
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  public getOrderItems(): Order {
    return this._itemsObservable.getValue();
  }

  public addItem(item: Item): boolean {
    try {
      if (this.add_cross_sell && item.categoryid === this.cart_cross_sell) {
        this.add_cross_sell = false;

        this.storage.set('add_cart_cross_sell', this.add_cross_sell).subscribe(() => {
          this._add_cart_cross_sell.next(this.add_cross_sell);
        });
      }

      this.storage.get('cart').subscribe((data: any) => {
        if (data) {
          item.show_details = false;
          this.cart = data;

          let modify_index: number;
          this.storage.get('modify_index').subscribe((result: any) => {
            this.storage.delete('modify_index').subscribe();
            console.log(result);

            modify_index = result;
          });

          const possible_matches = this.cart.items.filter(o => {
            if (item.itemid === o.itemid) {
              return o;
            }
          });
          let matched = false;

          for (const i of possible_matches) {
            if (item.beverage && i.beverage && item.size === i.size) {
              matched = true;
            } else if (!item.beverage && item.options && item.options.length === i.options.length && i.options.length === 0 &&
              item.modifiers.length === i.modifiers.length && i.modifiers.length === 0 &&
              item.sides.length === i.sides.length && i.sides.length === 0) {
              matched = true;
            } else if (!item.beverage && item.options.length === i.options.length && item.modifiers.length === i.modifiers.length && item.sides.length === i.sides.length) {
              if (item.options.length === 0) {
                matched = true;
              } else {
                for (const option of item.options) {
                  for (let index = 0; index < i.options.length; index++) {
                    if (option.title === i.options[index].title && option.selected === i.options[index].selected) {
                      matched = true;
                      break;
                    } else {
                      matched = false;
                    }
                  }
                }
              }

              if (!matched) {
                continue;
              } if (item.modifiers.length === 0) {
                matched = true;
              } else {
                for (const modifier of item.modifiers) {
                  for (let index = 0; index < i.modifiers.length; index++) {
                    if (modifier.title === i.modifiers[index].title && modifier.selected === i.modifiers[index].selected) {
                      matched = true;
                      break;
                    } else {
                      matched = false;
                    }
                  }
                }
              }

              if (!matched) {
                continue;
              } if (item.sides.length === 0) {
                matched = true;
              } else {
                for (const side of item.sides) {
                  for (let index = 0; index < i.sides.length; index++) {
                    if (side.title === i.sides[index].title && side.selected === i.sides[index].selected) {
                      matched = true;
                      break;
                    } else {
                      matched = false;
                    }
                  }
                }
              }
            }

            // if (item.beverage && i.beverage && item.size === i.size) {
            //   matched = true;
            // } else {
            //   matched = false;
            // }

            if (matched) {
              i.qty += item.qty;
              if (modify_index !== null && modify_index >= 0) {
                this.cart.items.splice(modify_index, 1);
              }
              break;
            }
          }
          if (!matched) {
            if (modify_index !== null && modify_index >= 0) {
              this.cart.items[modify_index] = item;
            } else {
              this.cart.items.push(item);
            }
          }

          this.storage.set('cart', this.cart).subscribe(async (value) => {
            if (item.beverage) {
              this.globalservice.add_beverage = false;
            }

            await this.calculateOrderSummary();
            this.orderDelegate.next(this.cart);
            this._itemsObservable.next(this.cart);
            return true;
          });
        } else {
          console.log('add item error');
        }
      });
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public updateItem(item: Item, index: number): boolean {
    try {
      if (index < this.cart.items.length) {
        this.cart.items[index] = item;
        this.itemsDelegate.next(this.cart);
        this.saveShoppingCart(this.cart);
      }
    } catch (error) {
      return false;
    }
  }

  public async removeItem(item: Item, index: number): Promise<Order> {
    return new Promise((resolve, reject) => {
      try {
        // const index = this.cart.items.findIndex(o => o.itemid === item.itemid);
        if (!this.add_cross_sell && item.categoryid === this.cart_cross_sell) {
          this.add_cross_sell = true;

          this.storage.set('add_cart_cross_sell', this.add_cross_sell).subscribe(() => {
            this._add_cart_cross_sell.next(this.add_cross_sell);
          });
        }

        if (index >= 0) {
          this.cart.items.splice(index, 1);
        }

        this.storage.set('cart', this.cart).subscribe(async (value) => {
          await this.calculateOrderSummary();
          this._itemsObservable.next(this.cart);
          resolve(this.cart);
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public async clearItems(): Promise<Order> {
    return new Promise((resolve, reject) => {
      try {
        if (this.cart_cross_sell && this.cart_cross_sell > 0) {
          this.add_cross_sell = true;
        } else {
          this.add_cross_sell = false;
        }

        this.storage.set('add_cart_cross_sell', this.add_cross_sell).subscribe(() => {
          this._add_cart_cross_sell.next(this.add_cross_sell);
        });

        this.storage.get('cart').subscribe(async (value) => {
          if (this.cart.items) {
            this.cart.items = [];

            this.storage.set('cart', this.cart).subscribe(async (order) => {
              await this.calculateOrderSummary();
              this.orderDelegate.next(this.cart);
              resolve(this.cart);
            });
          } else {
            await this.calculateOrderSummary();
            this.orderDelegate.next(this.cart);
            resolve(this.cart);
          }
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public async removeMod(item_index: number, mod_index: number, target: string): Promise<Order> {
    return new Promise((resolve, reject) => {
      try {
        switch (target) {
          case 'O':
            if (this.cart.items[item_index].options.length > mod_index) {
              this.cart.items[item_index].options.splice(mod_index, 1);
            }
            break;
          case 'M':
            if (this.cart.items[item_index].modifiers.length > mod_index) {
              this.cart[item_index].modifiers.splice(mod_index, 1);
            }
            break;
          case 'S':
            if (this.cart.items[item_index].sides.length > mod_index) {
              this.cart.items[item_index].sides.splice(mod_index, 1);
            }
            break;
          default:
            break;
        }

        this.storage.set('cart', this.cart).subscribe(async (value) => {
          await this.calculateOrderSummary();
          this._itemsObservable.next(this.cart);
          resolve(this.cart);
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public async qtyChanged(order: Order): Promise<Order> {
    return new Promise((resolve, reject) => {
      try {
        this.cart.items = order.items;

        this.storage.set('cart', this.cart).subscribe(async (value) => {
          await this.calculateOrderSummary();
          this._itemsObservable.next(this.cart);
          resolve(this.cart);
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public async add(item: Item, index: number): Promise<Item> {
    return new Promise((resolve, reject) => {
      try {
        // const selectedItem = this.cart.items.find(o => o.itemid === item.itemid);
        const selectedItem = this.cart.items[index];

        if (selectedItem) {
          selectedItem.qty++;
          this.storage.set('cart', this.cart).subscribe(async (value) => {
            await this.calculateOrderSummary();
            // this.orderDelegate.next(this.cart);
            resolve(selectedItem);
          });
        } else {
          item.qty++;
          resolve(item);
        }
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public async subtract(item: Item, index: number): Promise<Item> {
    return new Promise((resolve, reject) => {
      try {
        // const selectedItem = this.cart.items.find(o => o.itemid === item.itemid);
        const selectedItem = this.cart.items[index];

        if (selectedItem) {
          if (selectedItem.qty > 1) { selectedItem.qty--; }
          this.storage.set('cart', this.cart).subscribe(async (value) => {
            await this.calculateOrderSummary();
            // this.orderDelegate.next(this.cart);
            resolve(selectedItem);
          });
        } else {
          if (item.qty > 0) { item.qty--; }
          resolve(item);
        }
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  public createOrder(order: Order): boolean {
    try {
      this.cart = order;
      this.setLocalStorage('cart', order);
      return true;
    } catch (error) {
      console.log('createOrder', error);
      return false;
    }
  }

  public setLocalStorage(key, data) {
    this.storage.set(key, data).subscribe(() => { });
  }

  // Get cached API result
  public getLocalStorage(key) {
    return this.storage.get(key).subscribe(() => { });
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  public async calculateOrderSummary(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.cart.subtotal = 0;

        // for (let i = 0; i < this.cart.items.length; i++) {
        //   this.cart.subtotal += (this.cart.items[i].price * this.cart.items[i].qty);
        // }
        if (this.cart.items !== null && this.cart.items !== undefined) {
          this.cart.items.forEach((item: Item) => {
            this.cart.subtotal += (item.price * item.qty);

            // item.options.forEach((option: Option) => {
            //   if (option.price) {
            //     this.cart.subtotal += option.price;
            //   }
            // });

            // item.modifiers.forEach((modifier: Modifier) => {
            //   if (modifier.price) {
            //     this.cart.subtotal += modifier.price;
            //   }
            // });

            item.sides.forEach((side: OptionalItem) => {
              if (side.price) {
                this.cart.subtotal += side.price;
              }
            });
          });

          this.cart.subtotal = parseFloat(this.cart.subtotal.toFixed(2));

          this.cart.tax = (this.cart.subtotal > 0) ? parseFloat((this.cart.subtotal * this.taxrate).toFixed(2)) : 0;
          this.cart.serviceFee = (this.cart.subtotal > 0) ? parseFloat((this.cart.subtotal * this.servicefeerate).toFixed(2)) : 0;

          this.cart.totalAmount = parseFloat((this.cart.subtotal + this.cart.tax + this.cart.serviceFee).toFixed(2));
          this.storage.set('cart', this.cart).subscribe((value) => { });

          this._orderSummary.next(this.cart);

          this.checkMinOrderReached();
          resolve();
        }
      } catch (error) {
        // this.errorAlert(error.message);
        console.log(error);
        reject(error);
        // o.error(error);
      }
    });
  }

  public async setDeliveryFee(fee: any) {
    if (fee) {
      if (fee.percentOn) {
        this.cart.deliveryFee = fee.deliveryFee * this.cart.totalAmount;
      } else {
        this.cart.deliveryFee = fee.deliveryFee;
      }
      this.cart.totalAmount += this.cart.deliveryFee;
    }
  }

  public async saveShoppingCart(cart: Order): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.cart = cart;
        this.storage.set('cart', cart).subscribe(async (value) => {
          // this.orderDelegate.next(this.cart);
          this._itemsObservable.next(this.cart);

          this.storage.get('cart_cross_sell').subscribe(async (id: number) => {
            this.cart_cross_sell = id;

            this.storage.get('add_cart_cross_sell').subscribe(async (data: boolean) => {
              this.add_cross_sell = data;
              this._add_cart_cross_sell.next(data);
              // this.add_cart_cross_sell = new BehaviorSubject(this.add_cross_sell);

              resolve();
            });
          });
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
