import { Item } from 'projects/common/src/lib/item';

export class EmailOrderTemplate {
    viewonline: string;
    merchantlogo: string;
    businessname: string;
    merchantstreet: string;
    merchantcity: string;
    merchantstate: string;
    merchantzipcode: string;
    merchantphone: string;
    orderno: string;
    items: Item[];
    subtotal: string;
    servicefee: string;
    conveniencefee: string;
    tax: string;
    gratuity: string;
    deliveryfee: string;
    totalamount: string;
    ordertype: string;
    customername: string;
    orderdatetime: string;
    locationstreet: string;
    locationcity: string;
    locationstate: string;
    locationzip: string;
    locationphone: string;
    locationemail: string;
    cardbrandlogo: string;
    cclastfour: string;
    cardholder: string;
    paymentdatetime: string;
    paymentid: string;
    approvalcode: string;
    transactionidentifer: string;
    invoicenum: string;
    productlogo: string;
    copyrightyear: string;

    constructor() {
        this.viewonline = '';
        this.merchantlogo = '';
        this.businessname = '';
        this.merchantstreet = '';
        this.merchantcity = '';
        this.merchantstate = '';
        this.merchantzipcode = '';
        this.merchantphone = '';
        this.orderno = '';
        this.items = [];
        this.subtotal = '';
        this.servicefee = '';
        this.tax = '';
        this.gratuity = '';
        this.deliveryfee = '';
        this.totalamount = '';
        this.ordertype = '';
        this.customername = '';
        this.orderdatetime = '';
        this.locationstreet = '';
        this.locationcity = '';
        this.locationstate = '';
        this.locationzip = '';
        this.locationphone = '';
        this.locationemail = '';
        this.cardbrandlogo = '';
        this.cclastfour = '';
        this.cardholder = '';
        this.paymentdatetime = '';
        this.paymentid = '';
        this.approvalcode = '';
        this.transactionidentifer = '';
        this.invoicenum = '';
        this.productlogo = '';
        this.copyrightyear = new Date().getFullYear().toString();
    }
}
