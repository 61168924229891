import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  forbiddenEmails: any;
  errorMessage: string;
  successMessage: string;
  IsvalidForm = true;

  passwordResetForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
  });

  constructor(
    private dialogRef: MatDialogRef<PasswordResetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private router: Router,
  ) { }

  get email(): any { return this.passwordResetForm.get('email'); }

  ngOnInit() {
    try {
      this.passwordResetForm = new UntypedFormGroup({
        email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      });
    } catch (error) {

    }
  }

  public Cancel(): void {
    this.dialogRef.close();
  }

  public formChange(form: any): void {
    try {
      if (form.valid) {
        // this.disableSubmit = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public resetPassword(form: any): void {
    if (form.valid) {
      this.IsvalidForm = true;
      this.authService.passwordReset({merchant: this.data, destination: this.passwordResetForm.value}).subscribe(
        data => {
          this.errorMessage = null;
          this.successMessage = data.message;
          setTimeout(() => {
            this.passwordResetForm.reset();
            this.successMessage = null;
            this.dialogRef.close({token: data.token});
          }, 1000);
        },
        err => {

          if (err.error.message) {
            this.errorMessage = err.error.message;
          }
        }
      );
    } else {
      this.IsvalidForm = false;
    }
  }

}
