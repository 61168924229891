<button type="button" id="btnCheckoutCart" class="btn btn-primary d-md-none cart checkoutcart sidebarCollapse2" data-test="Store-CartCheckoutCartButton">
  <i class="fas fa-shopping-cart"></i>
  <span *ngIf="cartModel && cartModel.itemList" class="cart-basket d-flex align-items-center justify-content-center">
    {{cartModel.itemList.length}}
  </span>
</button>
<div class="wrapper2 noprint">
    <!-- Sidebar -->
    <nav id="sidebar2" class="sidebar2">
        <app-store-cart style="z-index: 2000"></app-store-cart>
    </nav>


    <div id="content" *ngIf="merchantInfo">

        <div id="divBanner" class="sticky-top navbar navbar-light bg-light justify-content-between" style="display:block !important;">
            <a class="merchant-brand navbar-brand" href="{{merchantInfo.routeName}}" data-test="Store-CheckoutMerchantInfoA">
                <img [src]="merchantInfo.logo" width="50" height="50" alt=""> {{merchantInfo.businessName}}
            </a>
        </div>

        <mat-vertical-stepper id="matStepper" [linear]="false" (selectionChange)="onStepChange($event)" #stepper>
            <mat-step>

                <div id="divStoreCheckout" class="container" *ngIf="!processing.done">

                    <ng-container *ngIf="cartModel && cartModel.itemList.length > 0; else CartEmpty">
                        <div class="row">
                            <div class="col">
                                <h2>Review Order and Information</h2>
                                <p><b>Please review address, payment method, and tip to complete your order</b></p>
                            </div>
                        </div>

                        <div class="row" *ngIf="merchantInfo">
                            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                <!--                         <h5>Your Order From {{merchantInfo.businessName}} For<a *ngIf="orderType"> {{orderType}}</a>:
                            </h5> -->

                                <h5>Your Order From {{merchantInfo.businessName}}:
                                </h5>

                                <div class="card">
                                    <div class="card-body" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="merchantInfo && merchantInfo.settings && merchantInfo.settings.Active">
                                        <div>
                                            <button class="btn btn-link" style="font-weight: bold;" (click)="ScheduleDialog()" [innerHTML]="scheduledReadyTime" data-test="Store-CheckoutScheduleDialogButton"></button>
                                        </div>
                                        <button class="btn btn-link" (click)="ScheduleDialog()" data-test="Store-CheckoutChangeButton1">Change</button>
                                    </div>

                                    <div class="card-body" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="merchantInfo && merchantInfo.settings && !merchantInfo.settings.Active">
                                        <div>
                                            Online Ordering is currently not available.
                                        </div>
                                    </div>
                                </div>

                                <h5>{{addressTitle}}</h5>

                                <div class="card">
                                    <div class="card-body">
                                        <div fxLayout="row" fxLayoutAlign="space-between baseline" *ngIf="order.ordertype !== 3">
                                            <!-- <div fxLayout="row" fxLayoutAlign="space-between baseline" *ngIf="order.ordertype <= 3"> -->
                                            <div>
                                                <a style="font-weight: bold; color: #6d7fcc;" data-test="Store-CheckoutMerchantInfo1">{{merchantInfo.businessName}}</a>
                                                <br />
                                                <a style="font-weight: bold; color: #6d7fcc;" data-test="Store-CheckoutMerchantInfo2">{{merchantInfo.address}},
                          {{merchantInfo.city}} {{merchantInfo.state}} - {{merchantInfo.zip}}</a>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between baseline" *ngIf="order.ordertype !== 3">
                                            <div>
                                                <label style="font-weight: bold; margin-top: 5px;">{{orderType}} Name:</label>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between baseline" *ngIf="order.ordertype !== 3">
                                            <div fxLayout="column" *ngIf="deliveryInfo && deliveryInfo.name; else NoPickUpInfo">
                                                <button class="btn btn-link" style="font-weight: bold;text-align: left;" (click)="AddressDialog()">
                          {{deliveryInfo.name}}
                        </button>
                                            </div>
                                            <div fxLayout="column">
                                                <button class="btn btn-link" (click)="AddressDialog()" style="text-align: left;" data-test="Store-CheckoutChangeButton2">
                          Change
                        </button>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between baseline" *ngIf="order.ordertype !== 3">
                                            <button class="btn btn-link" style="font-weight: bold;text-align: left;" (click)="AddressDialog()" *ngIf="order.ordertype === 2 && deliveryInfo && deliveryInfo.phone" data-test="Store-CheckoutPhoneandEmailButton">{{deliveryInfo.phone}},
                        {{deliveryInfo.email}}
                      </button>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="space-between baseline" *ngIf="order.ordertype === 3">
                                            <div *ngIf="deliveryInfo && deliveryInfo.address && deliveryInfo.address.street1; else NoDeliveryInfo">
                                                <button class="btn btn-link" style="font-weight: bold;text-align: left;" (click)="AddressDialog()" data-test="Store-CheckoutNameButton">{{deliveryInfo.name}}</button>
                                                <br />
                                                <button *ngIf="cartModel.deliverytype === 3" class="btn btn-link" style="font-weight: bold;text-align: left;" (click)="AddressDialog()" data-test="Store-CheckoutAddressButton">{{deliveryInfo.addressF}}</button>
                                            </div>
                                            <button class="btn btn-link" (click)="AddressDialog()" data-test="Store-CheckoutChangeButton3">Change</button>
                                        </div>
                                        <ng-template #NoPickUpInfo>
                                            <div fxLayout="row" fxLayoutAlign="space-between baseline">
                                                <div>
                                                    <button class="btn btn-link" style="text-align: start;" (click)="AddressDialog()" data-test="Store-CheckoutPleasesetButton1">Please set
                            name,
                            phone and email</button>
                                                </div>
                                                <!-- <button class="btn btn-link" (click)="AddressDialog()">Change</button> -->
                                            </div>
                                        </ng-template>
                                        <ng-template #NoDeliveryInfo>
                                            <div fxLayout="row" fxLayoutAlign="space-between baseline">
                                                <div>
                                                    <button class="btn btn-link" style="text-align: start;" (click)="AddressDialog()" data-test="Store-CheckoutPleasesetButton2">Please set
                            name
                            and
                            address</button>
                                                </div>
                                                <!-- <button class="btn btn-link" (click)="AddressDialog()">Change</button> -->
                                            </div>
                                        </ng-template>
                                        <div *ngIf="!deliveryInfo || !deliveryInfo.name && !deliveryInfo.email" class="alert alert-danger" (click)="AddressDialog()" style="cursor: pointer;">
                                            Customer Information Required
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div *ngIf="!showDI"><button class="btn btn-link" (click)="showDI = !showDI" data-test="Store-CheckoutIncludeInstructionsButton">+ Include<a
                          *ngIf="orderType" data-test="Store-CheckoutIncludeInstructionsA"> {{orderType}}</a>
                        Instructions</button></div>
                                        <div *ngIf="showDI"><button class="btn btn-link" (click)="showDI = !showDI" data-test="Store-CheckoutRemoveInstructionsButton">- Remove<a
                          *ngIf="orderType" data-test="Store-CheckoutRemoveInstructionsA"> {{orderType}}</a>
                        Instructions</button></div>
                                        <div *ngIf="showDI">
                                            <textarea rows="3" class="form-control" name="textDI" [(ngModel)]="cartModel.deliveryInstructions" maxlength="150" (ngModelChange)="deliverInstructionsChanged()" data-test="Store-CheckoutDeliverInstructionsTextArea">{{cartModel.deliveryInstructions}}</textarea>
                                            <p class="reminderText">{{(cartModel.deliveryInstructions || '').length}} out of 150
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <h5 *ngIf="order.ordertype >= 2">Payment Method:</h5>
                                <div *ngIf="order.ordertype >= 2" class="card">
                                    <div class="card-body">

                                        <div (click)="PaymentDialog()" fxLayout="row" fxLayoutAlign="space-between baseline">
                                            <div>
                                                <ng-container *ngIf="paymentMethod; else NoPaymentMethod">
                                                    <!--  <span class="pw pw-{{paymentMethod.paymethod.type}}"></span> -->
                                                    <span>
                                                        <img *ngIf="paymentMethod && paymentMethod.cardbrandlogo"
                                                        height="32px"
                                                        src="../../../assets/cc_logos/{{paymentMethod.cardbrandlogo}}.png"
                                                        alt=""
                                                        style="position:relative;right:5px;top:50%;font-size:2em !important;" />
                                                    </span>
                                                    <label style="font-weight: bold;">{{paymentMethod.paymethod.text}}</label>
                                                </ng-container>
                                                <ng-template #NoPaymentMethod>
                                                    <button class="btn btn-link" (click)="PaymentDialog()" data-test="Store-CheckoutEnterPaymentDetailsButton">Enter
                            Payment
                            Details</button>
                                                </ng-template>
                                            </div>
                                            <button class="btn btn-link" (click)="PaymentDialog()" data-test="Store-CheckoutChangeButton4">Change</button>
                                        </div>

                                        <div fxLayout="row" fxLayoutAlign="space-between baseline">
                                            <div *ngIf="!paymentMethod || !paymentMethod.name || !paymentMethod.cardnumber" class="alert alert-danger" (click)="PaymentDialog()" style="cursor: pointer;">
                                                Payment Information Required
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="merchantInfo.settings.tipSettings.enable">
                            <div class="col-lg-12">
                                <h5>Add a Tip?</h5>
                            </div>

                            <div class="col-lg-6">
                                <div fxLayout="row">
                                    <div fxFlex="25%" class="presettips" *ngFor="let t of tip.tipAmounts; let i = index">
                                        <input type="radio" id="tip_0" name="tipselect" [checked]="i === selectedTip ? 'checked' : ''" data-test="Store-CheckoutTipSelectInput1" />
                                        <label class="btn btn-outline-primary" [ngClass]="{'left-radius': i === 0, 'right-radius': i === tip.tipAmounts.length - 1}" (click)="SetTip(i, false)">
                      {{!tip.isPercentage ? '$' : ''}}{{t.amount}}{{tip.isPercentage ? '%' : ''}}
                    </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 presettips" *ngIf="merchantInfo.settings.tipSettings.tipAmounts[5].enable">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <input type="radio" id="tip_c" name="tipselect" [checked]="5 === selectedTip ? 'checked' : ''" #tip_c data-test="Store-CheckoutTipSelectInput2" />
                                    <label for="tip_c" class="btn btn-outline-primary left-radius" (click)="SetTip(5, true);customValue.focus()">Custom $:</label>
                                    <input class="form-control right-radius" type="text" (focusout)="tipFocusOut()" [textMask]="{mask: regexList.currency_mask}" placeholder="Amount" [(ngModel)]="customTip" (focus)="tip_c.checked = true;SetTip(5, true)" (input)="SetTip(5, true)" (keyup)="validateTipMax()"
                                        #customValue data-test="Store-CheckoutCustom$Input" />
                                </div>
                            </div>
                        </div>

                        <div class="row placeorder">
                            <div class="col" fxFlex="34%" fxLayoutAlign="start center">
                                <!-- <div class="row" fxFlex="100%" fxLayoutAlign="center center"> -->
                                <button *ngIf="applePay && applePay.isSupported" mat-flat-button="" class="apple-pay-button-checkout apple-pay-button-black-with-text" (click)="submitPayment('a', stepper)" [disabled]="disableApplePay" data-test="Store-CheckoutCheckoutWithButton">Checkout with</button>
                                <!-- </div> -->
                            </div>
                            <!--  <mat-divider style="height: 40px; margin: 0 5px;" [vertical]="true"></mat-divider> -->
                            <!--                                <div *ngIf="googlePay.isSupported" fxFlex="33%" fxLayoutAlign="center center">
                                          <button mat-flat-button="" class="google-pay" (click)="submitPayment('g')"></button>
                                      </div>
                                      <mat-divider style="height: 40px; margin: 0 5px;" [vertical]="true"></mat-divider>
                                      <div *ngIf="samsungPay.isSupported" fxFlex="33%" fxLayoutAlign="center center">
                                          <button mat-flat-button="" class="samsung-pay"
                                              (click)="submitPayment('s')"></button>
                                      </div> -->

                        </div>
                        <div *ngIf="applePay && applePay.isSupported" class="row" fxLayoutAlign="start center">
                            <span style="margin-left: 20px;">or</span>
                        </div>
                        <div class="row placeorder">
                            <div class="col" fxLayoutAlign="start center">
                                <button class="btn btn-success place-order-button" style="width:100%;" (click)="PlaceOrder(stepper)" [disabled]="!scheduleText || !deliveryInfo || !inRange || processing.inprogress || !isCustomerInfoValid || !isPaymentInfoValid || cartModel.subtotal.Value < merchantInfo.settings.minorderamt"
                                    data-test="Store-CheckoutPlaceYourOrderForButton">
                  Place Your Order for {{cartModel.total.Text}}
                </button>
                            </div>
                        </div>

                    </ng-container>

                    <ng-template #CartEmpty>
                        <div class="row">
                            <div class="col">
                                Your cart is empty! Check out the menu for {{merchantInfo.businessName}} <a class="text-primary" href="/{{merchantInfo.routeName}}" data-test="Store-CheckoutCheckoutMenuA">here!</a>
                            </div>
                        </div>
                    </ng-template>

                </div>

                <div class="container" style="margin-top: 30px;" *ngIf="processing.done && processing.error">

                    <div class="row">
                        <div class="col">
                            <h3>There were errors processing your request</h3>
                        </div>
                    </div>

                </div>
                <div class="container" style="margin-top: 30px;" *ngIf="processing.done && !processing.error">

                    <div class="row">
                        <div class="col">
                            <h3>Your {{cartModel.deliverytypetext}} order for {{scheduleText}} has been successfully processed!
                            </h3>
                        </div>
                    </div>

                </div>

                <ngx-spinner *ngIf="processing.inprogress" bdOpacity="0.9" bdColor="#333" size="medium" color="#fff" type="ball-scale-ripple-multiple" [fullScreen]="true">
                    <p style="color: white"> Submitting Order... </p>
                </ngx-spinner>

            </mat-step>
            <mat-step>
                <div fxLayout="column">
                    <div *ngIf="order" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 25%;">
                        <h2 *ngIf="order.orderstatus === 12" style="text-align: center;color: #000;font-weight: 600;">Your order was declined.
                        </h2>
                    </div>
                    <div *ngIf="order" fxLayout="row" fxLayoutAlign="center center">
                        <h2 style="text-align: center;color: #000;font-weight: 600;" *ngIf="!merchantInfo.settings.customErrorMessage">There was an error processing your order. Please call {{merchantInfo.phone}} for further assistance.
                        </h2>
                        <h2 style="text-align: center;color: #000;font-weight: 600;" *ngIf="merchantInfo.settings.customErrorMessage">{{merchantInfo.settings.customErrorMessage}}</h2>
                    </div>
                    <div *ngIf="order" fxLayout="row" fxLayoutAlign="center center">
                        <h2 *ngIf="orderResponse && orderResponse.paymentResponse && orderResponse.paymentResponse.ResponseCode === 1" style="text-align: center;color: #000;font-weight: 600;color: red">Your payment method was declined. Please enter another payment method.</h2>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 5px">
                        <button mat-raised-button btn btn-success class="stepper-button" (click)="correctOrder(stepper)" data-test="Store-CheckoutCorrectOrderButton">Correct
              Order</button>
                        <button mat-raised-button btn btn-success class="stepper-button" (click)="newOrder(stepper)" data-test="Store-CheckoutNewOrderButton">New
              Order</button>
                    </div>

                </div>
            </mat-step>
        </mat-vertical-stepper>
    </div>
    <!-- Main Page Content -->

    <!-- End Main Page Content -->
</div>