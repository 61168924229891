<div id="order-receipt2" fxLayout="row wrap" fxLayout.lt-md="column" class="receipt-content">
    <div fxLayout="column" style="width: 100%;">
        <div id="top" fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100" *ngIf="merchant">
                <div class="logo" [ngStyle]="{ 'background-image': 'url(' + merchant.logo + ')' }"></div>
            </div>
            <div id="mid" fxLayout="row" fxLayout.lt-md="column" fxFlex="100" *ngIf="merchant">
                <div fxLayout="column" class="info" fxLayoutAlign="center center">
                    <h2>{{merchant.businessName}}</h2>
                    <div fxLayout="row" fxLayoutAlign="center center" class="center">
                        <p> {{merchant.address}}<br /> {{merchant.city}}, {{merchant.state}} {{merchant.zip}}<br> {{merchant.phone}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="businessRating && !isMerchant">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
                <p>Please leave a comment.
                </p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
                <textarea type="text" [(ngModel)]="businessRating.comment" maxlength="200" class="comment" rows="3" cols="20"></textarea>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" aria-label="Rating of this item is 3 out of 5" fxLayoutAlign="center center">
                <ng-rating-bar [(value)]="value" [ratingCount]="5" colorActive="orange" [styles]="{margin: '10px', fontSize: '40px'}" (valueChange)="onRatingChange($event)" [disabled]="disableRatings"></ng-rating-bar>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
                <h3>How Satisfied Were You?</h3>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center">
                <p>Rating your experience helps to ensure top quality service is provided to each and every one of our valuable customers.
                </p>
            </div>
        </div>
        <div *ngIf="order && order.paymethods.length > 0" fxLayout="row" fxLayout.lt-md="column" class="paymentid" fxLayoutAlign="start center">
            <h3>Order: {{order.orderxref}}</h3>
        </div>
        <div id="bot" *ngIf="order" fxLayout.lt-md="column">
            <div id="table">
                <table>
                    <tbody>
                        <tr class="service" *ngFor="let item of order.items">
                            <td class="tableitem" style="vertical-align: baseline;">
                                <p class="itemtext">{{item.qty}}</p>
                            </td>
                            <td colspan="2" class="tableitem">
                                <p class="itemtext">{{item.title}}</p>

                                <div *ngIf="item.wholeMods && item.wholeMods.length > 0">
                                    <p *ngIf="item.wholeMods.length > 0" style="margin-top: 0.5rem; margin-bottom: 0px; line-height: 1rem;">
                                        <label style="margin-bottom: 0px; font-size: 0.8rem;">**Whole**</label></p>
                                    <ul style="margin-bottom: 0px;">
                                        <li *ngFor="let s of item.wholeMods" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;">
                                            <label>{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label></li>
                                    </ul>
                                </div>
                                <div *ngIf="item.leftMods && item.leftMods.length > 0">
                                    <p *ngIf="item.leftMods.length > 0" style="margin-top: 0.5rem; margin-bottom: 0px; line-height: 1rem;">
                                        <label style="margin-bottom: 0px; font-size: 0.8rem;">**1st
                      Half**</label></p>
                                    <ul style="margin-bottom: 0px;">
                                        <li *ngFor="let s of item.leftMods" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;"><label>{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label></li>
                                    </ul>
                                </div>
                                <div *ngIf="item.rightMods && item.rightMods.length > 0">
                                    <p *ngIf="item.rightMods.length > 0" style="margin-top: 0.5rem; margin-bottom: 0px; line-height: 1rem;">
                                        <label style="margin-bottom: 0px; font-size: 0.8rem;">**2nd
                      Half**</label></p>
                                    <ul style="margin-bottom: 0px;">
                                        <li *ngFor="let s of item.rightMods" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;"><label>{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label></li>
                                    </ul>
                                </div>

                                <div *ngIf="(!item.wholeMods && !item.leftMods && !item.rightMods) || (item.wholeMods.length === 0 && item.leftMods.length === 0 && item.rightMods.length === 0)">
                                    <ul>
                                        <li *ngFor="let s of item.sides" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;">
                                            <label>{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label></li>
                                        <li *ngFor="let s of item.options" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;"><label>{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label></li>
                                        <li *ngFor="let s of item.modifiers" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;"><label>{{s.extra.length > 0 && s.extra[0].selected ? s.extra[0].title + ' ' + s.title : s.title}}</label></li>
                                    </ul>
                                </div>

                                <!-- <div *ngIf="item.modifiers.length > 0" class="modifiertitle">
                  <p *ngFor="let m of item.modifiers" style="margin-bottom: 0;margin-left: 10px;">
                    {{m.title}}
                  </p>
                </div>
                <div *ngIf="item.sides.length > 0" class="modifiertitle">
                  <p *ngFor="let s of item.sides" style="margin-bottom: 0;margin-left: 10px;">
                    {{s.title}}
                  </p>
                </div>
                <div *ngIf="item.options.length > 0" class="modifiertitle">
                  <p *ngFor="let o of item.options" style="margin-bottom: 0;margin-left: 10px;">
                    {{o.title}}
                  </p>
                </div> -->
                                <div *ngIf="item.comments && item.comments.trim() !== ''" style="margin-left: 10px;">
                                    <p>
                                        <label style="font-size: small;">{{item.comments.trim()}}</label>
                                    </p>
                                </div>
                            </td>
                            <td class="tableitem" style="vertical-align: baseline;">
                                <p class="pricetext">${{item.labeledPrice || item.price.toFixed(2)}}</p>
                                <!--                                 <div *ngIf="item.options.length > 0" class="modifierprice">
                                    <p *ngFor="let o of item.options" style="margin-bottom: 0;">
                                        ${{(o.price * item.qty||0).toFixed(2)}}
                                    </p>
                                </div> -->

                                <!-- <div *ngIf="item.wholeMods && item.wholeMods.length > 0">
                                    <p *ngIf="item.wholeMods.length > 0" style="margin-top: 0.5rem; margin-bottom: 0px; line-height: 17px;">
                                        <label style="margin-bottom: 0px; font-size: 0.8rem;"></label></p>
                                    <p *ngFor="let s of item.wholeMods" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;" class="modifierprice">
                                        <label>${{(s.price * item.qty||0).toFixed(2)}}</label></p>
                                </div>
                                <div *ngIf="item.leftMods && item.leftMods.length > 0">
                                    <p *ngIf="item.leftMods.length > 0" style="margin-top: 0.5rem; margin-bottom: 0px; line-height: 17px;">
                                        <label style="margin-bottom: 0px; font-size: 0.8rem;"></label></p>
                                    <p *ngFor="let s of item.leftMods" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;" class="modifierprice">
                                        <label>${{(s.price * item.qty||0).toFixed(2)}}</label></p>
                                </div>
                                <div *ngIf="item.rightMods && item.rightMods.length > 0">
                                    <p *ngIf="item.rightMods.length > 0" style="margin-top: 0.5rem; margin-bottom: 0px; line-height: 17px;">
                                        <label style="margin-bottom: 0px; font-size: 0.8rem;"></label></p>
                                    <p *ngFor="let s of item.rightMods" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;" class="modifierprice">
                                        <label>${{(s.price * item.qty||0).toFixed(2)}}</label></p>
                                </div> -->

                                <!-- <div *ngIf="(!item.wholeMods && !item.leftMods && !item.rightMods) || (item.wholeMods.length === 0 && item.leftMods.length === 0 && item.rightMods.length === 0)">
                                    <p *ngFor="let s of item.sides" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;" class="modifierprice">
                                        <label>${{(s.price * item.qty||0).toFixed(2)}}</label></p>
                                    <p *ngFor="let s of item.options" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;" class="modifierprice">
                                        <label>${{(s.price * item.qty||0).toFixed(2)}}</label></p>
                                    <p *ngFor="let s of item.modifiers" style="margin-bottom: 0px; font-size: 0.8rem; line-height: 1rem;" class="modifierprice">
                                        <label>${{(s.price * item.qty||0).toFixed(2)}}</label></p>
                                </div> -->

                                <!-- <div *ngIf="item.modifiers.length > 0" class="modifierprice">
                                    <p *ngFor="let m of item.modifiers" style="margin-bottom: 0; line-height: 1rem; font-size: 0.8rem;">
                                        ${{(m.price * item.qty||0).toFixed(2)}}
                                    </p>
                                </div>
                                <div *ngIf="item.sides.length > 0" class="modifierprice">
                                    <p *ngFor="let s of item.sides" style="margin-bottom: 0; line-height: 1rem; font-size: 0.8rem;">
                                        ${{(s.price * item.qty||0).toFixed(2)}}
                                    </p>
                                </div>
                                <div *ngIf="item.options.length > 0" class="modifierprice">
                                    <p *ngFor="let o of item.options" style="margin-bottom: 0; line-height: 1rem; font-size: 0.8rem;">
                                        ${{(o.price * item.qty||0).toFixed(2)}}
                                    </p>
                                </div> -->
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr height="20%" class="tabletitle">
                            <td colspan="3" class="totals">
                                <h3>Subtotal</h3>
                            </td>
                            <td class="totals">
                                <h3>${{order.subtotal.toFixed(2)}}</h3>
                            </td>
                        </tr>
                        <tr *ngIf="order.serviceFee > 0" height="20%" class="tabletitle">
                            <td colspan="3" class="totals">
                                <h3>Checkout Fee</h3>
                            </td>
                            <td class="totals">
                                <h3>${{order.serviceFee.toFixed(2)}}</h3>
                            </td>
                        </tr>
                        <tr *ngIf="order.convenienceFee > 0" height="20%" class="tabletitle">
                            <td colspan="3" class="totals">
                                <h3>Convenience Fee</h3>
                            </td>
                            <td class="totals">
                                <h3>${{order.convenienceFee.toFixed(2)}}</h3>
                            </td>
                        </tr>
                        <tr height="20%" class="tabletitle">
                            <td colspan="3" class="totals">
                                <h3>Tax</h3>
                            </td>
                            <td class="totals">
                                <h3>${{order.tax.toFixed(2)}}</h3>
                            </td>
                        </tr>
                        <tr height="20%" class="tabletitle">
                            <td colspan="3" class="totals">
                                <h3>Gratuity</h3>
                            </td>
                            <td class="totals">
                                <h3>${{order.tip.toFixed(2)}}</h3>
                            </td>
                        </tr>
                        <tr *ngIf="order.deliveryFee > 0" class="tabletitle">
                            <td colspan="3" class="totals">
                                <h3>Delivery Fee</h3>
                            </td>
                            <td class="totals">
                                <h3>${{order.deliveryFee.toFixed(2)}}</h3>
                            </td>
                        </tr>
                        <tr class="tabletitle">
                            <td colspan="3" class="totals">
                                <h2>Grand Total</h2>
                            </td>
                            <td class="totals">
                                <h2>${{order.totalAmount.toFixed(2)}}</h2>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" class="page-break-after">
            <div fxLayout="column" class="delivery-info" fxFlex="50">
                <!-- <h5 *ngIf="order.ordertype === 2">Pick Up Information</h5>
                <h5 *ngIf="order.ordertype === 3">Delivery Information</h5> -->
                <h5>{{orderType}} Information</h5>
                <div fxLayout="row" fxLayoutAlign="start center" class="align-delivery-info">
                    <p> <b>{{orderType}} Name:</b> {{order.customerInformation.firstName}} {{order.customerInformation.lastName}}
                        <br><b>{{orderType}} Time:</b>
                        <br> {{orderDateTime}}
                        <br><b>{{orderType}} Location:</b>
                        <br> {{address.street1}}
                        <br> {{address.city}}, {{address.state}} {{address.zip}}
                        <br> {{address.phone}}
                        <br> {{address.email}}
                    </p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50">
                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" class="page-break-after">
                    <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                </agm-map>
            </div>
        </div>
        <br />
        <div fxLayout="row" fxLayout.lt-md="column">
            <div fxLayout="column" class="delivery-info" fxFlex="50">
                <h5>Payment Information</h5>
            </div>
            <div fxLayout="column" fxFlex="50" class="delivery-info">
                <div class="brand_logo" fxLayoutAlign="end">
                    <!-- <i [ngClass]="brandname" style="enable-background:new 0 0 47.834 47.834;"></i> -->
                    <img src="../../../assets/cc_logos/{{cardbrandlogo}}.png" height="64px" />
                </div>
                <div fxLayoutAlign="end center" class="payment-info" *ngIf="order && order.paymethods.length > 0">
                    <p class="payment-info"> **** **** **** {{order.paymethods[0].cc_last_four}}<br /> {{order.paymethods[0].name}}
                        <br /> {{ paymentDateTime }}
                        <br /> Payment ID: {{order.paymethods[0].response[order.paymethods[0].response.length - 1].PaymentId}}<br /> Authorization Code: {{order.paymethods[0].response[order.paymethods[0].response.length - 1].AuthorizationNumber}}
                        <br> TID: {{order.paymethods[0].response[order.paymethods[0].response.length - 1].TransactionIdentifier || order.paymethods[0].response[order.paymethods[0].response.length - 1].TransactionIdentifer}}<br> Invoice: {{order.InvoiceNum}}
                    </p>
                </div>
            </div>
        </div>
        <div fxLayout="row" *ngIf="showPrinter" class="noprint">
            <div fxLayout="column" fxFlex="50" fxLayoutAlign="start center">
                <button type="button" class="btn receipt-button noprint" (click)="print()">Print Receipt</button>
            </div>
            <div fxLayout="column" fxFlex="50" fxLayoutAlign="end center">
                <button type="button" class="btn receipt-button noprint" (click)="close()">Close</button>
            </div>
        </div>
        <div fxLayout="row" *ngIf="!showPrinter" class="noprint">
            <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
                <button type="button" class="btn receipt-button noprint" (click)="close()">Close</button>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="noprint">
            <div fxLayout="column" fxFlex="50">
                <div class="deliverme_logo_image">
                    <h6 class="powered-by">Powered By</h6>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50">
                <p>&copy;{{year}} Electronic Payments, Inc.<br /> 7800 Congress Ave, Suite 108<br /> Boca Raton, FL 33487
                </p>
            </div>
        </div>


        <!--        <div class="grid-container">
            <div id="grid1" class="grid-item-powered-by">
                <div fxLayout.lt-md="row" class="deliverme_logo_image">
                    <h6 class="powered-by">Powered By</h6>
                </div>
            </div>
            <div id="grid2" fxFlex.lt-md="100" class="grid-item-powered-by">
                <p class="powered-by">&copy;{{year}} Electronic Payments, Inc.<br /> 7800 Congress Ave, Suite 108<br /> Boca Raton, FL 33487
                </p>
            </div>
            <div id="grid3" class="grid-item3">
            </div>
            <div id="grid4" class="grid-item">

            </div>
        </div> -->
    </div>
</div>