import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Merchant } from 'projects/common/src/lib/merchant';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogEntry } from 'projects/common/src/lib/log-entry';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(public http: HttpClient) { }

  public writeToLog(entry: LogEntry, access_token: string = undefined): Observable<any> {
    let httpOptions: any;

    if (access_token) {
      httpOptions = {
        headers: new HttpHeaders({
          'authorization': access_token
        })
      };
    }

    return this.http.post<any>('/api/logs', entry, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public search(query: any): Observable<any> {
    // lastid is the last mongo objectid returned in the last record from the previous page

    const httpOptions = {
      headers: new HttpHeaders({
        'applicationkey': query.merchant.applicationKey,
        'merchantnumber': query.merchant.merchantNumber1,
        'x-api-key': query.merchant.applicationKey
      })
    };

    if (!query.pageno) {
      query.pageno = 1;
    }

    if (!query.key || !query.value) {
      return throwError(new Error('key and value are required'));
    }

    if (!query.startdate || !query.enddate) {
      return throwError(new Error('start and end date are required'));
    }

    if (!query.lastid) {
      query = '/api/logs/' + query.pageno + '/' + query.pagecount + '/' + query.startdate + '/' + query.enddate + '/' + query.application + '/' + query.key + '/' + query.value;
    } else {
      query = '/api/logs/' + query.pageno + '/' + query.pagecount + '/' + query.startdate + '/' + query.enddate + '/' + query.application + '/' + query.key + '/' + query.value + query.lastid;
    }

    return this.http.get<any>(query, httpOptions)
      .pipe(
        catchError(this.handleError)
      );

  }

  public handleError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${err.status}, ` +
        `body was: ${err.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(err);
  }

}
