import { Validators } from '@angular/forms';

export class Customer {
    public _id: string;
    public name: string;
    public firstName: string;
    public lastName: string;
    public street1: string;
    public street2: string;
    public city: string;
    public state: string;
    public postalcode: string;
    public country: string;
    public phone: string;
    public carrier: string;
    public email: string;
    public geolocation: any;
    public deliveryDistance: number;
    public lat: number;
    public lng: number;
    // public placeId: string;

    constructor() {
        this._id = '';
        this.name = '';
        this.firstName = '';
        this.lastName = '';
        this.street1 = '';
        this.street2 = '';
        this.city = '';
        this.state = '';
        this.postalcode = '';
        this.country = '';
        this.phone = '';
        this.carrier = '';
        this.email = '';
        this.deliveryDistance = 0;
        this.lat = 0;
        this.lng = 0;
        // this.placeId = "";
    }
}

export class CustomerFormModel {
  public firstName = ['', Validators.required];
  public lastName = ['', Validators.required];
  public street1 = ['', Validators.required];
  public street2 = [''];
  public city = ['', Validators.required];
  public state = ['', Validators.required];
  public postalcode = ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]];
  public email = ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]];
  public phone = [''];
}

export class CustomerFormValuesModel {
  public firstName: string;
  public lastName: string;
  public street1: string;
  public street2: string;
  public city: string;
  public state: string;
  public postalcode: string;
  public email: string;
  public phone: string;
}

export class CustomerCardFormModel {
  public cardtype = [''];
  public name = ['', Validators.required];
  public cardnumber = ['', Validators.required];
  public cardcvv = ['', Validators.required];
  public cardexpmonth = ['', Validators.required];
  public cardexpyear = ['', Validators.required];

  // billing info:
  public street1 = ['', Validators.required];
  public street2 = [''];
  public city = ['', Validators.required];
  public state = ['', Validators.required];
  public postalcode = ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]];
}

export class CustomerCardFormValuesModel {
  public cardtype: string;
  public name: string;
  public cardnumber: string;
  public cardcvv: string;
  public cardexpmonth: string;
  public cardexpyear: string;

  // billing info:
  public street1: string;
  public street2: string;
  public city: string;
  public state: string;
  public postalcode: string;
}
