<div style="padding: 24px;" *ngIf="merchantList && merchantList.length > 1">
    <div>
        <mat-expansion-panel expanded hideToggle disabled style="background: none;">
            <mat-expansion-panel-header>
                <mat-panel-description style="color: black;">
                    <h6>Please select a store location for this merchant!</h6>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div fxLayout="row" *ngIf="merchantList && merchantList.length > 1">
                <div fxFlex="100%" class="input-group my-2 w-100" style="border: 1px solid; margin-bottom: 30px !important; margin-right: 10px;">
                    <input type="text" #search class="form-control border-0 bg-transparent" placeholder="Search Locations" name="searchText" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" aria-label="form" aria-describedby="form" (input)="SearchLocations()"
                        [matAutocomplete]="autocomplete" style="font-weight: bold;" data-test="Merchant-Prompt-DialogSearchLocationsInput"/>

                    <mat-autocomplete #autocomplete="matAutocomplete">
                        <mat-option *ngFor="let m of searchMerchants" (click)="SelectLocation(m)">
                            <label>{{m.businessName}}</label>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-button (click)="Cancel()" data-test="Merchant-Prompt-DialogCancelButton">Cancel</button>
        <button mat-button (click)="Confirm()" data-test="Merchant-Prompt-DialogConfirmButton">Confirm</button>
    </div>
</div>