import { Component, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { Regex } from 'projects/common/src/lib/regex';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import * as cardValidator from 'card-validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoogleApiService } from 'projects/services/src/lib/google-api.service';
import { Address } from 'projects/common/src/lib/address';
import { StorageMap } from '@ngx-pwa/local-storage';
import { CardInfo } from 'projects/common/src/lib/cardinfo';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import ccType from 'credit-card-type';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  public cardInfo: CardInfo = new CardInfo();
  public payMethods: CardInfo[] = [];
  public regexList: Regex = new Regex();
  public us_phone_regex = this.regexList.us_phone_regex;
  public ccVal = 'new';
  public cardnum = '';
  // public newCardForm: FormGroup;
  public ccRegex = {
    brand: '',
    minlength: 16,
    mincvv: 3,
    cvvmask: 999,
    ccmask: this.regexList.ccRegexList.common
    // ccmask: ['']
  };
  public mask: any;
  public _to: any;

  cvvMask = 'XXX';
  ccMask = 'XXXXXXXXXXXX0000';
  cardbrandlogoval: string;

  // public formControls: any;
  address: Address = new Address();
  matches: [];
  searchTimer: any;

  public states: string[] = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
    'NL',
    'PE',
    'NS',
    'NB',
    'QC',
    'ON',
    'MB',
    'SK',
    'AB',
    'BC',
    'YT',
    'NT',
    'NU'
  ];

  // this.ccRegex.mincvv = 4;

  public newCardForm = new UntypedFormGroup({
    name: new UntypedFormControl(this.cardInfo.name, [Validators.required, this.invalidName()]),
    firstname: new UntypedFormControl('', []),
    lastname: new UntypedFormControl('', []),
    email: new UntypedFormControl('', []),
    phone: new UntypedFormControl('', [Validators.pattern(this.regexList.us_phone_regex)]),
    cardnumber: new UntypedFormControl(this.cardInfo.cardnumber, [Validators.required, Validators.minLength(this.ccRegex.minlength), Validators.maxLength(22), this._invalidCardNumberValidator()]),
    cvv: new UntypedFormControl(this.cardInfo.cvv, [Validators.required, Validators.minLength(this.ccRegex.mincvv), Validators.maxLength(this.ccRegex.mincvv)]),
    expdate: new UntypedFormControl(this.cardInfo.expdate, [Validators.required, this._invalidExpDateValidator()]),
    cardbrand: new UntypedFormControl(this.cardInfo.cardbrand, []),
    cardbrandlogo: new UntypedFormControl(this.cardInfo.cardbrandlogo, []),
    address: new UntypedFormControl('', []),
    addressF: new UntypedFormControl('', []),
    street1: new UntypedFormControl(this.cardInfo.street1, [Validators.required]),
    city: new UntypedFormControl(this.cardInfo.city, [Validators.required]),
    state: new UntypedFormControl(this.cardInfo.state, [Validators.required]),
    zip: new UntypedFormControl(this.cardInfo.zip, [Validators.required, Validators.minLength(5), Validators.maxLength(10)]),
    country: new UntypedFormControl(this.cardInfo.country, [Validators.required]),
  });

  get name(): any { return this.newCardForm.get('name'); }
  get cardnumber(): any { return this.newCardForm.get('cardnumber'); }
  get cardbrandlogo(): any { return this.newCardForm.get('cardbrandlogo'); }
  get cvv(): any { return this.newCardForm.get('cvv'); }
  get expdate(): any { return this.newCardForm.get('expdate'); }
  get firstname(): any { return this.newCardForm.get('firstname'); }
  get lastname(): any { return this.newCardForm.get('lastname'); }
  get phone(): any { return this.newCardForm.get('phone'); }
  get email(): any { return this.newCardForm.get('email'); }
  get addressF(): any { return this.newCardForm.get('addressF') ? this.newCardForm.get('addressF') : ''; }
  get street1(): any { return this.newCardForm.get('street1') ? this.newCardForm.get('street1') : ''; }
  get city(): any { return this.newCardForm.get('city') || ''; }
  get state(): any { return this.newCardForm.get('state') || ''; }
  get zip(): any { return this.newCardForm.get('zip') || ''; }
  get country(): any { return this.newCardForm.get('country') || ''; }

  modalElement: any;

  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localStorage: StorageMap,
    private google: GoogleApiService,
  ) {
    console.log(data.order);
    // this.newCardForm.
  }

  ngOnInit() {
    if (this.data.cardLength) {
      this.maskCard();
    }

    // do not place disableBodyScroll in constructor, it intereferes with loading the reactive form
    this.modalElement = document.querySelector('#body-content');
    disableBodyScroll(this.modalElement);
  }

  ngAfterViewInit() {
    this.localStorage.get('paymethod').subscribe((p: CardInfo) => {
      if (p) {
        this.cardInfo = p;

        this.ccRegex.mincvv = (p.cvv.toString().length > 0) ? p.cvv.toString().length : 3;

        this.ccRegex.cvvmask = 9 * (this.ccRegex.mincvv === 3 ? 111 : 1111);
      } else {
        this.cardInfo = new CardInfo();
        if (this.data.address) {
          this.cardInfo.name = this.data.address.name;
          this.cardInfo.addressF = this.data.address.addressF;
          this.cardInfo.street1 = this.data.address.address.street1;
          this.cardInfo.city = this.data.address.address.city;
          this.cardInfo.state = this.data.address.address.state;
          this.cardInfo.zip = this.data.address.address.postalCode;
        }
      }

      this.ccRegex.minlength = this.cardInfo.cardnumber.length;

      this.cvv.setValidators([Validators.required, Validators.minLength(this.ccRegex.mincvv), Validators.maxLength(this.ccRegex.mincvv)]);

      this.cvvMask = 'X'.repeat((p && p.cvv.toString().length > 0) ? p.cvv.toString().length : 3);

      this.newCardForm.patchValue({ name: this.cardInfo.name });

      /*       if (this.cardInfo.cardnumber) {
              this.setMaskedCardNumber();
            } else { */
      this.newCardForm.patchValue({ cardnumber: this.cardInfo.cardnumber });
      this.ccnumChange();
      /*       } */

      this.newCardForm.patchValue({ cvv: this.cardInfo.cvv });
      this.cvv.value = this.cardInfo.cvv;
      this.newCardForm.updateValueAndValidity();

      this.newCardForm.patchValue({ expdate: this.cardInfo.expdate });
      this.newCardForm.patchValue({ cardbrand: this.cardInfo.cardbrand });
      this.newCardForm.patchValue({ cardbrandlogo: this.cardInfo.cardbrandlogo });
      this.newCardForm.patchValue({ email: this.cardInfo.email });
      this.newCardForm.patchValue({ phone: this.cardInfo.phone });
      this.newCardForm.patchValue({ addressF: this.cardInfo.addressF ? this.cardInfo.addressF.trim() : '' });
      this.newCardForm.patchValue({ street1: this.cardInfo.street1 });
      this.newCardForm.patchValue({ street2: '' });
      this.newCardForm.patchValue({ city: this.cardInfo.city });
      this.newCardForm.patchValue({ state: this.cardInfo.state });
      this.newCardForm.patchValue({ zip: this.cardInfo.zip });
      this.newCardForm.patchValue({ country: this.cardInfo.country });
    });
  }

  ngOnDestroy() {
    enableBodyScroll(this.modalElement);
  }

  private invalidName(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.newCardForm) { return null; }
        if (this.newCardForm.pristine && !this.newCardForm.dirty && !this.newCardForm.touched) { return null; }

        if (control.value.split(' ').length < 2) {
          return { invalidName: { value: control.value } };
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  private maskCard() {
    switch (this.data.cardLength) {
      case 13:
        this.ccRegex.ccmask = this.regexList.ccRegexList.masked_maestro13;
        break;
      case 14:
        this.ccRegex.ccmask = this.regexList.ccRegexList.masked_diners_inter;
        break;
      case 15:
        this.ccRegex.ccmask = this.regexList.ccRegexList.masked_amex;
        break;
      // case 16:
      //  this.ccRegex.ccmask = this.regexList.ccRegexList.masked_common;
      // break;
      case 17:
        this.ccRegex.ccmask = this.regexList.ccRegexList.masked_unknown17;
        break;
      case 18:
        this.ccRegex.ccmask = this.regexList.ccRegexList.masked_unknown18;
        break;
      case 19:
        this.ccRegex.ccmask = this.regexList.ccRegexList.masked_unionpay19;
        break;
      default:
        this.ccRegex.ccmask = this.regexList.ccRegexList.masked_common;
        break;

    }
  }

  private _invalidCardNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.newCardForm) { return null; }
        if (this.newCardForm.pristine && !this.newCardForm.dirty && !this.newCardForm.touched) { return null; }

        const cardNumber = this.newCardForm.get('cardnumber').value;
        const valid = cardValidator.number(cardNumber).isValid;

        /*        if (cardNumber.indexOf('*') === -1) {
                 cardNumber = cardNumber.replace(this.regexList.fixCCstring, '');
                 valid = cardValidator.number(cardNumber).isValid;
               } else {
                 while (cardNumber.indexOf(' ') !== -1) {
                   cardNumber = cardNumber.replace(' ', '');
                 }
               } */

        if (!valid) {
          this.cardbrandlogo.value = '';
        }

        return !valid ? { invalidCardNumber: { value: control.value } } : null;

        /*
        if (!valid) {
          return { invalidCardNumber: { value: control.value } };
        } else {
                     if (cardNumber !== '' && cardNumber.indexOf('*') === -1) {
                      this.cardnum = cardNumber;
                    }

                    switch (cardNumber.length) {
                      case 13:
                        if (cardNumber.indexOf('_') !== -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('');
                        } else if (cardNumber.indexOf('*') === -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('**** **** ' + cardNumber.substring(8));
                          this.ccRegex.ccmask = ['**** **** ' + cardNumber.substring(8)];
                        }
                        break;
                      case 14:
                        if (cardNumber.indexOf('_') !== -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('');
                        } else if (cardNumber.indexOf('*') === -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('**** ****** ' + cardNumber.substring(10));
                          this.ccRegex.ccmask = ['**** ****** ' + cardNumber.substring(10)];
                        }
                        break;
                      case 15:
                        if (cardNumber.indexOf('_') !== -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('');
                        } else if (cardNumber.indexOf('*') === -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('**** ****** ' + cardNumber.substring(10));
                          this.ccRegex.ccmask = ['**** ****** ' + cardNumber.substring(10)];
                        }
                        break;
                      case 17:
                        if (cardNumber.indexOf('_') !== -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('');
                        } else if (cardNumber.indexOf('*') === -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('*************' + cardNumber.substring(13));
                          this.ccRegex.ccmask = ['*************' + cardNumber.substring(13)];
                        }
                        break;
                      case 18:
                        if (cardNumber.indexOf('_') !== -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('');
                        } else if (cardNumber.indexOf('*') === -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('**************' + cardNumber.substring(14));
                          this.ccRegex.ccmask = ['**************' + cardNumber.substring(14)];
                        }
                        break;
                      case 19:
                        if (cardNumber.indexOf('_') !== -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('');
                        } else if (cardNumber.indexOf('*') === -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('**** **** **** ****' + cardNumber.substring(16));
                          this.ccRegex.ccmask = ['**** **** **** ****' + cardNumber.substring(16)];
                        }
                        break;
                      default:
                        if (cardNumber.indexOf('_') !== -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').setValue('');
                        } else if (cardNumber.indexOf('*') === -1) {
                          this.newCardForm.get('cardnumber').markAsUntouched();
                          this.newCardForm.get('cardnumber').patchValue('**** **** **** ' + cardNumber.substring(12));
                          this.ccRegex.ccmask = ['**** **** **** ' + cardNumber.substring(12)];
                        }
                        break;
                    }
          return null;
        } */
      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }

  private _invalidExpDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        if (!this.newCardForm) { return null; }
        if (this.newCardForm.pristine && !this.newCardForm.dirty && !this.newCardForm.touched) { return null; }
        const expdate: any = this.newCardForm.get('expdate').value;
        const month = parseInt(expdate.split('/')[0], 10);
        const year = parseInt(expdate.split('/')[1], 10);
        const d = Date.parse(`${month}/01/${year}`);
        if (!d) {
          return { invalidDate: { value: control.value } };
        }
        const td = new Date(d);
        td.setMonth(month);
        td.setSeconds(-1);
        const now = new Date().getTime();
        const valid = td.getTime() > now;

        // this.newCardForm.patchValue({ expdate: control.value });
        return !valid ? { expiredDate: { value: control.value } } : null;
        // console.log(error);
      } catch (error) {
        console.log(error);
        return null;
      }
    };
  }


  // when patching the form value we need this to reset the mask
  /*   public setMaskedCardNumber(): void {
      try {
        this.cardnum = this.cardInfo.cardnumber;
        this.ccRegex.minlength = this.data.cardLength;
        this.cardnumber.setValidators([Validators.required, Validators.minLength(this.ccRegex.minlength), Validators.maxLength(22), this._invalidCardNumberValidator()]);

        switch (this.data.cardLength) {
          case 13:
            this.newCardForm.patchValue({ cardnumber: '**** **** ' + this.cardInfo.cardnumber.substring(8) });
            break;
          case 14:
            this.newCardForm.patchValue({ cardnumber: '**** ****** ' + this.cardInfo.cardnumber.substring(10) });
            break;
          case 15:
            this.newCardForm.patchValue({ cardnumber: '**** ****** ' + this.cardInfo.cardnumber.substring(10) });
            break;
          case 17:
            this.newCardForm.get('cardnumber').setValue('*************' + this.cardInfo.cardnumber.substring(13));
            break;
          case 18:
            this.newCardForm.get('cardnumber').setValue('**************' + this.cardInfo.cardnumber.substring(14));
            break;
          case 19:
            this.newCardForm.get('cardnumber').setValue('**** **** **** ****' + this.cardInfo.cardnumber.substring(15));
            break;
          default:
            this.newCardForm.get('cardnumber').setValue('**** **** **** ' + this.cardInfo.cardnumber.substring(12));
            break;
        }
      } catch (error) {
        console.log(error);
      }
    } */

  public importDeliveryAddress(): void {
    try {

      // this.cardInfo.name = this.data.order.customerInformation.name;
      // this.cardInfo.firstname = this.data.order.customerInformation.firstName;
      // this.cardInfo.lastname = this.data.order.customerInformation.lastName;
      this.cardInfo.email = this.data.order.customerInformation.email;
      this.cardInfo.phone = this.data.order.customerInformation.phone;
      this.cardInfo.street1 = this.data.order.customerInformation.street1;
      this.cardInfo.city = this.data.order.customerInformation.city;
      this.cardInfo.state = this.data.order.customerInformation.state;
      this.cardInfo.zip = this.data.order.customerInformation.postalcode;
      this.cardInfo.country = this.data.order.customerInformation.country;
      this.cardInfo.addressF = this.data.order.customerInformation.addressF;

      // this.newCardForm.patchValue({ name: this.cardInfo.name ? this.cardInfo.name.trim() : '' });
      // this.newCardForm.patchValue({ firstname: this.cardInfo.firstname ? this.cardInfo.firstname.trim() : '' });
      // this.newCardForm.patchValue({ lastname: this.cardInfo.lastname ? this.cardInfo.lastname.trim() : '' });
      this.newCardForm.patchValue({ email: this.cardInfo.email });
      this.newCardForm.patchValue({ phone: this.cardInfo.phone });
      this.newCardForm.patchValue({ addressF: this.cardInfo.addressF ? this.cardInfo.addressF.trim() : '' });
      this.newCardForm.patchValue({ street1: this.cardInfo.street1 });
      this.newCardForm.patchValue({ street2: '' });
      this.newCardForm.patchValue({ city: this.cardInfo.city });
      this.newCardForm.patchValue({ state: this.cardInfo.state });
      this.newCardForm.patchValue({ zip: this.cardInfo.zip });
      this.newCardForm.patchValue({ country: this.cardInfo.country });

    } catch (error) {
      console.log(error);
    }
  }

  public formChange(form: any): void {

    try {
      const nameArray = form.controls.name.value ? form.controls.name.value.split(' ') : [];

      this.cardInfo.addressF = form.controls.street1.value + ', ' + form.controls.city.value + ', ' + form.controls.state.value + ' ' + form.controls.zip.value + ', ' + form.controls.country.value;
      form.controls.addressF.value = this.cardInfo.addressF;

      // console.log(form.controls.expdate.value);
      if (form.controls.expdate.value) {
        const exp_date = form.controls.expdate.value.split('/');
        if (exp_date.length > 1) {
          if (exp_date[1].length === 4) {
            form.controls.expdate.value = exp_date[0] + '/' + exp_date[1].substr(2);
          }
        }
      }

      this.newCardForm.patchValue({ name: (form.controls.name.value) ? form.controls.name.value.trim() : '' });
      this.newCardForm.patchValue({ firstname: nameArray.length > 0 ? nameArray[0] : '' });
      this.newCardForm.patchValue({ lastname: nameArray.length > 1 ? nameArray[1] : '' });
      this.newCardForm.patchValue({ email: form.controls.email.value });
      this.newCardForm.patchValue({ phone: form.controls.phone.value });
      this.newCardForm.patchValue({ cardnumber: form.controls.cardnumber.value });
      this.newCardForm.patchValue({ cvv: form.controls.cvv.value });
      this.newCardForm.patchValue({ expdate: (form.controls.expdate.value) ? form.controls.expdate.value : '' });
      this.newCardForm.patchValue({ cardbrand: form.controls.cardbrand.value });
      this.newCardForm.patchValue({ addressF: form.controls.addressF.value });
      this.newCardForm.patchValue({ street1: form.controls.street1.value });
      this.newCardForm.patchValue({ city: form.controls.city.value });
      this.newCardForm.patchValue({ state: form.controls.state.value });
      this.newCardForm.patchValue({ zip: form.controls.zip.value });

      this.cardInfo.name = this.newCardForm.get('name').value;
      this.cardInfo.firstname = this.newCardForm.get('firstname').value;
      this.cardInfo.lastname = this.newCardForm.get('lastname').value;
      this.cardInfo.cardnumber = this.newCardForm.get('cardnumber').value;
      this.cardInfo.cvv = this.newCardForm.get('cvv').value;
      this.cardInfo.expdate = this.newCardForm.get('expdate').value;
      this.cardInfo.email = this.newCardForm.get('email').value ? this.newCardForm.get('email').value : '';
      this.cardInfo.phone = this.newCardForm.get('phone').value ? this.newCardForm.get('phone').value : '';
      this.cardInfo.addressF = this.newCardForm.get('addressF').value;
      this.cardInfo.street1 = this.newCardForm.get('street1').value;
      this.cardInfo.city = this.newCardForm.get('city').value;
      this.cardInfo.state = this.newCardForm.get('state').value;
      this.cardInfo.zip = this.newCardForm.get('zip').value;
    } catch (error) {
      console.log(error);
    }
  }

  public selectedAddress(addr) {
    clearTimeout(this.searchTimer);

    this.address.geolocation = addr.geometry.location;

    for (let i = 0; i < addr.address_components.length; i++) {
      switch (addr.address_components[i].types[0]) {
        case 'street_number':
          this.cardInfo.street1 = addr.address_components[i].short_name;
          break;
        case 'route':
          this.cardInfo.street1 += ' ' + addr.address_components[i].short_name;
          break;
        case 'locality':
          this.cardInfo.city = addr.address_components[i].short_name;
          break;
        case 'administrative_area_level_1':
          this.cardInfo.state = addr.address_components[i].short_name;
          break;
        case 'postal_code':
          this.cardInfo.zip = addr.address_components[i].short_name;
          break;
        case 'country':
          this.cardInfo.country = addr.address_components[i].short_name || 'USA';
          break;
      }
    }

    this.newCardForm.patchValue({ addressF: this.cardInfo.addressF });
    this.newCardForm.patchValue({ street1: this.cardInfo.street1 });
    this.newCardForm.patchValue({ city: this.cardInfo.city });
    this.newCardForm.patchValue({ state: this.cardInfo.state });
    this.newCardForm.patchValue({ zip: this.cardInfo.zip });
    this.newCardForm.patchValue({ country: this.cardInfo.country });
    this.newCardForm.patchValue({ geocode: addr.geometry.location });
  }

  public ccnumChange() {
    // if (this.newCardForm.get('cardnumber').value.indexOf('*') !== -1) {
    //   this.newCardForm.get('cardnumber').setValue('');
    // }

    let ccnumval = this.newCardForm.get('cardnumber').value;
    ccnumval = ccnumval.replace(this.regexList.fixCCstring, '');
    // this.cardnumber.reset(); <- Causing card number delete issue
    const cards = ccType(ccnumval);
    const validator = cardValidator.number(ccnumval);
    const isValid = validator.isValid;

    if (isValid && validator.card && validator.card.type) {
      this.ccRegex.brand = validator.card.type;
      this.newCardForm.patchValue({ cardbrand: this.ccRegex.brand });
    }

    if (cards && cards.length > 0) {
      const card = cards[0];

      if (validator.card) {
        this.ccRegex.minlength = validator.card.lengths[0];
        this.ccRegex.mincvv = validator.card.code.size;
      } else {
        this.ccRegex.minlength = card.lengths[0];
        this.ccRegex.mincvv = card.code.size;
      }

      this.ccMask = 'X'.repeat(this.ccRegex.minlength - 4) + '0000';
      this.cvvMask = 'X'.repeat(this.ccRegex.mincvv);

      this.cardnumber.setValidators([Validators.required, Validators.minLength(this.ccRegex.minlength), Validators.maxLength(22), this._invalidCardNumberValidator()]);
      this.newCardForm.patchValue({ cardnumber: ccnumval });
      this.cardnumber.setValue(ccnumval);
      // const cvvControl = this.newCardForm.get('cvv');
      // this.cvv.clearValidators();
      this.cvv.setValidators([Validators.required, Validators.minLength(this.ccRegex.mincvv), Validators.maxLength(this.ccRegex.mincvv)]);
      this.newCardForm.patchValue({ cvv: this.cardInfo.cvv });
      this.cvv.setValue(this.cardInfo.cvv);

      this.ccRegex.cvvmask = 9 * (this.ccRegex.mincvv === 3 ? 111 : 1111);

      this.ccRegex.ccmask = this.regexList.ccRegexList.common;

      this.cardnumber.setValidators([Validators.required, Validators.minLength(this.ccRegex.minlength), Validators.maxLength(22), this._invalidCardNumberValidator()]);
      this.newCardForm.patchValue({ cardnumber: ccnumval });

      switch (card.type) {
        case 'visa':
          this.ccRegex.brand = 'visa';
          this.cardbrandlogo.value = 'v';
          break;
        case 'mastercard':
          this.ccRegex.brand = 'mastercard';
          this.cardbrandlogo.value = 'm';
          break;
        case 'american-express':
          this.ccRegex.brand = 'american-express';
          this.ccRegex.ccmask = this.regexList.ccRegexList.amex;
          this.cardbrandlogo.value = 'a';
          break;
        case 'diners-club':
          this.ccRegex.brand = 'diners';
          this.cardbrandlogo.value = 'dc';
          if (card.lengths[0] <= 14) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.diners_inter;
          }
          break;
        case 'discover':
          this.ccRegex.brand = 'discover';
          this.cardbrandlogo.value = 'd';
          break;
        case 'jcb':
          this.ccRegex.brand = 'jcb';
          this.cardbrandlogo.value = 'jcb';
          break;
        case 'unionpay':
          this.ccRegex.brand = 'unionpay';
          if (card.lengths[0] === 19) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.unionpay19;
          } else if (card.lengths[0] === 17) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.unknown17;
          } else if (card.lengths[0] === 18) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.unknown18;
          }
          break;
        case 'maestro':
          this.ccRegex.brand = 'maestro';
          this.cardbrandlogo.value = 'me';
          if (card.lengths[0] === 13) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.maestro13;
          } else if (card.lengths[0] === 15) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.maestro15;
          } else if (card.lengths[0] === 16) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.common;
          } else if (card.lengths[0] === 19) {
            this.ccRegex.ccmask = this.regexList.ccRegexList.maestro19;
          }
          break;
        default:
          this.ccRegex.brand = undefined;
          break;
      }

      this.newCardForm.patchValue({ cardbrandlogo: this.cardbrandlogo.value });
    }
  }

  /*   public ccKeyPress(keyevent): boolean {
      try {
        const char = (keyevent.which) ? keyevent.which : keyevent.keyCode;
      } catch (error) {
        console.log(error);
      }
    } */

  public addressLookUp(): void {
    clearTimeout(this.searchTimer);

    this.searchTimer = setTimeout(() => {
      this.google.search(this.newCardForm.get('street1').value).subscribe((d: any) => {
        this.matches = d;
      }, error => {
        console.log('error:', error);
      });
    }, 1000);
  }

  public Submit() {

    enableBodyScroll(this.modalElement);

    if (this.ccVal === 'new') {
      const newcc = this.newCardForm.value as CardInfo;
      const data = new CardInfo();
      for (const prop in newcc) {
        if (prop) {
          data[prop] = newcc[prop];
        }
      }

      // data.cardnumber = this.cardnumber.value;
      // console.log('DATA:');
      // console.log(data);
      this.dialogRef.close(data);
      // this.dialogRef.close(data);
    } else {
      console.log('CCVal:');
      console.log(this.payMethods[parseInt(this.ccVal, 10)]);
      this.dialogRef.close(this.payMethods[parseInt(this.ccVal, 10)]);
    }
  }

  public close() {
    enableBodyScroll(this.modalElement);
    this.dialogRef.close();
  }
}
