import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { Order } from 'projects/common/src/lib/order';
import { Merchant } from 'projects/common/src/lib/merchant';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ShoppingCartService } from 'projects/services/src/lib/shopping-cart.service';
import { OrderTypeEnum } from 'projects/common/src/lib/ordertype';
import { Rating } from 'projects/common/src/lib/rating';
import { User } from 'projects/common/src/lib/user';
import { LogIn } from 'projects/common/src/lib/login';
import { RatingsService } from 'projects/services/src/lib/ratings.service';
import { AuthenticationService } from 'projects/services/src/lib/authentication.service';
import { Subscription } from 'rxjs';

// import moment from 'moment';
import * as moment from 'moment';
import 'moment-timezone';

declare var google: any;

@Component({
  selector: 'app-receipt2',
  templateUrl: './receipt2.component.html',
  styleUrls: ['./receipt2.component.scss']
})
export class Receipt2Component implements OnInit, OnDestroy {
  @Input() order: Order;
  @Input() merchant: Merchant;
  @Input() brandname: string;
  @Input() isMerchant: boolean;

  user: User;
  ratingSubscription: Subscription;
  public year = new Date().getFullYear().toString();
  public value = 0;
  public longitude = 10;
  public latitude = 25;
  public zoom = 15;
  public address = {
    street1: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: ''
  };
  private geoCoder;

  public orderType: string;
  public disableRatings = false;
  public cardbrandlogo = '';
  public showPrinter = true;
  public orderDateTime = '';
  public paymentDateTime = '';
  public businessRating = new Rating();

  @ViewChild('search', { static: true }) public searchElementRef: ElementRef;

  public paymentDetailsTitle = 'Show Payment Details';

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private activeRoute: ActivatedRoute,
    public router: Router,
    public shoppingCartService: ShoppingCartService,
    public localStorage: StorageMap,
    private ratingsService: RatingsService) {
    // console.log('receipt application');

    if (typeof window.print !== 'function') {
      this.showPrinter = false;
    }
  }

  ngOnInit() {

    this.orderDateTime = '';

    const timezone = moment.tz(moment.tz.guess()).zoneAbbr();

    const offset = moment().utcOffset();
    const hrs = offset / 60;

    // const tzDate = new Date(this.order.createtime).toString();
    const tzDate = new Date(this.order.datetime).toString();
    this.orderDateTime = moment(tzDate).format('MMMM Do YYYY, h:mm:ss a') + ' (' + timezone + ')';
    const paymentDate = new Date(this.order.paymethods[0].datetime);
    this.paymentDateTime = moment(paymentDate).format('MMMM Do YYYY, h:mm:ss a') + ' (' + timezone + ')';

    this.mapsAPILoader.load().then(async () => {
      // this.setCurrentLocation();

      this.merchant.orderOptions.find(o => {
        if (o.value === this.order.ordertype) {
          this.orderType = o.desc;
        }
      });

      if (this.order.ordertype === OrderTypeEnum.Delivery) {
        this.address.street1 = this.capitalize(this.order.customerInformation.street1);
        this.address.city = this.capitalize(this.order.customerInformation.city);
        this.address.state = this.order.customerInformation.state.toUpperCase();
        this.address.zip = this.order.customerInformation.postalcode;
        this.address.phone = this.order.customerInformation.phone;
        this.address.email = this.order.customerInformation.email;
      } else {
        this.address.street1 = this.capitalize(this.merchant.address);
        this.address.city = this.capitalize(this.merchant.city);
        this.address.state = this.merchant.state.toUpperCase();
        this.address.zip = this.merchant.zip;
        this.address.phone = this.merchant.phone && this.merchant.phone.trim() !== '' ? this.merchant.phone : '';
        this.address.email = '';
      }

      for (const item of this.order.items) {
        item.wholeMods = [];
        item.wholeMods = item.wholeMods.concat(item.sides.filter(s => s.ratio === 0));
        item.wholeMods = item.wholeMods.concat(item.options.filter(o => o.ratio === 0));
        item.wholeMods = item.wholeMods.concat(item.modifiers.filter(m => m.ratio === 0));

        item.rightMods = [];
        item.rightMods = item.rightMods.concat(item.sides.filter(s => s.ratio === 1));
        item.rightMods = item.rightMods.concat(item.options.filter(o => o.ratio === 1));
        item.rightMods = item.rightMods.concat(item.modifiers.filter(m => m.ratio === 1));

        item.leftMods = [];
        item.leftMods = item.leftMods.concat(item.sides.filter(s => s.ratio === -1));
        item.leftMods = item.leftMods.concat(item.options.filter(o => o.ratio === -1));
        item.leftMods = item.leftMods.concat(item.modifiers.filter(m => m.ratio === -1));

        console.log(item);

        if (parseFloat(item.labeledPrice) > 0) {
          item.labeledPrice = parseFloat(item.labeledPrice).toFixed(2);
        } else {
          item.labeledPrice = '0.00';
        }
      }

      if (this.order.ordertype === OrderTypeEnum.Delivery) {
        if (this.order.customerInformation.geolocation.lat && this.order.customerInformation.geolocation.lat !== 0) {
          this.latitude = this.order.customerInformation.geolocation.lat;
        }
        if (this.order.customerInformation.geolocation.lng && this.order.customerInformation.geolocation.lng !== 0) {
          this.longitude = this.order.customerInformation.geolocation.lng;
        }
      }

      if (this.order.ordertype !== OrderTypeEnum.Delivery) {
        if (this.merchant.latitude && this.merchant.latitude !== 0) {
          this.latitude = this.merchant.latitude;
        }
        if (this.merchant.longitude && this.merchant.longitude !== 0) {
          this.longitude = this.merchant.longitude;
        }
      }

      switch (this.order.paymethods[0].cc_type) {
        case 'visa':
          this.cardbrandlogo = 'v';
          break;
        case 'mastercard':
          this.cardbrandlogo = 'm';
          break;
        case 'american-express':
          this.cardbrandlogo = 'a';
          break;
        case 'diners-club':
          this.cardbrandlogo = 'dc';
          break;
        case 'discover':
          this.cardbrandlogo = 'd';
          break;
        case 'jcb':
          this.cardbrandlogo = 'jcb';
          break;
        case 'paypal':
          this.cardbrandlogo = 'p';
          break;
        case 'bitcoin':
          this.cardbrandlogo = 'bit';
          break;
        case 'maestro':
          this.cardbrandlogo = 'me';
          break;
        default:
          break;
      }

      this.setCurrentLocation(this.latitude, this.longitude);
    });
  }

  ngOnDestroy() {
    if (this.ratingSubscription) {
      this.ratingSubscription.unsubscribe();
    }
  }

  addRating(value: number): void {
    try {
      if (this.ratingSubscription) {
        this.ratingSubscription.unsubscribe();
      }

      this.disableRatings = true;

      this.businessRating.merchantid = this.merchant._id;
      this.businessRating.rating = value;
      this.businessRating.receiptNumber = this.order.receiptNumber;
      this.businessRating.userid = (this.order.userid) ? this.order.userid : '';

      if (this.order.paymethods && this.order.paymethods.length > 0) {
        if (this.order.paymethods[0].response && this.order.paymethods[0].response.length === 1) {
          this.businessRating.transactionId = this.order.paymethods[0].response[0].TransactionIdentifier;
        } else if (this.order.paymethods[0].response && this.order.paymethods[0].response.length > 1) {
          this.businessRating.transactionId = this.order.paymethods[0].response[1].TransactionIdentifier;
        }
      }

      this.ratingSubscription = this.ratingsService.addRating(this.businessRating).subscribe(() => {
        this.disableRatings = false;
      }, (error => {
        console.log(error.message);
        this.disableRatings = false;
      }));

    } catch (error) {
      console.log(error.message);
      this.disableRatings = false;
    }
  }

  public capitalize(s: string): string {
    let capitalized = '';
    const strings = s.split(' ');
    strings.forEach(w => {
      w = w[0].toUpperCase() + w.substr(1).toLowerCase();
      capitalized += w + ' ';
    });
    return capitalized.trim();
  }

  public clearOrder(): void {
    try {
      this.localStorage.delete('cart').subscribe(async () => {
        await this.shoppingCartService.clearItems();
      });
    } catch (error) {

    }
  }

  private setCurrentLocation(lat: number, lng: number) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = lat; // position.coords.latitude;
        this.longitude = lng; // position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  public paymentDetailToggle(): void {
    if (this.paymentDetailsTitle === 'Show Payment Details') {
      this.paymentDetailsTitle = 'Hide Payment Details';
    } else {
      this.paymentDetailsTitle = 'Show Payment Details';
    }
  }

  public displayMap(): void {
    try {
      this.router.navigate(['/mobile-item-detail']);
    } catch (error) {
      console.log(error);
    }
  }

  public goHome(): void {
    this.clearOrder();
    this.router.navigate(['/' + this.merchant.routeName]);
  }

  public onRatingChange(event: any): void {
    this.addRating(event);
  }

  public print(): void {
    try {
      window.print();
    } catch (error) {

    }
  }

  public close(): void {
    this.router.navigate([this.merchant.routeName], { state: { url: 'receipt' } });
  }
}
