<div id="more-info-modal" class="infoContainer"
    *ngIf="merchantInfo && merchantInfo.settings && merchantInfo.settings.pickupHours">
    <!-- <div> -->
    <div class="content-header">
        <div class="row">
            <div class="col-2">
                <button class="btn" (click)="close()" data-test="Store-InfoCloseButton">
                    <i class="fas fa-times fa-lg"></i>
                </button>
            </div>
            <div class="col-10">
                <h2 class="mods-item-title col-12">Hours Of Operation</h2>
            </div>
        </div>
    </div>
    <mat-dialog-content>
        <!-- <div class="content-body"> -->
            <!-- <div class="container-fluid px-0"> -->
                <mat-list class="business-hours">
                   <div *ngFor="let orderType of orderTypes; let idx = index"> 
                        <mat-list-item>
                            <label class="col-12"
                            style="font-weight: bolder; border: none !important; padding: 0.5rem 0 1.5rem 0 !important;">{{orderType.desc}}:
                            {{orderType.waitTimes}} Minute(s) Wait Time</label>
                        </mat-list-item>
                        <mat-list-item *ngFor="let day of DaysOfWeek; let i = index">
                            <p matLine
                                *ngIf="orderType.operationHours[i].openHour !== null && orderType.operationHours[i].closeHour !== null && orderType.operationHours[i].openHour.trim() !== '' && orderType.operationHours[i].closeHour.trim() !== ''">
                                <span fxFlex="34%">{{day}}</span>
                                <span>From {{orderType.operationHours[i].openHour}} To
                                    {{orderType.operationHours[i].closeHour}}</span>
                            </p>
                            <p matLine
                                *ngIf="orderType.operationHours[i].openHour === null || orderType.operationHours[i].closeHour === null || orderType.operationHours[i].openHour.trim() === '' || orderType.operationHours[i].closeHour.trim() === ''">
                                {{day}} Closed
                            </p>
                        </mat-list-item>

                        <!--                     <div *ngFor="let day of DaysOfWeek; let i = index" class="col-md-12 mods-title">
                        <label class="col-md-3" style="font-weight: bold;">{{day}}</label>
                        <label class="col-md-9" *ngIf="operationHours[i].openHour !== null && operationHours[i].closeHour !== null && operationHours[i].openHour.trim() !== '' && operationHours[i].closeHour.trim() !== ''">
                                From {{operationHours[i].openHour}} To
                                {{operationHours[i].closeHour}}
                            </label>
                        <label class="col-md-9" *ngIf="operationHours[i].openHour === null || operationHours[i].closeHour === null || operationHours[i].openHour.trim() === '' || operationHours[i].closeHour.trim() === ''">
                                Closed
                            </label>
                    </div> -->
                    </div>
                </mat-list>
            <!-- </div> -->
        <!-- </div> -->
    </mat-dialog-content>
    <!-- </div> -->
</div>