import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output, OnInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LogIn } from 'projects/common/src/lib/login';
import { Register } from 'projects/common/src/lib/register';
import { User } from 'projects/common/src/lib/user';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap, map, first } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json;charset=utf-8'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private user: User = new User();
  private userStorageKey = 'user';

  // authenticationEvent$ = this.authenticationDelegate.asObservable();
  constructor(
    private http: HttpClient,
    private localStorage: StorageMap
  ) {
    this.localStorage.get('user').subscribe((user: User) => {
      this.user = user;
    });
  }

  public getConfig(configKey: string, access_token: string = undefined): Observable<any> {
    let httpOptions: any;

    if (access_token) {
      httpOptions = {
        headers: new HttpHeaders({
          'authorization': access_token
        })
      };
    }

    return this.http.get<any>('/api/consul/' + configKey, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public updateConfig(configKey: string, value: any): Observable<any> {
    return this.http.put<any>('/api/consul/' + configKey, value)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

}
