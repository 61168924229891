<div class="body" id="item-modal" style="padding: 0 !important;">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div id="divItemDetailClose" fxLayoutAlign="start center">
                <a class="nav-link float-right" data-dismiss="modal" [routerLink]="[]" (click)="close()" data-test="Item-Details-ModalModalA">
                    <i class="fas fa-times fa-lg"></i>
                </a>
            </div>
            <div class="modal-header">
                <div class="row mx-0">
                    <div class="col-9">
                        <h1 class="mods-item-title col-12">{{item.title}}</h1>
                        <h3 class="mods-description">{{item.desc}}</h3>
                        <h2 class="mods-price" *ngIf="item.price > 0">${{item.price.toFixed(2)}}</h2>
                        <h2 class="mods-price" *ngIf="item.price <= 0 && item.price + sidesPrice > 0">
                            ${{(item.price + sidesPrice).toFixed(2)}}</h2>
                    </div>
                    <div class="col-3 menu-item-image" style="height: 151px;">
                        <div [ngStyle]="{ 'background-image': 'url(' + item.image + ')' }">
                        </div>
                    </div>
                </div>

                <!-- <div class="row">
                    <h1 class="mods-item-title col-12">{{item.title}}</h1>
                </div>
                <h3 class="mods-description">{{item.desc}}</h3>
                <h4 *ngIf="item.nutrition" class="mods-additional-data">{{item.nutrition.calories}} calories per serving
                </h4>
                <h2 class="mods-price" *ngIf="item.price > 0">${{item.price.toFixed(2)}}</h2>
                <h2 class="mods-price" *ngIf="item.price <= 0 && item.price + sidesPrice > 0">
                    ${{(item.price + sidesPrice).toFixed(2)}}</h2> -->
            </div>
            <div class="modal-body">
                <div class="container-fluid px-0">
                    <div class="row" style="padding-bottom: 10px;" *ngFor="let group of modalItem.modifier_groups;let gi = index;">
                        <div class="col-12 mods-title" *ngIf="noneRMS && group.max_limit > 0">
                            {{group.title}} - Choose {{group.max_limit === 1 ? group.max_limit + ' Item': 'Up To ' + group.max_limit + ' Items'}}:
                            <mat-error class="error-message" *ngIf="modifierError[gi] && noneRMS">
                                Please Select at Least {{group.min_limit}} To Continue!
                            </mat-error>
                        </div>
                        <div class="col-12 mods-title" *ngIf="noneRMS && group.max_limit === 0">
                            {{group.title}}:
                        </div>
                        <div class="col-4" *ngIf="group.allowRatio"></div>
                        <div class="col-2" fxLayoutAlign="center center" *ngIf="group.allowRatio">
                            Left
                        </div>
                        <div class="col-2" fxLayoutAlign="center center" *ngIf="group.allowRatio">
                            Whole
                        </div>
                        <div class="col-2" fxLayoutAlign="center center" *ngIf="group.allowRatio">
                            Right
                        </div>
                        <div class="col-12" *ngFor="let s of group.items;let idx=index;">
                            <div class="form-check mods-desc row">
                                <div class="col-4">
                                    <input class="form-check-input" type="checkbox" name="modifier_{{gi + '_' + idx}}" [(ngModel)]="s.selected" id="modifier_{{gi + '_' + idx}}" (click)="chooseModifier(idx, gi)" [disabled]="group.max_limit > 1 && modifiersChosen[gi] >= group.max_limit && !s.selected"
                                        data-test="Item-DetailsFormCheckInput1" />
                                    <label class="form-check-label" for="modifier_{{gi + '_' + idx}}">{{s.title||s.desc}}</label>
                                </div>
                                <!-- TEMP -->
                                <!-- <div class="col-4" style="text-align: end;">
                                    <label class="form-check-label" for="modifier_{{idx}}" *ngIf="s.price > 0">${{s.price.toFixed(2)}}</label>
                                </div> -->
                                <!-- TEMPORARY REMOVE Amount? and Ratios -->
                                <!-- <div *ngIf="displayExtraAmount(s.extra)" class="col-2" style="text-align: end;">
                                    <label style="cursor: pointer;font-size: 0.85rem;" (click)="showExtra(group, idx)" [ngStyle]="{'color': s.extra && s.extra.length > 0 ? 'dodgerblue' : 'grey', 'cursor': s.extra && s.extra.length > 0 ? 'pointer' : 'default'}">Amount?</label>
                                </div> -->
                                <div class="col-2" style="text-align: center;">
                                    <mat-radio-group id="rgAmounts" *ngIf="group.allowRatio && item.ratioGraphic != 'None'">
                                        <div fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, -1)">
                                            <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === -1" data-test="Item-Details-ModalLeftInput" />
                                            <mat-icon svgIcon="pizza_left_ratio{{s.ratio === -1 ? '_selected' : ''}}"></mat-icon>
                                        </div>
                                    </mat-radio-group>
                                    <!-- <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, -1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === -1" />
                                                <mat-icon svgIcon="pizza_left_ratio{{s.ratio === -1 ? '_selected' : ''}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div> -->
                                </div>
                                <div class="col-2" style="text-align: center;">
                                    <mat-radio-group id="rgAmounts" *ngIf="group.allowRatio && item.ratioGraphic != 'None'">
                                        <div fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 0)">
                                            <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 0" data-test="Item-Details-ModalFullInput" />
                                            <mat-icon svgIcon="{{s.ratio === 0 ? 'pizza_full_ratio_selected' : 'pizza_full_ratio'}}"></mat-icon>
                                        </div>
                                    </mat-radio-group>
                                    <!-- <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 0)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 0" />
                                                <mat-icon svgIcon="{{s.ratio === 0 ? 'pizza_full_ratio_selected' : 'pizza_full_ratio'}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div> -->
                                </div>
                                <div class="col-2" style="text-align: center;">
                                    <mat-radio-group id="rgAmounts" *ngIf="group.allowRatio && item.ratioGraphic != 'None'">
                                        <div fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 1)">
                                            <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 1" data-test="Item-Details-ModalRightInput" />
                                            <mat-icon svgIcon="{{s.ratio === 1 ? 'pizza_right_ratio_selected' : 'pizza_right_ratio'}}"></mat-icon>
                                        </div>
                                    </mat-radio-group>
                                    <!-- <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 1" />
                                                <mat-icon svgIcon="{{s.ratio === 1 ? 'pizza_right_ratio_selected' : 'pizza_right_ratio'}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div> -->
                                </div>
                                <!-- <div class="col-3" style="text-align: center;">
                                    <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, -1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === -1" />
                                                <mat-icon svgIcon="pizza_left_ratio{{s.ratio === -1 ? '_selected' : ''}}"></mat-icon>
                                            </div>
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 0)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 0" />
                                                <mat-icon svgIcon="{{s.ratio === 0 ? 'pizza_full_ratio_selected' : 'pizza_full_ratio'}}"></mat-icon>
                                            </div>
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 1" />
                                                <mat-icon svgIcon="{{s.ratio === 1 ? 'pizza_right_ratio_selected' : 'pizza_right_ratio'}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div>
                                </div>-->
                                <!-- </div> -->
                                <div *ngIf="!s.extra || !s.showExtra || s.extra.length === 0" class="col-2" style="text-align: end;">
                                    <label class="form-check-label" for="mod_{{idx}}" *ngIf="s.price > 0">${{s.price.toFixed(2)}}</label>
                                </div>
                                <div class="col-10" *ngIf="s.extra && s.extra.length > 0" style="margin: 0; padding-top: 10px;">
                                    <div fxLayout="row" style="padding: 0rem;">
                                        <div fxFlex="25%" fxLayoutAlign="center center" class="presettips" *ngFor="let e of s.extra; let i = index">
                                            <input *ngIf="e.enabled" type="radio" id="extra_{{idx}}_{{i}}" name="extra_{{idx}}_{{i}}" [checked]="e.selected" data-test="Item-DetailsRadioInput4" />
                                            <label *ngIf="e.enabled" class="btn btn-outline-primary" style="border: unset;" (click)="extraSelected(s.extra, i, idx, gi, true)" [ngClass]="{'left-radius': i === 0, 'right-radius': i === group.extra - 1}">
                        {{e.title}} - ${{(e.fee).toFixed(2)}}
                      </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngFor="let group of modalItem.side_groups;let gi = index;">
                        <div class="col-12 mods-title" *ngIf="xtMerchant">
                            Choose {{group.max_limit === 1 ? group.max_limit + ' Item': 'Up To ' + group.max_limit + ' Items'}}:
                            <mat-error class="error-message" *ngIf="optionError[gi] && xtMerchant">
                                Please Select at Least {{group.min_limit}} To Continue!
                            </mat-error>
                        </div>
                        <div class="col-12 mods-title" *ngIf="noneRMS && group.max_limit === 0">
                            {{group.title}}:
                        </div>
                        <div class="col-4" *ngIf="group.allowRatio"></div>
                        <div class="col-2" fxLayoutAlign="center center" *ngIf="group.allowRatio">
                            Left
                        </div>
                        <div class="col-2" fxLayoutAlign="center center" *ngIf="group.allowRatio">
                            Whole
                        </div>
                        <div class="col-2" fxLayoutAlign="center center" *ngIf="group.allowRatio">
                            Right
                        </div>
                        <div class="col-12" *ngFor="let s of group.items;let idx=index;">
                            <div class="form-check mods-desc row">
                                <div class="col-4">
                                    <input class="form-check-input" type="checkbox" name="side_{{gi + '_' + idx}}" [(ngModel)]="s.selected" id="side_{{gi + '_' + idx}}" (click)="chooseSide(idx, gi)" [disabled]="group.max_limit > 1 && sidesChosen[gi] >= group.max_limit && !s.selected" />
                                    <label class="form-check-label" for="side_{{gi + '_' + idx}}">{{s.title||s.desc}}</label>
                                </div>
                                <!-- TEMP -->
                                <!-- <div class="col-4" style="text-align: end;">
                                    <label class="form-check-label" for="mod_{{idx}}" *ngIf="s.price > 0">${{s.price.toFixed(2)}}</label>
                                </div> -->
                                <!-- TEMPORARY REMOVE Amount? and Ratios -->
                                <!-- <div *ngIf="displayExtraAmount(s.extra) && s.extra && s.extra.length > 0" class="col-2" style="text-align: end;">
                                    <label style="cursor: pointer;font-size: 0.85rem;" (click)="showExtra(group, idx)" [ngStyle]="{'color': s.extra && s.extra.length > 0 ? 'dodgerblue' : 'grey', 'cursor': s.extra && s.extra.length > 0 ? 'pointer' : 'default'}">Amount?</label>
                                </div> -->
                                <div class="col-2" style="text-align: center;">
                                    <mat-radio-group id="rgAmounts" *ngIf="group.allowRatio">
                                        <div fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, -1)">
                                            <input type="radio" id="side_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === -1" data-test="Item-Details-ModalLeftInput2" />
                                            <mat-icon svgIcon="pizza_left_ratio{{s.ratio === -1 ? '_selected' : ''}}"></mat-icon>
                                        </div>
                                    </mat-radio-group>
                                    <!-- <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, -1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === -1" />
                                                <mat-icon svgIcon="pizza_left_ratio{{s.ratio === -1 ? '_selected' : ''}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div> -->
                                </div>
                                <div class="col-2" style="text-align: center;">
                                    <mat-radio-group id="rgAmounts" *ngIf="group.allowRatio">
                                        <div fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 0)">
                                            <input type="radio" id="side_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 0" data-test="Item-Details-ModalFullInput2" />
                                            <mat-icon svgIcon="{{s.ratio === 0 ? 'pizza_full_ratio_selected' : 'pizza_full_ratio'}}"></mat-icon>
                                        </div>
                                    </mat-radio-group>
                                    <!-- <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 0)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 0" />
                                                <mat-icon svgIcon="{{s.ratio === 0 ? 'pizza_full_ratio_selected' : 'pizza_full_ratio'}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div> -->
                                </div>
                                <div class="col-2" style="text-align: center;">
                                    <mat-radio-group id="rgAmounts" *ngIf="group.allowRatio">
                                        <div fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 1)">
                                            <input type="radio" id="side_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 1" data-test="Item-Details-ModalRightInput2" />
                                            <mat-icon svgIcon="{{s.ratio === 1 ? 'pizza_right_ratio_selected' : 'pizza_right_ratio'}}"></mat-icon>
                                        </div>
                                    </mat-radio-group>
                                    <!-- <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 1" />
                                                <mat-icon svgIcon="{{s.ratio === 1 ? 'pizza_right_ratio_selected' : 'pizza_right_ratio'}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div> -->
                                </div>
                                <!-- <div class="col-3" style="text-align: center;">
                                    <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, -1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === -1" />
                                                <mat-icon svgIcon="pizza_left_ratio{{s.ratio === -1 ? '_selected' : ''}}"></mat-icon>
                                            </div>
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 0)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 0" />
                                                <mat-icon svgIcon="{{s.ratio === 0 ? 'pizza_full_ratio_selected' : 'pizza_full_ratio'}}"></mat-icon>
                                            </div>
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 1" />
                                                <mat-icon svgIcon="{{s.ratio === 1 ? 'pizza_right_ratio_selected' : 'pizza_right_ratio'}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div>
                                </div> -->
                                <!-- <div class="col-4" style="text-align: center;">
                                    <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="30%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, -1)">
                                                <input type="radio" id="side_ratio_{{idx}}" name="side_ratio_{{idx}}" [checked]="s.ratio === -1" />
                                                <mat-icon svgIcon="{{group.ratio.toLowerCase()}}_left_ratio"></mat-icon>
                                            </div>
                                            <div fxFlex="30%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 0)">
                                                <input type="radio" id="side_ratio_{{idx}}" name="side_ratio_{{idx}}" [checked]="s.ratio === 0" />
                                                <mat-icon svgIcon="{{group.ratio.toLowerCase()}}_full_ratio"></mat-icon>
                                            </div>
                                            <div fxFlex="30%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 1)">
                                                <input type="radio" id="side_ratio_{{idx}}" name="side_ratio_{{idx}}" [checked]="s.ratio === 1" />
                                                <mat-icon svgIcon="{{group.ratio.toLowerCase()}}_right_ratio"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div>
                                </div>-->
                                <div *ngIf="!s.extra || !s.showExtra || s.extra.length === 0" class="col-2" style="text-align: end;">
                                    <label class="form-check-label" for="mod_{{idx}}" *ngIf="s.price > 0">${{s.price.toFixed(2)}}</label>
                                </div>
                                <div class="col-10" *ngIf="s.extra && s.extra.length > 0" style="margin: 0; padding-top: 10px;">
                                    <div fxLayout="row" style="padding: 0rem;">
                                        <div fxFlex="25%" fxLayoutAlign="center center" class="presettips" *ngFor="let e of s.extra; let i = index">
                                            <input *ngIf="e.enabled" type="radio" id="extra_{{idx}}_{{i}}" name="extra_{{idx}}_{{i}}" [checked]="e.selected" data-test="Item-DetailsRadioINput8" />
                                            <label *ngIf="e.enabled" class="btn btn-outline-primary" style="border: unset;" (click)="extraSelected(s.extra, i, idx, gi, true)" [ngClass]="{'left-radius': i === 0, 'right-radius': i === group.extra - 1}">
                        {{e.title}} - ${{(e.fee).toFixed(2)}}
                      </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row" *ngIf="modalItem && modalItem.modifiers && modalItem.modifiers.length > 0">
                        <div class="col-12 mods-title">
                            Modifiers:
                        </div>
                        <div class="col-12" *ngFor="let s of modalItem.modifiers;let idx=index">
                            <div class="form-check mods-desc row">
                                <div class="col-8">
                                    <input class="form-check-input" type="checkbox" name="mod_{{idx}}" [(ngModel)]="s.selected" id="mod_{{idx}}" />
                                    <label class="form-check-label" for="mod_{{idx}}">{{s.title||s.desc}}</label>
                                </div>
                                <div class="col-4" style="text-align: end;">
                                    <label class="form-check-label" for="mod_{{idx}}" *ngIf="s.price > 0">${{s.price.toFixed(2)}}</label>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="row" *ngFor="let group of modalItem.option_groups;let gi = index;">
                        <div class="col-12 mods-title" *ngIf="xtMerchant && group.items.length > 0">
                            {{group.title}}
                        </div>
                        <div class="col-4" *ngIf="group.allowRatio"></div>
                        <div class="col-2" fxLayoutAlign="center center" *ngIf="group.allowRatio">
                            Left
                        </div>
                        <div class="col-2" fxLayoutAlign="center center" *ngIf="group.allowRatio">
                            Whole
                        </div>
                        <div class="col-2" fxLayoutAlign="center center" *ngIf="group.allowRatio">
                            Right
                        </div>
                        <div class="col-12" style="padding-bottom: 10px;" *ngFor="let s of group.items;let idx=index">
                            <div class="form-check mods-desc row">
                                <div class="col-4">
                                    <input class="form-check-input" type="checkbox" name="opt_{{idx}}" (click)="chooseOption(idx, gi)" (change)="optionChange()" [(ngModel)]="s.selected" id="opt_{{idx}}" data-test="Item-Details-ModalCheckInputInput" />
                                    <label class="form-check-label" for="opt_{{idx}}">{{s.title||s.desc}} </label>
                                </div>
                                <!-- TEMP -->
                                <!-- <div class="col-4" style="text-align: end;">
                                    <label class="form-check-label" for="mod_{{idx}}" *ngIf="s.price > 0">${{s.price.toFixed(2)}}</label>
                                </div> -->
                                <!-- TEMPORARY REMOVE Amount? and Ratios -->
                                <!-- <div *ngIf="displayExtraAmount(s.extra)" class="col-2" style="text-align: end;">
                                    <label style="cursor: pointer;font-size: 0.85rem;" (click)="showExtra(group, idx)" [ngStyle]="{'color': s.extra && s.extra.length > 0 ? 'dodgerblue' : 'grey', 'cursor': s.extra && s.extra.length > 0 ? 'pointer' : 'default'}">Amount?</label>
                                </div> -->
                                <div class="col-2" style="text-align: center;">
                                    <mat-radio-group id="rgAmounts" *ngIf="group.allowRatio && item.ratioGraphic != 'None'">
                                        <div fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, true, -1)">
                                            <input type="radio" id="option_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === -1" data-test="Item-Details-ModalLeftInput3" />
                                            <mat-icon svgIcon="pizza_left_ratio{{s.ratio === -1 ? '_selected' : ''}}"></mat-icon>
                                        </div>
                                    </mat-radio-group>
                                    <!-- <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, -1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === -1" />
                                                <mat-icon svgIcon="pizza_left_ratio{{s.ratio === -1 ? '_selected' : ''}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div> -->
                                </div>
                                <div class="col-2" style="text-align: center;">
                                    <mat-radio-group id="rgAmounts" *ngIf="group.allowRatio && item.ratioGraphic != 'None'">
                                        <div fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, true, 0)">
                                            <input type="radio" id="option_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 0" data-test="Item-Details-ModalFullInput3" />
                                            <mat-icon svgIcon="{{s.ratio === 0 ? 'pizza_full_ratio_selected' : 'pizza_full_ratio'}}"></mat-icon>
                                        </div>
                                    </mat-radio-group>
                                    <!-- <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 0)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 0" />
                                                <mat-icon svgIcon="{{s.ratio === 0 ? 'pizza_full_ratio_selected' : 'pizza_full_ratio'}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div> -->
                                </div>
                                <div class="col-2" style="text-align: center;">
                                    <mat-radio-group id="rgAmounts" *ngIf="group.allowRatio && item.ratioGraphic != 'None'">
                                        <div fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, true, 1)">
                                            <input type="radio" id="option_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 1" data-test="Item-Details-ModalRightInput3" />
                                            <mat-icon svgIcon="{{s.ratio === 1 ? 'pizza_right_ratio_selected' : 'pizza_right_ratio'}}"></mat-icon>
                                        </div>
                                    </mat-radio-group>
                                    <!-- <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 1" />
                                                <mat-icon svgIcon="{{s.ratio === 1 ? 'pizza_right_ratio_selected' : 'pizza_right_ratio'}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div> -->
                                </div>
                                <!-- <div class="col-3" style="text-align: center;">
                                    <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgAmounts">
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, -1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === -1" />
                                                <mat-icon svgIcon="pizza_left_ratio{{s.ratio === -1 ? '_selected' : ''}}"></mat-icon>
                                            </div>
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 0)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 0" />
                                                <mat-icon svgIcon="{{s.ratio === 0 ? 'pizza_full_ratio_selected' : 'pizza_full_ratio'}}"></mat-icon>
                                            </div>
                                            <div fxFlex="40%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, false, 1)">
                                                <input type="radio" id="modifier_ratio_{{idx}}" name="modifier_ratio_{{idx}}" [checked]="s.ratio === 1" />
                                                <mat-icon svgIcon="{{s.ratio === 1 ? 'pizza_right_ratio_selected' : 'pizza_right_ratio'}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div>
                                </div> -->
                                <!-- <div class="col-4" style="text-align: center;">
                                    <div fxLayout="row" style="padding: 0rem;" *ngIf="group.allowRatio">
                                        <mat-radio-group id="rgOptionRatio">
                                            <div fxFlex="30%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, true, -1)">
                                                <input type="radio" id="option_ratio_{{idx}}" name="option_ratio_{{idx}}" [checked]="s.ratio === -1" />
                                                <mat-icon svgIcon="{{s.ratio === -1 ? 'pizza_left_ratio_selected' : 'pizza_left_ratio'}}"></mat-icon>
                                            </div>
                                            <div fxFlex="30%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, true, 0)">
                                                <input type="radio" id="option_ratio_{{idx}}" name="option_ratio_{{idx}}" [checked]="s.ratio === 0" />
                                                <mat-icon svgIcon="{{s.ratio === 0 ? 'pizza_full_ratio_selected' : 'pizza_full_ratio'}}"></mat-icon>
                                            </div>
                                            <div style="padding-right: 2rem;"></div>
                                            <div fxFlex="30%" fxLayoutAlign="center center" style="cursor: pointer;" class="presetratios" (click)="setRatio(gi, idx, true, 1)">
                                                <input type="radio" id="option_ratio_{{idx}}" name="option_ratio_{{idx}}" [checked]="s.ratio === 1" />
                                                <mat-icon svgIcon="{{s.ratio === 1 ? 'pizza_right_ratio_selected' : 'pizza_right_ratio'}}"></mat-icon>
                                            </div>
                                        </mat-radio-group>
                                    </div>
                                </div>-->
                                <div *ngIf="!s.extra || !s.showExtra || s.extra.length === 0" class="col-2" style="text-align: end;">
                                    <label class="form-check-label" for="mod_{{idx}}" *ngIf="s.price > 0">${{s.price.toFixed(2)}}</label>
                                </div>
                                <div class="col-10" style="margin: 0; padding-top: 10px;" *ngIf="s.extra && s.extra.length > 0">
                                    <!-- <div class="col-10" *ngIf="s.showExtra"> -->
                                    <div fxLayout="row" style="padding: 0rem;">
                                        <div fxFlex="25%" fxLayoutAlign="center center" class="presettips" *ngFor="let e of s.extra; let i = index">
                                            <input *ngIf="e.enabled" type="radio" id="extra_option_{{idx}}_{{i}}" name="extra_option_{{idx}}_{{i}}" [checked]="e.selected" data-test="RadioInput12" />
                                            <label *ngIf="e.enabled" class="btn btn-outline-primary" style="border: unset;" (click)="extraSelected(s.extra, i, idx, gi, false)" [ngClass]="{'left-radius': i === 0, 'right-radius': i === group.extra - 1}">
                        {{e.title}} - ${{(e.fee).toFixed(2)}}
                      </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="instructionEnabled">
                        <div class="col-12 mods-title">Additional Instructions</div>
                        <div class="col-12">
                            <div class="form-group">
                                <textarea class="form-control" name="itemComments" [(ngModel)]="item.comments" rows="5" placeholder="{{instruction}}"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" style="align-self: center;">
                            <div class="input-group" [formGroup]="form">
                                <span class="input-group-btn input-group-prepend border">
                  <button type="button" class="btn btn-default btn-number" [disabled]="item.qty <= 1"
                    (click)="ValidateItemQty(-1)" data-test="Item-DetailsItemQtyButton1">
                    <i class="fas fa-minus" aria-hidden="true"></i>
                  </button>
                </span>
                                <!-- <input type="number" name="quant" id="quant" class="form-control input-number text-center"
                  value="{{item.qty}}" (ngModelChange)="ValidateItemQty(0, true, $event)"
                  (blur)="ValidateItemQty(item.qty, true)" [(ngModel)]="item.qty" min="1" max="{{item.maxqty}}" /> -->
                                <input type="number" name="quant" id="quant" class="form-control input-number text-center" formControlName="qty" min="{{item.minqty > 0 ? item.minqty : 1}}" max="{{item.maxqty > 0 ? item.maxqty : 99}}" data-test="Item-DetailsItemQtyButton2" />
                                <span class="input-group-btn input-group-append border">
                  <button type="button" class="btn btn-default btn-number" [disabled]="item.qty >= item.maxqty" data-test="Item-DetailsItemQtyButton3"
                    (click)="ValidateItemQty(1)">
                    <i class="fas fa-plus" aria-hidden="true"></i>
                  </button>
                </span>
                            </div>
                        </div>
                        <div class="col-1 hidden-xs-up not-visible" style="font-size: 9px;">&nbsp;</div>
                        <div *ngIf="!item.item86" class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12" style="align-self: center;">
                            <button *ngIf="!isUpdate" type="button" class="btn btn-success w-100" (click)="submit()" [disabled]="showError || item.qty < 1 || form.invalid" data-test="Item-DetailsItemQtyButton4" data-dismiss="modal" autofocus>
                Add {{item.qty}} {{item.title}}{{(item.qty != 1 ? 's' : '')}} to Order -
                ${{(item.qty*(item.price+sidesPrice+optionsPrice+modifiersPrice)).toFixed(2)}}
              </button>
                            <button *ngIf="isUpdate" type="button" class="btn btn-success w-100" (click)="submit()" [disabled]="showError || item.qty < 1 || form.invalid" data-test="Item-DetailsItemQtyBUtton5" data-dismiss="modal" autofocus>
                Update {{item.title}} from Order -
                ${{(item.qty*(item.price+sidesPrice+optionsPrice+modifiersPrice)).toFixed(2)}}
              </button>
                            <!-- <button *ngIf="!isUpdate" type="button" class="btn btn-success w-100" (click)="submit()" [disabled]="showError || item.qty < 1" data-dismiss="modal" autofocus>
                                Add {{item.qty}} {{item.title}}{{(item.qty != 1 ? 's' : '')}} to Order -
                                ${{(item.qty*(bareItemPrice+sidesPrice+optionsPrice)).toFixed(2)}}
                            </button>
                            <button *ngIf="isUpdate" type="button" class="btn btn-success w-100" (click)="submit()" [disabled]="showError || item.qty < 1" data-dismiss="modal" autofocus>
                                Update {{item.title}} from Order -
                                ${{(item.qty*(bareItemPrice+sidesPrice+optionsPrice)).toFixed(2)}}
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>