import { Injectable } from '@angular/core';
import { User } from 'projects/common/src/lib/user';
import { Merchant } from 'projects/common/src/lib/merchant';
import { GlobalService } from './global.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Item } from 'projects/common/src/lib/item';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json;charset=utf-8'
  })
};

@Injectable({
  providedIn: 'root'
})
export class MenuBuilderService {
  // public headers = new Headers({ 'Content-Type': 'application/json' });
  // public options = new RequestOptions({ headers: this.headers });
  // public logindata: LogIn;
  // public user: User = new User();
  public authenticationDelegate = new Subject<User>();
  authenticationEvent$ = this.authenticationDelegate.asObservable();

  constructor(public http: HttpClient, public globalservice: GlobalService, public localStorage: StorageMap) { }
  // Import Items from Excel
  public importMenu(menu: any): Observable<any> {
    return this.http.post<any>('/api/menus/import', menu, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public importCategories(menu: any): Observable<any> {
    return this.http.post<any>('/api/menus/import/categories', menu, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public importItems(menu: any): Observable<any> {
    return this.http.post<any>('/api/menus/import/items', menu, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public importModifierGroups(menu: any): Observable<any> {
    return this.http.post<any>('/api/menus/import/modifierGroups', menu, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public importModifiers(menu: any): Observable<any> {
    return this.http.post<any>('/api/menus/import/modifiers', menu, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Update Items
  public updateItems(items: any): Observable<any> {
    return this.http.post('/api/menus/items', items, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Update Items
  public updateItem(item: Item): Observable<any> {
    return this.http.put('/api/manage/item', item)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Set Default Modifiers
  public setDefaultModifers(menuItems: any, menuMerchant: Merchant): Observable<any> {

    const request = { merchant: menuMerchant, items: menuItems };

    return this.http.put('/api/menus/items/defaultModifiers', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  // ReOrder Items
  public reOrderItems(menuItems: any, menuMerchant: Merchant): Observable<any> {

    const request = { merchant: menuMerchant, items: menuItems };

    return this.http.put('/api/menus/reorder/items', request)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Update Categories
  public reOrderCategories(menuCategories: any, menuMerchant: Merchant): Observable<any> {
    const request = { merchant: menuMerchant, categories: menuCategories };

    return this.http.put('/api/menus/reorder/categories', request)
      .pipe(
        catchError(this.handleError)
      );
  }


  // Update Options/Sides/Modifiers
  public updateOptions(objects: any): Observable<any> {
    return this.http.post('/api/menus/optional/', objects, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get All (Categories, Items, Options, Sides, Modifiers)
  public getMenu(merchantid: string): Observable<any> {
    return this.http.get('/api/menus/' + merchantid, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get Specific Tab (Categories/Items/Options/Sides/Modifiers) Depending On Target Value
  /*
  * 1: Items
  * 2: Categories
  * 3: Options
  * 4: Sides
  * 5: Modifiers
  * 6: Cross Sell
  */
  public getSpecific(merchantid: string, target: number): Observable<any> {
    return this.http.get('/api/menus/' + merchantid + '/' + target, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Remove Element
  /*
  * 1: Items
  * 2: Categories
  * 3: Options
  * 4: Sides
  * 5: Modifiers
  * 6: Cross Sell
  */
  public removeElement(id: string, target: number): Observable<any> {
    return this.http.delete('/api/menus/' + id + '/' + target, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  // Remove Optional Item
  /*
  * 1: Option
  * 2: Side
  * 3: Modifier
  */
  public removeOptionalItem(id: string, target: number): Observable<any> {
    return this.http.delete('/api/menus/optionalitem/' + id + '/' + target, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  public getNextModifiersItemId(merchantid: string, mode: number): Observable<any> {
    try {
      return this.http.get<any>('/api/menus/optionalid/' + merchantid + "/" + mode)
        .pipe(
          catchError(this.handleError)
        );
    } catch (error) {
      return throwError(new Error(error));
    }
  }

  // public validateToken(token: string): Observable<any> {
  //   const headers = new HttpHeaders().set('Content-Type', 'application/json;charset=utf-8');
  //   headers.append('token', token);

  //   return this.http.get<any>('/api/verify', { headers: new HttpHeaders().set('token', token) })
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  // public isAuthenticated() {
  //   return this.localStorage.get('token').subscribe((data) => {
  //     if (data) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   });
  // }

  authenticationChanged(user: User) {
    this.authenticationDelegate.next(user);
  }
}
